import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import organizationClient from "../../../apis/organizationClient";
import SntWarning from "../../../components/SntInfo/SntWarning";
import { useOrg } from "../../../contexts/OrgContext";
import StringUtils from "../../../utils/StringUtils";
import _ from "lodash";

const PagePermissionWarning = ({ pageName }) => {
  const language = useSelector((state) => state.language);
  const { orgId } = useOrg();
  const loginInfo = useSelector((state) => state.user);

  const [orgName, setOrgName] = useState();
  const [requiredFeatures, setRequiredFeatures] = useState([]);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (
      pageName &&
      loginInfo.organisation.organisationType !== "NORMAL" &&
      !_.isNil(orgId) &&
      orgId !== 0
    ) {
      organizationClient
        .checkPagePermission({
          orgId: orgId,
          pageName: pageName,
        })
        .then(({ data }) => {
          if (data.orgName) {
            setOrgName(data.orgName);
            setRequiredFeatures(data.requiredFeatures);
            setShowWarning(true);
          } else {
            setShowWarning(false);
          }
        })
        .catch((error) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return (
    <>
      {showWarning && (
        <div className="mt-2 mb-2">
          <SntWarning textClassName="d-flex justify-content-center">
            {ReactHtmlParser(
              language.organization_key +
                " <b>" +
                orgName +
                "</b> " +
                language.has_no_access_to_feature +
                (requiredFeatures && " <b>" + requiredFeatures + "</b>. ") +
                StringUtils.replaceStr(
                  language.change_for_this_org_to_enable_it,
                  ` <a href='/product_plan?org=${orgId}'>` +
                    language.platfrom_plan +
                    "</a> "
                )
            )}
          </SntWarning>
        </div>
      )}
    </>
  );
};

export default PagePermissionWarning;
