import moment from "./SensolusMoment";

export const SensolusDatetimeReact = {
  FORMAT_SERVER: "YYYY-MM-DD HH:mm:ss ZZ",
  FORMAT_CLIENT: "YYYY-MM-DD HH:mm:ss",
  ISO_8601_PATTERN: "YYYY-MM-DDTHH:mm:ssZZ",

  fromNow: function (dateTime) {
    // dateTime should comes from SensolusMoment with correct timezone and locale
    if (!dateTime) {
      dateTime = moment();
    } else if (!moment.isMoment(dateTime)) {
      dateTime = moment(dateTime);
    }

    let duration = moment.duration(moment().diff(dateTime));
    let minutes = duration.asMinutes();

    if (minutes < 60) {
      return dateTime.fromNow();
    }
    return dateTime.format("LLL");
  },

  primitiveFromNow: function (dateTime) {
    // dateTime should comes from SensolusMoment with correct timezone and locale
    if (!dateTime) {
      dateTime = moment();
    } else if (!moment.isMoment(dateTime)) {
      dateTime = moment(dateTime);
    }

    return dateTime.fromNow();
  },

  parse: function (str, selection) {
    return !selection || selection === "client"
      ? moment(str, this.FORMAT_CLIENT)
      : moment(str, this.FORMAT_SERVER);
  },
  format: function (datetime, selection) {
    if (!datetime) {
      datetime = moment();
    } else if (!moment.isMoment(datetime)) {
      datetime = moment(datetime);
    }

    return !selection || selection === "client"
      ? datetime.format(this.FORMAT_CLIENT)
      : datetime.format(this.FORMAT_SERVER);
  },

  formatServer: function (datetime) {
    if (!datetime) {
      datetime = moment();
    } else if (!moment.isMoment(datetime)) {
      datetime = moment(datetime);
    }
    return datetime.format(this.FORMAT_SERVER);
  },

  // msToTime: function (duration, format) {
  //    var d, h, m, s;
  //    var days = {'s': 'd ', 'l': ' days '}
  //    var hours = {'s': 'h ', 'l': ' hours '}
  //    var mins = {'s': 'm ', 'l': ' mins '}
  //    format = format || 's';
  //    s = Math.floor(duration / 1000);
  //    m = Math.floor(s / 60);
  //    s = s % 60;
  //    h = Math.floor(m / 60);
  //    m = m % 60;
  //    d = Math.floor(h / 24);
  //    h = h % 24;
  //   return (d === 0?"":d + days[format])
  //           + (h === 0?"":h + hours[format])
  //           + (m === 0?"":m + mins[format])
  //           + (s === 0 && (m != 0 || h != 0 || d != 0) || d !=0 ?"":s + "s");
  // },

  formatDuration: function (_duration) {
    var duration = moment.duration(_duration),
      _day = Math.floor(duration.asDays()),
      _hour = duration.hours(),
      _min = duration.minutes();

    var result = "",
      d_key = window.LANG.d_key + " ",
      h_key = window.LANG.h_key + " ",
      m_key = "m";
    _day && (result += _day + d_key);
    _hour && (result += _hour + h_key);
    _min && (result += _min + m_key);
    if (!result) {
      result = "0";
    }
    return result;
  },
  formatDetailedDuration: function (_duration) {
    var duration = moment.duration(_duration),
      _day = Math.floor(duration.asDays()),
      _hour = duration.hours(),
      _min = duration.minutes(),
      _sec = duration.seconds();

    var result = "",
      d_key = window.LANG.d_key + " ",
      h_key = window.LANG.h_key + " ",
      m_key = "m ",
      s_key = "s";
    _day && (result += _day + d_key);
    _hour && (result += _hour + h_key);
    _min && (result += _min + m_key);
    _sec && (result += _sec + s_key);
    if (!result) {
      result = "0";
    }
    return result;
  },
};

const config = {
  API_URL: import.meta.env.VITE_REACT_APP_API_URL || "",
  MODE: import.meta.env.MODE,
};

export default config;
