import SntIcon from "./SntIcon";

const SntCheckIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 2L6 9.5L2.5 6L0 8.5L6 14.5L16 4.5L13.5 2Z"
      />
    </SntIcon>
  );
};

export default SntCheckIcon;
