import SntIcon from "./SntIcon";

const SntCloseSmallIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.8L11.2 4L8 7.2L4.8 4L4 4.8L7.2 8L4 11.2L4.8 12L8 8.8L11.2 12L12 11.2L8.8 8L12 4.8Z"
      />
    </SntIcon>
  );
};

export default SntCloseSmallIcon;
