import { apiClient } from "./apiClient";

export default {
  updateSetting(data) {
    return apiClient.post(`/rest/uiSetting`, data);
  },

  findOne(pageName) {
    return apiClient.get(`/rest/uiSetting/${pageName}`);
  },
};
