import SntDialog from "../../components/SntDialog/SntDialog";
import { useSelector } from "react-redux";

const DeleteTag = ({
  numLinkedObject = 0,
  objectType = "assets",
  isShow,
  onDelete,
  onClose,
}) => {
  const language = useSelector((state) => state.language);

  return (
    <SntDialog
      isShow={isShow}
      onSave={onDelete}
      onClose={onClose}
      saveTxt={language.delete_key}
      btnSaveClassName="danger"
      title={language.delete_key}
    >
      {() => {
        return (
          <>
            <div>{language.confirm_delete_tag}</div>
            <div>
              {language.warning_delete_tag}&nbsp;<b>{numLinkedObject}</b>&nbsp;
              {objectType}
            </div>
          </>
        );
      }}
    </SntDialog>
  );
};
export default DeleteTag;
