import { apiClient } from "./apiClient";

export default {
  getByLoginUser() {
    return apiClient.get(`/rest/system_tag/getByLoginUser`);
  },

  search(data, orgId) {
    return apiClient.post(`/rest/system_tag/search/${orgId}`, data, {
      headers: { "content-type": "application/json" },
    });
  },

  checkTag(id) {
    return apiClient.get(`/rest/system_tag/checkTag/${id}`);
  },

  delete(id) {
    return apiClient.delete(`/rest/system_tag/${id}`);
  },

  getById(id) {
    return apiClient.get(`/rest/system_tag/${id}`);
  },

  create(body) {
    return apiClient.post(`/rest/system_tag`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  update(id, body) {
    return apiClient.put(`/rest/system_tag/${id}`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};
