import SntIcon from "./SntIcon";

const SntSearchIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8857 5.94343C11.8857 2.66083 9.22514 0 5.94286 0C2.66057 0 0 2.66083 0 5.94343C0 9.22603 2.66057 11.8869 5.94286 11.8869C7.41486 11.8869 8.768 11.3474 9.81029 10.4604L10.0571 10.7073V11.4297L14.6286 16.0015L16 14.63L11.4286 10.0581H10.7063L10.4594 9.81123C11.3463 8.76884 11.8857 7.41557 11.8857 5.94343ZM1.82858 5.94344C1.82858 3.65751 3.65715 1.82876 5.94287 1.82876C8.22858 1.82876 10.0572 3.65751 10.0572 5.94344C10.0572 8.22937 8.22858 10.0581 5.94287 10.0581C3.65715 10.0581 1.82858 8.22937 1.82858 5.94344Z"
      />
    </SntIcon>
  );
};

export default SntSearchIcon;
