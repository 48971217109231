const StringUtils = {
  replaceStr() {
    if (arguments.length < 1 || arguments[0] === undefined) {
      return "";
    }
    let arr = arguments[0].split("{}");
    var result = "";
    for (var i = 0; i < arr.length - 1; i++) {
      result += arr[i] + arguments[i + 1];
    }
    result += arr[arr.length - 1];
    return result;
  },
};
export default StringUtils;
