import { useEffect, useImperativeHandle, useState } from "react";

// Components
import { useColumnDefinition } from "./hooks/useColumnDefinition";
import { useColumnFilter } from "./hooks/useColumnFilter";
import { useUiSetting } from "./hooks/useUiSetting";
import { ActionEnum } from "./ActionEnum";
import SntConfigColumns from "./SntConfigColumns";
import { SntTableViewCard } from "./SntTableViewCard";
import DataUtils from "@/utils/DataUtils";

const SntAdvancedSearchTableViewCard = ({
  allColumns,
  extraCols,
  preventCols,
  visibleCols,
  disabled = false,
  onChange,
  isFloatingFooter,
  dataOptions,
  initialPageSize = 25,
  shared,
  orgId,
  useOrgInSidebar,
  categoryId,
  addFilter,
  noDataText,
  includeDynamicColumn = false,
  pageName,
  isDisabbledGroup = false,
  isShowDecoratorColor,
  isShowSelectPageSize = true,
  isShowPaging = true,
  isShowConfigColumn = true,
  onRenderConfig,
  fixedSetting,
  isDynamicDashBoard,
  height,
  onCountClicked,
  onExtraColumnActionClicked,
  isDisabledCopyData,
  isDisabbledAddFilter,
  restrictedFilters,
  onFilterClicked,
  filterMap,
}) => {
  const [isShowConfigDialog, setShowConfigDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  // Column definition
  const { allColumnDefs: columnsDef, getNewCols } = useColumnDefinition(
    dataOptions.queryObjectType,
    dataOptions.categories,
    preventCols,
    {
      originalCols: allColumns,
      extraCols: extraCols,
      includeDynamicColumn: includeDynamicColumn,
    }
  );
  const customPageName = pageName || dataOptions.pageName;
  const { setting, updateSetting } = useUiSetting(
    customPageName,
    fixedSetting || null
  );

  const dataFilter = useColumnFilter(
    columnsDef,
    {
      defaultVisibleColumnKeys: visibleCols,
      defaultPageSize: initialPageSize,
      onCountClicked: onCountClicked,
    },
    setting
  );
  const {
    filter,
    setPageCount,
    getPageData,
    setVisibleColumnKeys,
    setColumnsSetting,
    resetDefault,
    setTotalItems,
    refresh,
    loadData,
    setPaging,
  } = dataFilter;

  const _getDataRequest = () => {
    const { start, limit, sort } = getPageData();
    let params = _getParamFormat(
      start,
      limit,
      sort,
      dataOptions.optionsSettings && dataOptions.optionsSettings.query,
      dataOptions.queryObjectType,
      dataOptions.optionsSettings && dataOptions.optionsSettings.searchQueryType
    );

    if (useOrgInSidebar && orgId) {
      _addOrgInSidebarToFilter(params);
    }

    addFilter && addFilter(params);

    return { params, dataOptions };
  };

  const _addOrgInSidebarToFilter = (params) => {
    // remove old
    params.query = params.query.filter(function (param) {
      return param.filterKey !== "organizations";
    });

    // add new
    params.query.push({
      categoryId: categoryId || "userInfo",
      filterKey: "organizations",
      filterType: "MULTI_SELECT_AUTO_COMPLETE",
      searchType: "STATIC",
      notFilter: false,
      filterValue: {
        includeEmpty: false,
        includeNoEmpty: false,
        selectedValues: [orgId],
      },
    });
  };

  const _getParamFormat = (
    start,
    limit,
    sort,
    queries,
    queryObjectType,
    searchQueryType
  ) => {
    const { requestedTableColumns: currentColumns } = getPageData();
    let parsedSort = JSON.parse(sort);
    let orderData = [parsedSort[0].property, parsedSort[0].direction];

    return {
      maxResults: limit,
      sort: sort || "[]",
      query: queries || [],
      queryObjectType: queryObjectType,
      // currentColumns can a list of string, or list of group object
      requestedTableColumns: currentColumns.map((obj) => {
        if (typeof obj === "string") {
          return {
            columnKey: obj,
            order: obj === orderData[0] ? orderData[1] : null,
          };
        }
        return {
          ...obj,
        };
      }),
      searchQueryType: searchQueryType || "BASIC",
      startIndex: start,
    };
  };

  const _getDataCallBack = (params, dataOptions, data) => {
    const { start, limit, groups } = getPageData();

    let parsedSort = JSON.parse(params.sort);
    let orderData = [parsedSort[0].property, parsedSort[0].direction];

    return [
      {
        limit: limit,
        orderData: orderData,
        query: params.query,
        searchQueryType: params.searchQueryType,
        start: start,
        tableSettings: {
          columnVisibleInOrder: params.requestedTableColumns.map(
            (c) => c.columnKey
          ),
          groups: groups,
          orderData: orderData,
          pageName: customPageName,
          pageSize: limit,
          switchMode: "TABLE", // unknow
        },
      },
      { countByFilter: data.countByFilter, countAll: data.countAll },
    ];
  };

  useEffect(() => {
    if (!filter) return;

    loadData();
    if (dataOptions.action && dataOptions.action.type) {
      if (dataOptions.action.type === ActionEnum.LOAD_FILTER) {
        if (
          dataOptions.optionsSettings &&
          dataOptions.optionsSettings.tableSettings
        ) {
          let columnKeys =
            dataOptions.optionsSettings.tableSettings.columnVisibleInOrder ||
            [];
          let orderData = dataOptions.optionsSettings.tableSettings
            .orderData || [columnKeys[0], "DESC"];

          let groups = dataOptions.optionsSettings.tableSettings.groups || [];
          let groupKey = null;
          let bucketType = null;
          let bucketConfig = null;
          if (groups.length > 0) {
            groupKey = groups[0].aggregateColumn;
            bucketType = groups[0].bucketType;
            bucketConfig = groups[0].bucketConfig;
          }
          setColumnsSetting(
            columnKeys,
            { key: orderData[0], desc: orderData[1] === "DESC" },
            groupKey,
            bucketType,
            bucketConfig
          );
          setVisibleColumnKeys(
            dataOptions.optionsSettings.tableSettings.columnVisibleInOrder || []
          );
        }
      } else if (dataOptions.action.type === ActionEnum.CHANGE_MORE_FILTER) {
        let isGrouped = filter.columns.find((f) => f.aggregationType);
        if (!isGrouped) {
          const newCols = getNewCols(dataOptions);
          setVisibleColumnKeys([
            ...filter.columns.map((item) => item.columnKey),
            ...newCols,
          ]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOptions]);

  useEffect(() => {
    if (filter) {
      updateSetting(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (!filter || !dataOptions) return;
    const timeout = setTimeout(() => {
      const request = _getDataRequest();
      onChange && onChange(request);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, dataOptions]);

  const [presentFilters, setPresentFilters] = useState([]);

  useEffect(() => {
    let query = dataOptions?.optionsSettings?.query || [];

    console.log(query);
    let presentWithValueKey = [];
    query.forEach((item) => {
      let { filterValue, filterType } = item;

      if (item.notFilter) {
        presentWithValueKey.push(item.filterKey);
      } else if (filterValue) {
        if (filterType === "MULTI_SELECT_CHECKBOX") {
          let selectedValues = filterValue.selectedValues || [];
          let operator = filterValue.operator;
          if (selectedValues.length === 0 && (!operator || operator === "IN"))
            return;
          presentWithValueKey.push(item.filterKey);
        } else if (filterType === "STRING") {
          let { includeEmpty, includeNoEmpty, searchText } = filterValue;
          if (!searchText && !includeEmpty && !includeNoEmpty) return;
          presentWithValueKey.push(item.filterKey);
        } else if (filterType === "MULTI_STRING") {
          let { searchText } = filterValue;
          if (!searchText) return;
          presentWithValueKey.push(item.filterKey);
        } else if (filterType === "BOOLEAN") {
          let { value } = filterValue;
          if (value === null) return;
          presentWithValueKey.push(item.filterKey);
        } else if (filterType === "NUMERIC" || filterType === "DATE") {
          presentWithValueKey.push(item.filterKey);
        }
      }
    });
    setPresentFilters(presentWithValueKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOptions]);

  const [data, setData] = useState([]);

  // Expose available functions for parents
  useImperativeHandle(shared, () => ({
    refresh: () => {
      refresh && refresh();
    },
    resetDefault: () => {
      resetDefault && resetDefault();
      refresh && refresh();
    },
    setLoading: (value) => {
      setLoading(value);
    },
    // setData: (data) => {
    //   setData(data);
    // },
    // setPageCount: (pageCount) => {
    //   setPageCount(Math.ceil(pageCount / filter.pageSize));
    // },
    // setTotalItems: (totalItem) => {
    //   setTotalItems(totalItem);
    // },
    updateDataTable: ({
      data,
      convertData,
      compactData,
      pageCount,
      totalItem,
    }) => {
      let dataList = DataUtils.convertData({ data, convertData, compactData });
      setData(dataList);
      if (filter) {
        let pageNrOf = Math.ceil(pageCount / filter.pageSize);
        setPageCount(pageNrOf);
        setTotalItems(totalItem);
        if (filter.pageIndex >= pageNrOf) {
          setPaging(0, filter.pageSize);
        }
      }
    },
    getDataCallBack: (params, dataOptions, data) => {
      return _getDataCallBack(params, dataOptions, data);
    },
  }));

  if (!dataFilter.filter) return <></>;

  let onConfigColumn = null;
  if (isShowConfigColumn) {
    onConfigColumn = () => {
      setShowConfigDialog(true);
    };
  }

  return (
    <div>
      {isShowConfigDialog && (
        <SntConfigColumns
          dataFilter={dataFilter}
          isShowConfigDialog={true}
          onDone={() => setShowConfigDialog(false)}
          onCancel={() => setShowConfigDialog(false)}
          isDisabbledGroup={isDisabbledGroup}
          optionsSettings={dataOptions.optionsSettings}
        />
      )}
      <SntTableViewCard
        dataFilter={dataFilter}
        data={data}
        onConfigColumn={onConfigColumn}
        loading={loading}
        noDataText={noDataText}
        isFloatingFooter={isFloatingFooter}
        isDisabbledGroup={isDisabbledGroup}
        isShowDecoratorColor={isShowDecoratorColor}
        isShowSelectPageSize={isShowSelectPageSize}
        isShowPaging={isShowPaging}
        onRenderConfig={onRenderConfig}
        isDynamicDashBoard={isDynamicDashBoard}
        height={height}
        onExtraColumnActionClicked={onExtraColumnActionClicked}
        isDisabledCopyData={isDisabledCopyData}
        isDisabbledAddFilter={isDisabbledAddFilter}
        restrictedFilters={restrictedFilters}
        onFilterClicked={onFilterClicked}
        filterMap={filterMap}
        filterVisibleKeys={presentFilters}
      />
    </div>
  );
};

export default SntAdvancedSearchTableViewCard;
