const DateModeView = {
  day: "day",
  week: "week",
  month: "month",
  year: "year",
  custom: "custom",
  datetime: "datetime",
  last90Day: "last90Day",
  hour: "hour",
  all: "all",
};

export default DateModeView;
