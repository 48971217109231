import IcomoonIcon from "../Icons/IcomoonIcon";

const SntInfoField = ({ className, children, iconClassName = "" }) => {
  return (
    <div
      className={className ? className : ""}
      style={{
        color: "#424B59",
        marginBottom: "10px",
      }}
    >
      <div className="d-flex mt-1">
        <div style={{ minWidth: 24 }} className={iconClassName}>
          <IcomoonIcon icon="info" size={18} />
        </div>
        <div className="flex-grow-1">{children}</div>
      </div>
    </div>
  );
};

export default SntInfoField;
