import { apiClient } from "./apiClient";
import { saveAs } from "file-saver";

export default {
  search(params) {
    return apiClient.post(`/rest/private_cellular/search`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  downloadTemplateFile() {
    return apiClient
      .get("/rest/private_cellular/downloadTemplate", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "Template_example.xlsx");
      });
  },

  exportAsExcel(orgId) {
    return apiClient
      .get("/rest/private_cellular/exportExcel/" + orgId, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "Cellular_towers.xlsx");
      });
  },

  uploadPrivateCellularFile(orgId, params) {
    return apiClient.post("/rest/private_cellular/upload/" + orgId, params, {
      headers: { "content-type": "multipart/form-data" },
    });
  },
};
