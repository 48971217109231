import BackHistory from "../../BackHistory/BackHistory";
import SntBrowserTabTitle from "../../../components/SntBrowserTabTitle/SntBrowserTabTitle";
import { Col, Row } from "react-bootstrap";
import SntHideLongText from "../../../components/ReactBootstrap/SntHideLongText";
import Cookies from "js-cookie";
import DateRangeContainer from "../../../cleanup/containers/dates/DateRangeContainer";

const ActionHeader = ({
  title,
  tabTitle,
  renderHeaderIcon,
  toolbar,
  isShowBackHistory = true,
  dateTimePickerOptions = {
    isShow: false,
    defaultOptions: {},
    overrideDisplays: {},
    onChanged: function () {},
  },
}) => {
  const sensolusFullScreen = Cookies.get("sensolus-full-screen");

  if (sensolusFullScreen) return <></>;

  return (
    <Row>
      <Col xs="12">
        <SntBrowserTabTitle title={tabTitle || title} />

        {dateTimePickerOptions && dateTimePickerOptions.isShow ? (
          <div className="justify-content-between d-block d-xl-flex heading">
            <div className="d-flex align-items-center">
              {isShowBackHistory && <BackHistory />}
              <h1 className="page-header">
                <SntHideLongText>{title}</SntHideLongText>
              </h1>
              {renderHeaderIcon && renderHeaderIcon()}
            </div>
            <div className="align-items-center">
              <div className="float-xl-end mt-2">
                <DateRangeContainer
                  value={dateTimePickerOptions.initialDefaultValue}
                  overrideDisplays={dateTimePickerOptions.overrideDisplays}
                  onChange={dateTimePickerOptions.onChanged}
                />
              </div>

              {toolbar && toolbar}
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-between heading">
            <div className="d-flex align-items-center w-50">
              {isShowBackHistory && <BackHistory />}
              <h1 className="page-header">
                <SntHideLongText>{title}</SntHideLongText>
              </h1>
              {renderHeaderIcon && renderHeaderIcon()}
            </div>
            <div className="d-flex justify-content-end align-items-center w-50">
              {toolbar && toolbar}
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default ActionHeader;
