// 3rd parties
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import nl from "date-fns/locale/nl";
import de from "date-fns/locale/de";
import enGB from "date-fns/locale/en-GB";
import es from "date-fns/locale/es";

// owner
import CustomDateInput from "./CustomDateInput";
import momentTZ from "../../../constants/SensolusMoment";

registerLocale("fr", fr);
registerLocale("nl", nl);
registerLocale("de", de);
registerLocale("en-gb", enGB);
registerLocale("es", es);

const DateWrapper = ({
  value,
  onChange,
  maxDate,
  minDate,
  disabled,
  showMonthYearPicker,
  showYearPicker,
  showTimeSelect,
  textDate,
  startDate,
  endDate,
  today = "Today",
  isShowIcon = false,
}) => {
  const getLocalZone = (date) => {
    const dateWithoutZoneFormat = momentTZ(date).format(
      "YYYY-MM-DDTHH:mm:ss.SSS"
    );
    return new Date(dateWithoutZoneFormat);
  };

  const getOtherZone = (date) => {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const dateWithBrowserZone = momentTZ.tz(date, currentTimezone);
    const formatNewZone = dateWithBrowserZone.format("YYYY-MM-DDTHH:mm:ss.SSS");

    return momentTZ(formatNewZone).toDate();
  };

  return (
    <ReactDatePicker
      locale={momentTZ.locale()}
      selected={getLocalZone(value)}
      customInput={
        <CustomDateInput textDate={textDate || value} isShowIcon={isShowIcon} />
      }
      onChange={(date) => {
        const value = getOtherZone(date);
        onChange && onChange(momentTZ(value));
      }}
      startDate={getLocalZone(startDate)}
      endDate={getLocalZone(endDate)}
      maxDate={maxDate ? maxDate.toDate() : undefined}
      minDate={minDate ? minDate.toDate() : undefined}
      disabled={disabled}
      showMonthYearPicker={showMonthYearPicker}
      showYearPicker={showYearPicker}
      showTimeSelect={showTimeSelect}
      shouldCloseOnSelect={startDate && !endDate}
      timeIntervals={1}
      todayButton={today}
      timeCaption="time"
      popperProps={{
        positionFixed: true, // use this to make the popper position: fixed
      }}
    />
  );
};

export default DateWrapper;
