import L from "leaflet";

// owner
import geobeaconClient from "@apis/geobeaconClient";
import {
  PruneCluster,
  PruneClusterForLeaflet,
} from "@/components/Leaflet/Map/PruneCluster/PruneCluster";
import { ClusterIcon } from "@/components/Leaflet/Map/Marker/ClusterIcon";
import { svgIcon, ICON_TYPE } from "@/components/Leaflet/Map/Marker/SvgIcon";

L.InfraLayer = L.FeatureGroup.extend({
  options: {},
  bounds: L.latLngBounds(),
  pruneCluster: null,
  updateData: function (orgId, options) {
    var self = this;
    let pruneCluster = new PruneClusterForLeaflet();
    self.pruneCluster = pruneCluster;
    PruneCluster.Cluster.ENABLE_MARKERS_LIST = true;
    pruneCluster.BuildLeafletClusterIcon = function (cluster) {
      let _length = cluster.population;
      var width = 24,
        textSize = 11;
      if (_length >= 100 && _length < 1000) {
        width = 36;
        textSize = 7;
      } else if (_length >= 1000) {
        width = 44;
        textSize = 6;
      }

      const svgString = `<svg width="${width}" height="${width}" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="cluster-bg" d="M6 0.5H12H18H20C21.933 0.5 23.5 2.067 23.5 4V6V12V18V20C23.5 21.933 21.933 23.5 20 23.5H18H12H6H4C2.067 23.5 0.5 21.933 0.5 20V18V12V6V4C0.5 2.067 2.067 0.5 4 0.5H6Z"
                  fill="#A900D5"/>
            <path id="cluster-fg" d="M4 2.5H12H20C20.8284 2.5 21.5 3.17157 21.5 4V7V12V20C21.5 20.8284 20.8284 21.5 20 21.5H17H12H7H4C3.17157 21.5 2.5 20.8284 2.5 20V12V7V4C2.5 3.17157 3.17157 2.5 4 2.5Z"
                  stroke="#FFFFFF"/>
            <text id="cluster-text" x="50%" y="50%" font-size="${textSize}" font-weight="bold" transform="translate(0, 3.5)" text-anchor="middle" fill="#ffffff">${_length}</text>
          </svg>
      `;
      return ClusterIcon({
        count: _length,
        svgString: svgString,
        className: "cluster-svg-infras",
      });
    };
    pruneCluster.PrepareLeafletMarker = function (leafletMarker, data) {
      data.icon.setMarker(leafletMarker);
      leafletMarker.setIcon(data.icon);
      if (data.tooltip) {
        leafletMarker.unbindTooltip();
        leafletMarker.bindTooltip(data.tooltip, {
          permanent: true,
          direction: "top",
          className: "transparent-tooltip",
          offset: [0, -8],
        });
      }
    };

    let basic = {
      searchQueryType: "BASIC",
      queryObjectType: "SIGFOX_DEVICE",
      query: [],
      requestedTableColumns: [
        {
          columnKey: "name",
          order: null,
        },
        {
          columnKey: "serial",
          order: null,
        },
        {
          columnKey: "address",
          order: null,
        },
        {
          columnKey: "location",
          order: null,
        },
        {
          columnKey: "currentLat",
          order: null,
        },
        {
          columnKey: "currentLng",
          order: null,
        },
        {
          columnKey: "infrastructureScanType",
          order: null,
        },
        {
          columnKey: "status",
          order: null,
        },
      ],
    };

    if (orgId) {
      basic.selectedOrgId = orgId;
    }
    // basic.query.push({
    //   categoryId: "userInfo",
    //   filterKey: "organizations",
    //   filterType: "MULTI_SELECT_AUTO_COMPLETE",
    //   searchType: "STATIC",
    //   notFilter: false,
    //   filterValue: {
    //     includeEmpty: false,
    //     includeNoEmpty: false,
    //     selectedValues: [orgId],
    //   },
    // });

    if (options.query) {
      basic.query = options.query.filter(
        (item) =>
          item.filterKey === "organizations" || item.filterKey === "partners"
      );
    }

    self.bound = L.latLngBounds();
    self.clearLayers();

    self.addLayer(self.pruneCluster);

    geobeaconClient.search(basic).then(({ data }) => {
      self.processData(data.data, options);
    });
  },
  transformData: function (beacon) {
    beacon.coordinate = {
      lat: beacon.currentLat,
      lon: beacon.currentLng,
      lastSeen: beacon.lastSeenAlive,
    };
  },
  isValidCoordinate: function (coordinate) {
    return !!(coordinate && (coordinate.lat || coordinate.lon));
  },
  processData: function (data, options) {
    let self = this;
    let devices = [];
    data.forEach((obj) => {
      if (options && +options.ignoreInfraId === obj.id) return;
      self.transformData(obj);
      if (self.isValidCoordinate(obj.coordinate)) {
        devices.push(obj);
      }
    });
    self.processMarkers(devices);
  },
  processMarkers: function (devices) {
    let self = this;

    // register the remaining markers
    devices.forEach((beacon) => {
      // new marker, add to cluster
      let coordinate = beacon.coordinate;
      let icon = svgIcon({
        iconUrl: beacon.image ? beacon.image : "/images/beacon/default.svg",
        id: beacon.id,
        trackerName: beacon.name,
        state: beacon.status,
        iconAnchor: [10, 28],
        network: false,
        type: ICON_TYPE.infraType,
      });

      let marker = new PruneCluster.Marker(
        coordinate.lat || 0,
        coordinate.lon || 0,
        {
          icon: icon,
          tooltip: beacon.name,
        }
      );
      marker.data.beaconInfo = beacon;
      marker.getLatLng = function () {
        return new L.latLng(this.position.lat, this.position.lon);
      };

      self.bound.extend(L.latLng(coordinate.lat, coordinate.lon));
      self.pruneCluster.RegisterMarker(marker);
    });

    self.pruneCluster.ProcessView();
  },
  getLayer: function () {
    return this;
  },
  getBounds: function () {
    return this.bounds;
  },
});

L.infraLayer = function (opts) {
  return new L.InfraLayer(opts);
};
export const InfraLayer = L.infraLayer;
