import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Components
import SntDialog from "../../../SntDialog/SntDialog";

// APIs
import storedSearchQuery from "../../../../apis/storedSearchQuery";

const SaveFilter = ({ onSave, onClose, data, isShow }) => {
  const language = useSelector((state) => state.language);

  const [filterName, setFilterName] = useState("");

  const onSaveFilter = () => {
    if (filterName.trim() !== "") {
      if (data.appliedFilterSettings === 0) {
        // self._$errorMessage.html(LANG.saving_filter_message).show()
        // show error
      } else {
        let params = {
          name: filterName,
          tableSettings: data.tableSettings || {},
        };

        params.queryType = "BASIC";
        params.queryObjectType = data.queryObjectType;
        params.appliedFilterSettings = data.appliedFilterSettings;
        storedSearchQuery
          .create(params)
          .then((data) => {
            data.appliedFilterSettings = JSON.parse(
              data.data.appliedFilterSettings
            );
            onSave && onSave(filterName);
          })
          .catch((response) => {});
      }
    }
  };

  const dialogBody = () => {
    return (
      <div className="row">
        <div className="col-md-3">
          <label> {language.saving_filter_label} </label>
        </div>
        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    setFilterName(data.title || "");
  }, [data.title]);

  return (
    <SntDialog
      isAllowClickOutside={true}
      isShow={isShow}
      width="40%"
      onSave={onSaveFilter}
      onClose={onClose}
      title={language.saving_filter_header}
      saveTxt={language.save_key}
      closeTxt={language.cancel_key}
      // eslint-disable-next-line react/no-children-prop
      children={dialogBody}
    ></SntDialog>
  );
};

export default SaveFilter;
