import * as types from "../redux/ActionTypes";
import authenticationClient from "../apis/authenticationClient";
import TagManager from "react-gtm-module";
import GoogleTagManager from "@/constants/GoogleTagManager";

var initialState = {};
var myReducer = (state = initialState, action) => {
  let loginInfo = action.data;

  switch (action.type) {
    case types.LOG_IN_SUCCESS:
      //old structure needs use global var

      var timezoneUserSetting;
      if (loginInfo.timezone) {
        timezoneUserSetting = loginInfo.timezone;
      } else if (loginInfo.organisation && loginInfo.organisation.timezone) {
        timezoneUserSetting = loginInfo.organisation.timezone;
      } else {
        // Should never happen because the organisation's timezone cannot be null
        // Just to prevent the application crash
        timezoneUserSetting = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      window.TIMEZONE_USER_SETTING = timezoneUserSetting;

      // Locale#week.dow should be an integer representing the first day of the week, 0 is Sunday, 1 is Monday, ..., 6 is Saturday.
      // @see: https://momentjscom.readthedocs.io/en/latest/moment/07-customization/16-dow-doy/
      // The new one from STIC-4133:
      loginInfo.weekStart = 1;
      if (loginInfo.firstDayOfWeek === "SUNDAY") {
        loginInfo.weekStart = 0;
      }

      window.loginInfo = loginInfo;
      window.USER_ID = loginInfo.userId;
      window.GLOBAL_PERMISSIONS = {};
      var permissions = loginInfo.userPermissions;
      for (var i = 0; i < permissions.length; i++) {
        window.GLOBAL_PERMISSIONS[permissions[i]] = true;
      }
      loginInfo.permissionsMap = window.GLOBAL_PERMISSIONS;

      window.FEATURES_PARAMETER = loginInfo.featureParameters || {};

      sessionStorage.setItem("SNT-VERSION", loginInfo.sntVersion);

      // eslint-disable-next-line no-case-declarations
      let assetLocationState = sessionStorage.getItem("ASSET_LOCATION");
      if (assetLocationState) {
        let pageSetting = JSON.parse(assetLocationState);
        if (pageSetting) {
          delete pageSetting.currentBounds;
          sessionStorage.setItem("ASSET_LOCATION", JSON.stringify(pageSetting));
        }
      }

      var VITE_GTM_ID = GoogleTagManager.VITE_GTM_ID,
        NO_VALUE = GoogleTagManager.NO_VALUE,
        hostName = window.location.hostname;

      if (
        sessionStorage.getItem(VITE_GTM_ID) &&
        sessionStorage.getItem(VITE_GTM_ID) !== NO_VALUE &&
        hostName !== "localhost"
      ) {
        TagManager.dataLayer({
          dataLayer: {
            userId: loginInfo.userId,
            userLanguage: loginInfo.language,
            orgId: loginInfo.organisation.id,
            orgName: loginInfo.organisation.name,
            plan: loginInfo.organisation.platformSubscription.basePlanKey,
            numberOfActiveSubscriptions:
              loginInfo.organisation.numberOfActiveSubscriptions,
            organisationClassification:
              loginInfo.organisation.organisationClassification,
            organizationMainAssetType:
              loginInfo.organisation.organizationMainAssetType,
            organizationCountry: loginInfo.organisation.organizationCountry,
            organizationRegion: loginInfo.organisation.organizationRegion,
            organizationSegment: loginInfo.organisation.organizationSegment,
          },
        });
      }

      return { ...action.data };
    case types.UPDATE_PROFILE:
      loginInfo = { ...state, ...action.data };

      return loginInfo;
    case types.LOG_OUT:
      authenticationClient
        .logout()
        .then(() => {
          localStorage.removeItem("sensolus-impersonate-user");

          if (action.callBack) {
            action.callBack();
          } else {
            window.history.pushState({}, null, "/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return {};
    default:
      return state;
  }
};

export default myReducer;
