import AwesomeIcon from "@/components/Icons/AwesomeIcon";
import { forwardRef } from "react";

// 3rd parties
import { Form, InputGroup } from "react-bootstrap";

// eslint-disable-next-line react/display-name
const CustomDateInput = forwardRef(
  ({ value, textDate, disabled, onClick, isShowIcon }, ref) => {
    return isShowIcon ? (
      <InputGroup className="mb-3">
        <Form.Control
          disabled={disabled}
          type="text"
          onClick={() => {
            onClick();
          }}
          onChange={onClick}
          ref={ref}
          value={textDate}
        />
        <InputGroup.Text>
          <AwesomeIcon icon={["far", "calendar-days"]}></AwesomeIcon>
        </InputGroup.Text>
      </InputGroup>
    ) : (
      <Form.Control
        disabled={disabled}
        type="text"
        onClick={() => {
          onClick();
        }}
        onChange={onClick}
        ref={ref}
        value={textDate}
      />
    );
  }
);

export default CustomDateInput;
