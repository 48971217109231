import L from "leaflet";
L.CircleGroup = L.Path.extend({
  options: {
    fill: "#0000FF",
    fillRule: "nonzero",
  },

  initialize: function (circles, options) {
    L.setOptions(this, options);
    this.setCircles(circles);
  },

  getCircles: function () {
    return this._circles;
  },

  setCircles: function (circles) {
    this._circles = circles;
    return this.redraw();
  },

  _project: function () {
    this._projectCircles(this._circles);
  },

  _projectCircles: function (circles) {
    this._projected = [];
    for (var i = 0; i < circles.length; i++) {
      this._projected.push({
        center: this._map.latLngToLayerPoint(circles[i].center),
        radius: this._projectRadius(circles[i].center, circles[i].radius),
      });
    }
  },

  _projectRadius: function (center, radiusInMeter) {
    var metresPerPixel =
      (40075016.686 * Math.abs(Math.cos((center.lat * Math.PI) / 180))) /
      Math.pow(2, this._map.getZoom() + 8);
    return radiusInMeter / metresPerPixel;
  },

  _update: function () {
    if (!this._map) {
      return;
    }
    this._updatePath();
  },

  _updatePath: function () {
    var path = "";
    for (var i = 0, len = this._projected.length; i < len; i++) {
      var circle = this._projected[i];
      path +=
        "M " +
        circle.center.x +
        " " +
        circle.center.y +
        " m -" +
        circle.radius +
        ", 0 a " +
        circle.radius +
        "," +
        circle.radius +
        " 0 1,1 " +
        2 * circle.radius +
        ",0 a " +
        circle.radius +
        "," +
        circle.radius +
        " 0 1,1 -" +
        2 * circle.radius +
        ",0 z ";
    }

    this._renderer._setPath(this, path);
  },
});
export const CircleGroup = function (opts, options) {
  return new L.CircleGroup(opts, options);
};
export const circleGroupExtends = L.CircleGroup;
