import SntIcon from "./SntIcon";

const SntArrowRightIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path d="M5.52667 11.06L8.58667 8L5.52667 4.94L6.47334 4L10.4733 8L6.47334 12L5.52667 11.06Z" />
    </SntIcon>
  );
};

export default SntArrowRightIcon;
