import SntIcon from "./SntIcon";

const SntListIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.800049 8.8H2.40005V7.2H0.800049V8.8ZM0.800049 12H2.40005V10.4H0.800049V12ZM0.800049 5.6H2.40005V4H0.800049V5.6ZM4.00005 8.8H15.2V7.2H4.00005V8.8ZM4.00005 12H15.2V10.4H4.00005V12ZM4.00005 4V5.6H15.2V4H4.00005Z"
      />
    </SntIcon>
  );
};

export default SntListIcon;
