import SntCheckIcon from "@/components/Icons/SntCheckIcon";
import SntCloseIcon from "@/components/Icons/SntCloseIcon";

const CheckView = ({ value, language }) => {
  if (value === null || value === undefined) "";

  if (typeof value === "boolean") {
    return value ? <SntCheckIcon /> : <SntCloseIcon />;
  }

  if (typeof value === "string") {
    if (value.toUpperCase() === "FALSE") {
      return <SntCloseIcon />;
    } else if (value.toUpperCase() === "TRUE") {
      return <SntCheckIcon />;
    }
  }

  return <>{value}</>;
};

export default CheckView;
