import SntIcon from "./SntIcon";

const SntRemoveFilterIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        d="M7.5 0C11.636 0 15 3.364 15 7.5S11.636 15 7.5 15 0 11.636 0 7.5 3.364 0 7.5 0zm0 .882a6.618 6.618 0 100 13.236A6.618 6.618 0 007.5.882zM3.5 7h8a.5.5 0 110 1h-8a.5.5 0 010-1z"
      ></path>
    </SntIcon>
  );
};

export default SntRemoveFilterIcon;
