import ErrorLabel from "../../components/ReactBootstrap/FormValidation/ErrorLabel";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import PageHeader from "../../containers/PageHeader/PageHeader";
import SntPanel from "../../components/Panel/SntPanel";
import { Button, Col, Row } from "react-bootstrap";
import InputText from "../../components/ReactBootstrap/FormValidation/InputText";
import {
  FormFullScreenLayout,
  ItemSelector,
} from "../../components/ReactBootstrap/FormValidation";
import InputNumber from "@/components/ReactBootstrap/FormValidation/InputNumber.js";
import queryString from "query-string";
import { useEffect, useState } from "react";
import cellularAccountClient from "@apis/cellularAccountClient.js";
import SntText from "@/components/ReactBootstrap/SntText.js";
import * as Yup from "yup";

function CellularAccountManage() {
  const language = useSelector((state) => state.language);
  const history = useHistory();
  const location = useLocation();
  let { type, id } = queryString.parse(location.search);

  const [cellularAccount, setCellularAccount] = useState({
    name: "",
    cellularProvider: {
      value: "deutschetelekom",
    },
    applicationKey: "",
    applicationSecret: "",
    username: "",
    password: "",
    numberOfCallsPerMinute: "",
  });

  const cellularSimProviders = [
    { label: "deutschetelekom", value: "deutschetelekom" },
    { label: "once", value: "once" },
    { label: "oncetrial", value: "oncetrial" },
    { label: "sfr", value: "sfr" },
    { label: "telia", value: "telia" },
    { label: "telenet", value: "telenet" },
    { label: "vodafone", value: "vodafone" },
    { label: "epsilonsensolus", value: "epsilonsensolus" },
    { label: "epsilonalps", value: "epsilonalps" },
    { label: "unknown", value: "unknown" },
  ];

  const [requestError, setRequestError] = useState(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(language.valid_required_key),
    cellularProvider: Yup.string().required(language.valid_required_key),
  });

  useEffect(() => {
    type !== "create" &&
      cellularAccountClient.get(id).then(({ data }) => {
        setCellularAccount({
          name: data.name,
          cellularProvider: {
            value: data.cellularProvider.name,
          },
          applicationKey: data.applicationKey,
          applicationSecret: data.applicationSecret,
          username: data.username,
          password: data.password,
          numberOfCallsPerMinute: data.numberOfCallsPerMinute,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSubmit(values) {
    let newValues = {
      name: values.name,
      cellularProvider: values.cellularProvider.value,
      applicationKey: values.applicationKey,
      applicationSecret: values.applicationSecret,
      username: values.username,
      password: values.password,
      numberOfCallsPerMinute: values.numberOfCallsPerMinute,
    };

    if (type === "edit") {
      cellularAccountClient
        .update(id, newValues)
        .then(({ data }) => {
          history.push(`cellular_account`);
        })
        .catch((error) => {
          console.error(error);
          setRequestError(error.response.data.message);
        });
    } else {
      cellularAccountClient
        .create(newValues)
        .then(({ data }) => {
          history.push(`cellular_account`);
        })
        .catch((error) => {
          console.error(error);
          setRequestError(error.response.data.message);
        });
    }
  }

  return (
    <>
      <PageHeader title={language.create_cellular_account_key} />
      <Row>
        <Col xs="12">
          <Formik
            initialValues={cellularAccount}
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              return (
                <Form>
                  <SntPanel title={language.account_details}>
                    <FormFullScreenLayout
                      label={language.name_key}
                      isRequired={true}
                      rightRender={() => {
                        return (
                          <InputText name="name" disabled={type === "view"} />
                        );
                      }}
                    />
                    <FormFullScreenLayout
                      label={language.provider_key}
                      isRequired={true}
                      rightRender={() => {
                        return (
                          <ItemSelector
                            name="cellularProvider"
                            fieldCol="col-lg-5"
                            disabled={type === "view" || type === "edit"}
                            isRequired={true}
                            multi={false}
                            options={cellularSimProviders}
                          />
                        );
                      }}
                    />
                    <FormFullScreenLayout
                      label={language.application_key}
                      isRequired={false}
                      rightRender={() => {
                        return (
                          <InputText
                            name="applicationKey"
                            disabled={type === "view"}
                          />
                        );
                      }}
                    />
                    <FormFullScreenLayout
                      label={language.application_secret}
                      isRequired={false}
                      rightRender={() => {
                        return (
                          <InputText
                            name="applicationSecret"
                            disabled={type === "view"}
                          />
                        );
                      }}
                    />
                    <FormFullScreenLayout
                      label={language.user_name_key}
                      isRequired={false}
                      rightRender={() => {
                        return (
                          <InputText
                            name="username"
                            disabled={type === "view"}
                          />
                        );
                      }}
                    />
                    <FormFullScreenLayout
                      label={language.user_password_key}
                      isRequired={false}
                      rightRender={() => {
                        return (
                          <InputText
                            name="password"
                            disabled={type === "view"}
                          />
                        );
                      }}
                    />
                    <FormFullScreenLayout
                      label={language.number_of_calls_per_minute}
                      isRequired={false}
                      rightRender={() => {
                        return (
                          <Row>
                            <Col xs="8" lg="8">
                              <InputNumber
                                name="numberOfCallsPerMinute"
                                disabled={type === "view"}
                              />
                            </Col>
                            <Col xs="4" lg="4" className="mt-2">
                              <SntText value={language.calls_key} />
                            </Col>
                          </Row>
                        );
                      }}
                    />

                    <FormFullScreenLayout rightRender={() => <ErrorLabel />} />
                  </SntPanel>

                  {requestError !== null && (
                    <Col xs="12" className="mb-3">
                      <ErrorLabel error={requestError} />
                    </Col>
                  )}

                  <Col xs="12" className="mb-3">
                    <Button
                      variant="sensolus"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      {language.save_key}
                    </Button>
                    <Button
                      variant="outline-sensolus"
                      className="ms-2"
                      onClick={() => history.push("/cellular_account")}
                    >
                      {language.cancel_key}
                    </Button>
                  </Col>
                </Form>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </>
  );
}

CellularAccountManage.propTypes = {};

export default CellularAccountManage;
