import { useEffect, useMemo, useRef, useState } from "react";

// Components
import MoreFilter from "./MoreFilter/MoreFilter";
import DateFilter from "../AdvancedFilters/DateFilter/DateFilter";
import StringFilter from "../AdvancedFilters/StringFilter/StringFilter";
import NumericFilter from "../AdvancedFilters/NumericFilter/NumericFilter";
import BooleanFilter from "../AdvancedFilters/BooleanFilter/BooleanFilter";
import MultiStringFilter from "../AdvancedFilters/MultiStringFilter/MultiStringFilter";
import MultiCheckboxFilter from "../AdvancedFilters/MultiCheckboxFilter/MultiCheckboxFilter";
import MultiSelectAutocompleteFilter from "../AdvancedFilters/MultiSelectAutocompleteFilter/MultiSelectAutocompleteFilter";
import { useSelector } from "react-redux";
import SntLink from "../ReactBootstrap/SntLink";
import { AdvanceFilter } from "./FilterStyle";
import { SntCollapse } from "../SntCollapse/SntCollapse";
import FilterTool from "../../containers/AdminLayout/Toolbar/FilterTool";
import { ActionEnum } from "../SntTableViewCard/ActionEnum";
import momentTZ from "@/constants/SensolusMoment";

// PROCESS: setFiltersVisible => loadFilter => Submit

const FilterList = ({
  isVisibleAddFilterBtn = true,
  restrictedFilters = [],
  onChange,
  data = {},
  onResetFilterData,
  filterListRef,
  disabled = false,
  totalElement,
  filterList,
  handleTool,
  deviceCategorySuggestion,
  forceFilterAction,
  setForceFilterAction,
}) => {
  const [filtersVisible, setFiltersVisible] = useState([]);
  const [components, setComponents] = useState([]);
  const language = useSelector((state) => state.language);

  const action = useRef({ type: ActionEnum.EMPTY, idx: null }); // actions of user
  const mapDescriptors = useRef({}); // the descriptor of filters which included _idx property
  const appliedFilterSettings = useRef({}); // the setting (data) of current filters
  const previousComponentRender = useRef([]);
  const filterRef = useRef();
  const _getDescriptorByFilter = (_filter) => {
    for (let _idx in mapDescriptors.current) {
      let _descriptor = mapDescriptors.current[_idx];

      if (
        (_descriptor.key === _filter.key ||
          _descriptor.key === _filter.filterKey) &&
        _descriptor.searchType === _filter.searchType
      ) {
        return mapDescriptors.current[_idx];
      }
    }
    return null;
  };

  const _getDescriptorByKey = (_key) => {
    for (var _idx in mapDescriptors.current) {
      if (mapDescriptors.current[_idx].key === _key) {
        return mapDescriptors.current[_idx];
      }
    }
    return null;
  };

  const _getDescriptorByIdx = (idx) => {
    for (var _idx in mapDescriptors.current) {
      if (mapDescriptors.current[_idx]._idx === idx) {
        return mapDescriptors.current[_idx];
      }
    }
    return null;
  };

  const _getDefaultApplyFilter = (idx) => {
    let { categoryId, key, filterType, searchType } = _getDescriptorByIdx(idx);
    if (categoryId && key && filterType && searchType) {
      return {
        categoryId: categoryId,
        filterKey: key,
        filterType: filterType,
        searchType: searchType,
        notFilter: false,
      };
    }
    return {};
  };

  const _sortByArray = (descArray, conditionArr) => {
    let arr = Array.from(descArray),
      result = [];
    for (let i = 0; i < conditionArr.length; i++) {
      let index = arr.indexOf(conditionArr[i]);
      if (index !== -1) {
        result.push(conditionArr[i]);
        arr.splice(index, 1);
      }
    }
    return [...result, ...arr];
  };

  const loadDescriptors = () => {
    for (var i = 0, len = data.descriptors.length; i < len; i++) {
      var _category = data.descriptors[i];
      var _searchFields = _category.searchFields || [];

      for (var j = 0; j < _searchFields.length; j++) {
        var _idx = "descriptor_" + i + "_" + j;
        var _field = _searchFields[j];

        if (restrictedFilters.indexOf(_field.key) !== -1) {
          _searchFields.splice(j, 1);
          j--;
          continue;
        }
        //_searchFields[j] reference to descriptor. Create new _idx as id of html element and add an extra _idx attribute to each descriptors
        _field._idx = _idx;
        mapDescriptors.current[_idx] = _field;
      }
    }
    loadAppliedFilter();
  };

  const loadAppliedFilter = () => {
    let _filtersVisible = [];
    let list = data.appliedFilterSettings || [];

    //convert appliedFilterSettings list to appliedFilterSettings map
    if (list.length > 0) {
      for (let i = 0, len = list.length; i < len; i++) {
        let _filter = list[i];

        let _descriptor = _getDescriptorByFilter(_filter);
        if (_descriptor) {
          appliedFilterSettings.current[_descriptor._idx] = _filter;
          _filtersVisible.push(_descriptor._idx);
        }
      }
      setFiltersVisible(_filtersVisible);
    }

    if (_filtersVisible.length === 0) {
      if (
        data.filtersVisibleAtInit.length === 0 ||
        Object.keys(mapDescriptors.current).length === 0
      )
        return;

      let _filtersVisibleAtInit = data.filtersVisibleAtInit || [];
      for (let i = 0, len = _filtersVisibleAtInit.length; i < len; i++) {
        let _key = _filtersVisibleAtInit[i];

        let _descriptor = _getDescriptorByKey(_key);
        if (_descriptor) {
          _filtersVisible.push(_descriptor._idx);
        }
      }
      appliedFilterSettings.current = {};
      setFiltersVisible(_filtersVisible);
    }
  };

  const loadFilters = (filters = filtersVisible) => {
    let _data = [];
    for (var i = 0; i < filters.length; i++) {
      var _key = filters[i];
      appliedFilterSettings.current[_key] = appliedFilterSettings.current[_key]
        ? appliedFilterSettings.current[_key]
        : _getDefaultApplyFilter(_key);

      _data.push({
        descriptor: mapDescriptors.current[_key],
        data: appliedFilterSettings.current[_key],
      });
    }
    setComponents(_data);
    submitFilter();
  };
  useEffect(() => {
    if (filterListRef)
      filterListRef.current = {
        clearFilter: function () {
          filterRef.current.click();
        },
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    action.current = {
      type: ActionEnum.EMPTY,
      idx: null,
    };
    loadDescriptors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    loadFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersVisible]);

  useEffect(() => {
    if (!forceFilterAction) return;

    let existedFilter = null;
    for (let key in appliedFilterSettings.current) {
      if (
        appliedFilterSettings.current[key].filterKey ===
        forceFilterAction.filterKeys[0]
      ) {
        existedFilter = appliedFilterSettings.current[key];
      }
    }

    if (!existedFilter) {
      for (let key in mapDescriptors.current) {
        if (
          mapDescriptors.current[key].key === forceFilterAction.filterKeys[0]
        ) {
          appliedFilterSettings.current[key] = _getDefaultApplyFilter(key);
          existedFilter = appliedFilterSettings.current[key];
          break;
        }
      }
    }

    if (existedFilter) {
      existedFilter.filterValue = existedFilter.filterValue
        ? { ...existedFilter.filterValue }
        : {};
      let { filterValue, filterType } = existedFilter;
      let { selectedValues = [], operator } = filterValue;
      let { action, query } = forceFilterAction;

      if (action === "FILTER_FOR_VALUE") {
        if (filterType === "MULTI_STRING") {
          filterValue.searchText = query?.length ? query[0] || "" : "";
        } else if (filterType === "STRING") {
          if (query?.length) {
            filterValue.searchText = query[0] || "";
          } else {
            // query is not exists = Add N/A value
            filterValue.searchText = "";
            filterValue.includeEmpty = true;
            filterValue.includeNoEmpty = false;
          }
        } else if (filterType === "BOOLEAN") {
          filterValue.value = query?.length
            ? query[0] === "true" || query[0] === true
            : true;
        } else if (filterType === "NUMERIC") {
          if (query?.length) {
            existedFilter.notFilter = false;
            filterValue.eq = query[0];
            filterValue.operator = "EQ";
          } else {
            existedFilter.notFilter = true;
            filterValue.eq = "";
            filterValue.operator = "NULL";
          }
        } else if (filterType === "DATE") {
          if (query?.length) {
            existedFilter.notFilter = false;
            filterValue.absoluteStartDate = momentTZ(query[0])
              .startOf("day")
              .format();
            filterValue.absoluteEndDate = momentTZ(query[0])
              .endOf("day")
              .format();
            filterValue.dateFilterType = "BETWEEN_ABSOLUTE";
            filterValue.intervalSign = null;
            filterValue.lastUnit = null;
            filterValue.lastValue = null;
            filterValue.relativeEndDate = null;
            filterValue.relativeStartDate = null;
          } else {
            delete existedFilter.filterValue;
          }
        } else {
          filterValue.selectedValues = query || [];
        }
      } else if (action === "FILTER_OUT_VALUE") {
        if (filterType === "MULTI_SELECT_CHECKBOX") {
          if (!query) {
            filterValue.operator = "NOT_NULL";
            filterValue.selectedValues = [];
          } else if (selectedValues.length === 0) {
            filterValue.operator = "NOT_IN";
            filterValue.selectedValues = [...query];
          } else if (operator === "NOT_IN") {
            filterValue.selectedValues = [...selectedValues, ...query];
          } else if (!operator || operator === "IN") {
            filterValue.selectedValues = filterValue.selectedValues.filter(
              (item) => !forceFilterAction.query.includes(item)
            );
            if (filterValue.selectedValues.length === 0) {
              filterValue.operator = "NOT_IN";
              filterValue.selectedValues = [...query];
            }
          } else {
            // convert other  operator options to NOT_IN
            filterValue.operator = "NOT_IN";
            filterValue.selectedValues = [...query];
          }
        } else if (filterType === "MULTI_SELECT_AUTO_COMPLETE") {
          if (query?.length > 0) {
            if (selectedValues.length === 0) {
              existedFilter.notFilter = true;
              filterValue.selectedValues = [...query];
            } else if (existedFilter.notFilter) {
              filterValue.selectedValues = [...selectedValues, ...query];
            } else {
              filterValue.selectedValues = filterValue.selectedValues.filter(
                (item) => !query.includes(item)
              );
              if (filterValue.selectedValues.length === 0) {
                existedFilter.notFilter = true;
                filterValue.selectedValues = [...query];
              }
            }
          } else {
            existedFilter.notFilter = true;
            filterValue.includeEmpty = true;
            filterValue.selectedValues = [];
          }
        } else if (filterType === "STRING") {
          if (query?.length > 0) {
            existedFilter.notFilter = true;
            filterValue.includeEmpty = false;
            filterValue.includeNoEmpty = false;
            filterValue.searchText = query[0];
          } else {
            existedFilter.notFilter = false;
            filterValue.includeEmpty = false;
            filterValue.includeNoEmpty = true;
            filterValue.searchText = "";
          }
        } else if (filterType === "BOOLEAN") {
          existedFilter.notFilter = true;
          filterValue.value = query?.length
            ? query[0] === "true" || query[0] === true
            : false;
        } else if (filterType === "NUMERIC") {
          if (query?.length) {
            existedFilter.notFilter = true;
            filterValue.eq = query[0];
            filterValue.operator = "EQ";
          } else {
            existedFilter.notFilter = true;
            filterValue.operator = "NULL";
          }
        } else if (filterType === "DATE" && query?.length) {
          existedFilter.notFilter = true;
          filterValue.absoluteStartDate = momentTZ(query[0])
            .startOf("day")
            .format();
          filterValue.absoluteEndDate = momentTZ(query[0])
            .endOf("day")
            .format();
          filterValue.dateFilterType = "BETWEEN_ABSOLUTE";
          filterValue.intervalSign = null;
          filterValue.lastUnit = null;
          filterValue.lastValue = null;
          filterValue.relativeEndDate = null;
          filterValue.relativeStartDate = null;
        }
      }
    }

    setForceFilterAction(null);

    let _data = [];
    for (let key in appliedFilterSettings.current) {
      _data.push(key);
    }

    action.current = { type: ActionEnum.UPDATE_FILTER, idx: null };
    setFiltersVisible(_sortByArray(_data, filtersVisible));
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceFilterAction]);

  const onChangeMoreFilter = (_data) => {
    // keep the order like the old one
    let newApplyFilterSettings = {};
    for (let i = 0; i < _data.length; i++) {
      if (appliedFilterSettings.current[_data[i]]) {
        newApplyFilterSettings[_data[i]] =
          appliedFilterSettings.current[_data[i]];
      } else {
        newApplyFilterSettings[_data[i]] = _getDefaultApplyFilter(_data[i]);
      }
    }
    appliedFilterSettings.current = newApplyFilterSettings;

    action.current = { type: ActionEnum.CHANGE_MORE_FILTER, idx: null };
    setFiltersVisible(_sortByArray(_data, filtersVisible));
  };

  // submit to parents that filter changed
  const submitFilter = (settings = appliedFilterSettings.current) => {
    let list = [];

    for (let i = 0; i < filtersVisible.length; i++) {
      if (settings[filtersVisible[i]]) {
        list.push(settings[filtersVisible[i]]);
      } else {
        list.push(_getDefaultApplyFilter(filtersVisible[i]));
      }
    }
    if (list.length > 0) {
      var basicQuery = {
        searchQueryType: "BASIC",
        query: list,
      };
      onChange && onChange(basicQuery, action.current);
    }
  };

  const componentsRender = useMemo(() => {
    const onUpdateSearchFilterItem = (_data) => {
      if (_data.data === null) {
        delete appliedFilterSettings.current[_data.idx];
      } else {
        var _descriptor = _getDescriptorByFilter(_data);
        if (_descriptor) {
          appliedFilterSettings.current[_descriptor._idx] = _data;
        }
      }
      action.current = { type: ActionEnum.UPDATE_FILTER, idx: null };
      loadFilters();
    };

    if (action.current.type === ActionEnum.REMOVE_FILTER) {
      let _render = previousComponentRender.current.filter(
        (c) => c.key !== action.current.idx
      );
      previousComponentRender.current = _render;
      action.current = { type: ActionEnum.EMPTY, idx: null };
      return _render;
    }

    let render = components.map((obj, index) => {
      let { descriptor, data } = obj;
      if (descriptor.filterType === "MULTI_STRING") {
        return (
          <MultiStringFilter
            key={descriptor._idx}
            descriptor={descriptor}
            data={data}
            disabled={disabled}
            onChange={onUpdateSearchFilterItem}
          />
        );
      } else if (descriptor.filterType === "STRING") {
        return (
          <StringFilter
            key={descriptor._idx}
            descriptor={descriptor}
            data={data}
            disabled={disabled}
            onChange={onUpdateSearchFilterItem}
          />
        );
      } else if (descriptor.filterType === "DATE") {
        return (
          <DateFilter
            key={descriptor._idx}
            descriptor={descriptor}
            data={data}
            disabled={disabled}
            onChange={onUpdateSearchFilterItem}
          />
        );
      } else if (descriptor.filterType === "NUMERIC") {
        return (
          <NumericFilter
            key={descriptor._idx}
            descriptor={descriptor}
            data={data}
            disabled={disabled}
            onChange={onUpdateSearchFilterItem}
          />
        );
      } else if (descriptor.filterType === "BOOLEAN") {
        return (
          <BooleanFilter
            key={descriptor._idx}
            descriptor={descriptor}
            data={data}
            disabled={disabled}
            onChange={onUpdateSearchFilterItem}
          />
        );
      } else if (descriptor.filterType === "MULTI_SELECT_CHECKBOX") {
        return (
          <MultiCheckboxFilter
            key={descriptor._idx}
            descriptor={descriptor}
            data={data}
            disabled={disabled}
            onChange={onUpdateSearchFilterItem}
            deviceCategorySuggestion={deviceCategorySuggestion}
          />
        );
      } else if (descriptor.filterType === "MULTI_SELECT_AUTO_COMPLETE") {
        return (
          <MultiSelectAutocompleteFilter
            key={descriptor._idx}
            descriptor={descriptor}
            data={data}
            disabled={disabled}
            onChange={onUpdateSearchFilterItem}
          />
        );
      }
      return null;
    });
    previousComponentRender.current = render;
    return render;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [components, disabled, onChange]);

  return (
    <>
      <SntCollapse>
        <div>
          {componentsRender}
          {isVisibleAddFilterBtn && (
            <MoreFilter
              onChange={onChangeMoreFilter}
              descriptors={data.descriptors}
              disabled={disabled}
              data={filtersVisible}
            />
          )}
          {totalElement && (
            <AdvanceFilter className="text-sensolus">
              {totalElement}
            </AdvanceFilter>
          )}
          {onResetFilterData && !disabled ? (
            <AdvanceFilter>
              <SntLink
                ref={filterRef}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onResetFilterData();
                }}
              >
                {language.clear_all}
              </SntLink>
            </AdvanceFilter>
          ) : null}
          {filterList && (
            <AdvanceFilter>
              <FilterTool
                list={filterList}
                onClick={handleTool}
                showFilterIcon={true}
              />
            </AdvanceFilter>
          )}
        </div>
      </SntCollapse>
    </>
  );
};

export default FilterList;
