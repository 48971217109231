import { useSelector } from "react-redux";
import { GreyTag } from "@/components/ReactBootstrap/TagMore";

const ExcludeLabel = () => {
  const language = useSelector((state) => state.language);

  return <GreyTag size={"sm"}>{language.exclude_upper_key}</GreyTag>;
};

export default ExcludeLabel;
