import SntIcon from "./SntIcon";

const SntSortIcon = (props) => {
  return (
    <SntIcon {...props}>
      <g clipPath="url(#clip0_3583_571)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 5L4 0L7 5H5V16H3V5L1 5Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 11L12 16L9 11L11 11L11 -3.49691e-07L13 -1.74846e-07L13 11L15 11Z"
        />
      </g>
    </SntIcon>
  );
};

export default SntSortIcon;
