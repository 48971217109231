import { useField } from "formik";
import { Form } from "react-bootstrap";

const InputNumber = ({
  name,
  placeholder = "",
  disabled = false,
  type = "number",
  className = "",
  min,
  max,
  required,
}) => {
  const [field, meta] = useField(name);

  let formGroupClass = "";
  if (meta && meta.touched) {
    if (meta.error) {
      formGroupClass = "is-invalid";
    } else {
      formGroupClass = "is-valid";
    }
  }

  const checkIfNumber = (event) => {
    /**
     * Allowing: Integers | Backspace | Tab | Delete | Left & Right arrow keys
     **/

    const regex = new RegExp(
      /(^-?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  return (
    <>
      <Form.Control
        className={`${className} ${formGroupClass}`}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        {...field}
        value={field.value || (field.value === 0 ? 0 : "")}
        min={min}
        max={max}
        required={required}
        onKeyDown={(event) => checkIfNumber(event)}
      />
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </>
  );
};

export default InputNumber;
