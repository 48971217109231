import { useEffect, useMemo, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "./routes";

import * as actions from "../../redux/actions";

import userClient from "../../apis/userClient";

import SntBrowserTabTitle from "../../components/SntBrowserTabTitle/SntBrowserTabTitle";
import { useMediaQuery } from "react-responsive";
import { useDebouncedEffect } from "../../hooks/useDebouncedEffect";
import commonClient from "@/apis/commonClient";
import TagManager from "react-gtm-module";
import GoogleTagManager from "@/constants/GoogleTagManager";
import { AppLanguageProvider } from "@/contexts/AppLanguageContext.js";

const App = () => {
  const dispatch = useDispatch();
  const loginInfo = useSelector((state) => state.user);

  let [languageLoading, setLanguageLoading] = useState(true);
  let [lang] = useState(loginInfo.language);
  const [forceRerender, setForceRerender] = useState(false);

  const [isGtmReady, setIsGtmReady] = useState(false);
  const [isGetCsrf, setIsGetCsrf] = useState(false);

  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // redirect url from alert email.
  if (history.location.hash.startsWith("#/admin/")) {
    history.push(history.location.hash.replace("#/admin/", ""));
  }

  useEffect(() => {
    userClient.getLanguage(lang).then((data) => {
      setLanguageLoading(false);
      dispatch(actions.updateLanguage(data.data));
    });
  }, [lang, dispatch]);

  useEffect(() => {
    let VITE_GTM_ID = GoogleTagManager.VITE_GTM_ID,
      NO_VALUE = GoogleTagManager.NO_VALUE;

    let viteGTMId = sessionStorage.getItem(VITE_GTM_ID),
      hostName = window.location.hostname;

    commonClient
      .getCsrfName()
      .then(({ data }) => {
        sessionStorage.setItem("csrf_name", data);
      })
      .catch((err) => {})
      .finally(() => {
        setIsGetCsrf(true);
      });

    if (viteGTMId || hostName === "localhost") {
      setIsGtmReady(true);
    } else {
      commonClient
        .getGTMContainerId()
        .then(({ data }) => {
          if (data && data.settingValue) {
            const tagManagerArgs = {
              gtmId: data.settingValue,
            };

            console.log("Initialize VITE_GTM_ID");
            TagManager.initialize(tagManagerArgs);
            sessionStorage.setItem(VITE_GTM_ID, data.settingValue);
          } else {
            sessionStorage.setItem(VITE_GTM_ID, NO_VALUE);
          }
        })
        .catch((err) => {
          sessionStorage.setItem(VITE_GTM_ID, NO_VALUE);
        })
        .finally(() => {
          setIsGtmReady(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const channel = useMemo(() => {
    return new BroadcastChannel("impersonate_reload");
  }, []);

  useEffect(() => {
    channel.onmessage = (ev) => {
      console.log("reload. impersonate_reload");
      window.location.reload(true);
    };

    return () => {
      channel.close();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  useEffect(() => {
    history.listen(() => {
      // only happen when navigating between pages (when location.pathname is updated)
      let newestSNTVersion = sessionStorage.getItem("NEWEST-SNT-VERSION");
      let loginSNTVersion = sessionStorage.getItem("SNT-VERSION");
      if (
        newestSNTVersion &&
        loginSNTVersion &&
        newestSNTVersion !== loginSNTVersion
      ) {
        sessionStorage.setItem("SNT-VERSION", newestSNTVersion);

        // override again to prevent http async response issue (or cache if the checking function is not good enough)
        sessionStorage.setItem("NEWEST-SNT-VERSION", newestSNTVersion);

        setForceRerender(true);
      } else if (!loginSNTVersion) {
        sessionStorage.setItem("SNT-VERSION", newestSNTVersion);
      }
    });

    let dataStore = localStorage.getItem("snt_control_layer");
    if (dataStore) {
      dispatch(actions.storeLayer(JSON.parse(dataStore)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDebouncedEffect(
    () => {
      if (forceRerender) {
        console.log(
          "reload after 1s. loginSNTVersion:",
          sessionStorage.getItem("SNT-VERSION")
        );
        window.location.reload();
      }
    },
    1000,
    [forceRerender]
  );

  useEffect(() => {
    dispatch(actions.setIsMobile({ isMobile: isTabletOrMobile }));
  }, [isTabletOrMobile, dispatch]);

  function showRoutes(routes) {
    var rs = null;
    if (routes.length > 0) {
      rs = routes.map((route, idx) => {
        return (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        );
      });
    }
    return rs;
  }

  if (languageLoading) {
    return null;
  }

  return (
    isGetCsrf &&
    isGtmReady && (
      <>
        <AppLanguageProvider>
          <SntBrowserTabTitle />
          <Switch>{showRoutes(routes)}</Switch>
        </AppLanguageProvider>
      </>
    )
  );
};

export default App;
