import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

// eslint-disable-next-line react/display-name
const AdvanceFilter = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className={`d-sm-inline-block mb-2 me-2 position-relative ${
        props.className || ""
      }`}
    >
      {props.children}
    </div>
  );
});

const FilterHeaderStyle = styled.div`
  display: inline-block;
  margin-bottom: -5px;
  max-width: 100px;
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  white-space: nowrap;
  font-weight: 700;
`;

const FilterBody = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1003;
  display: none;
  float: left;
  min-width: 310px;
  font-size: 14px;
  background-color: #ffffff;
  margin-top: -1px;
  border: 1px solid #b8bfca;
  border-radius: 0 0 4px 4px;
  background-clip: padding-box;
  padding: 0;
  box-shadow: 0 2px 10px rgb(0 0 0 / 18%);
`;

const FilterOptionHint = styled.span`
  float: right;
`;

const FilterLineLoaderStyle = styled(Row)`
  span {
    height: 26px;
    display: inline-block;

    &:nth-child(1) {
      background: linear-gradient(to right, #888e91, #bdc1c2);
      width: 20%;
    }

    &:nth-child(2) {
      background: linear-gradient(to right, #c1c4c6, #d9dbdc);
      width: 17%;
    }

    &:nth-child(3) {
      background: linear-gradient(to right, #dcdedf, #fdfdfd);
      width: 15%;
    }
  }
`;
const FilterLineLoader = () => {
  return (
    <FilterLineLoaderStyle>
      <Col xs="12">
        <span></span>
        <span></span>
        <span></span>
      </Col>
    </FilterLineLoaderStyle>
  );
};

export {
  AdvanceFilter,
  FilterHeaderStyle,
  FilterOptionHint,
  FilterBody,
  FilterLineLoader,
};
