import SntDownloadIcon from "../Icons/SntDownloadIcon";

const SntDownloadButton = (props) => {
  const { linkDownload, title } = props;

  return (
    <a
      href={linkDownload}
      title={title}
      className="btn btn-sensolus-transparent"
      // eslint-disable-next-line react/no-unknown-property
      dataplacement="bottom"
      target="_blank"
      rel="noopener noreferrer"
    >
      <SntDownloadIcon />
    </a>
  );
};
export default SntDownloadButton;
