import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import AdminLayout from "../../containers/AdminLayout/AdminLayout";
import SntLink from "../../components/ReactBootstrap/SntLink";
import SntActionButton from "../../components/ReactBootstrap/SntActionButton";
import SntPencilIcon from "../../components/Icons/SntPencilIcon";
import SntDeleteIcon from "../../components/Icons/SntDeleteIcon";
import useDeleteDialog from "@widgets/dialog/useDeleteDialog";
import { SntAdvancedSearchTableViewCard } from "../../components/SntTableViewCard";
import cellularAccountClient from "@apis/cellularAccountClient.js";

const pageName = "CELLULAR_ACCOUNT";
const SHARED_KEY = "CELLULAR_ACCOUNT";
const DEFAULT_OPTION = {
  pageName: pageName,
  queryObjectType: "CELLULAR_ACCOUNT",
  filtersVisibleAtInit: ["name", "cellularProvider"],
  optionsSettings: {},
};

const CellularAccount = ({
  reload,
  addFilter,
  isEmbeddedComponent = false,
}) => {
  //redux state
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  const { permissionsMap } = loginInfo;
  const table = useRef();
  const [forceFilterAction, setForceFilterAction] = useState(null);
  const [filterMap, setFilterMap] = useState(null);

  const allColumns = useMemo(() => {
    return [
      {
        key: "name",
        Cell: ({ cell, value }) => {
          return (
            <div>
              <SntLink
                to={`/cellular_account_manage?type=view&id=${cell.row.original.id}`}
              >
                {value}
              </SntLink>
            </div>
          );
        },
      },
      {
        key: "cellularProvider",
        Cell: ({ cell, value }) => {
          const provider = value;
          return provider ? provider.name : "";
        },
        cellCopyData: (data) => {
          return [data.cellularProvider?.name || ""];
        },
        cellFilterValue: (data) => {
          return [`${data.cellularProvider?.name || ""}`];
        },
      },
      {
        key: "applicationKey",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "applicationSecret",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "username",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "password",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "numberOfCallsPerMinute",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extraCols = useMemo(() => {
    if (permissionsMap["CELLULAR_ACCOUNT_MANAGE"]) {
      return [
        {
          key: "actions",
          title: language.actions_key,
          immovable: true,
          disableSortBy: true,
          canResize: false,
          Cell: ({ cell }) => {
            const full = cell.row.original;
            return (
              <div>
                <SntActionButton
                  title={language.edit_key}
                  to={`/cellular_account_manage?type=edit&id=${cell.row.original.id}`}
                >
                  <SntPencilIcon />
                </SntActionButton>

                <SntActionButton
                  title={language.delete_key}
                  onClick={(e) => onDeleteClicked(e, full)}
                >
                  <SntDeleteIcon />
                </SntActionButton>
              </div>
            );
          },
        },
      ];
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionsMap]);

  const defaultColumnInit = useMemo(
    () => [
      "name",
      "cellularProvider",
      "applicationKey",
      "username",
      "numberOfCallsPerMinute",
    ],
    []
  );

  const onDataRequestChange = (data, callback) => {
    const { params, dataOptions } = data;
    if (table.current) {
      table.current.setLoading(true);
      cellularAccountClient.search(params).then(
        ({ data }) => {
          if (table.current) {
            table.current.updateDataTable({
              ...data,
              pageCount: data.countByFilter,
              totalItem: {
                countAll: data.countAll,
                countByFilter: data.countByFilter,
              },
            });
            table.current.setLoading(false);

            const dataCallback = table.current.getDataCallBack(
              params,
              dataOptions,
              data
            );
            callback(dataCallback[0], dataCallback[1]);
          }
        },
        (err) => {
          table.current.setLoading(false);
        }
      );
    }
  };

  const {
    handleOpen,
    handleClose,
    handleOpenError,
    DeleteDialog,
  } = useDeleteDialog({
    onDelete: (itemSelected) => {
      cellularAccountClient
        .delete(itemSelected.id)
        .then(() => {
          handleClose();
          table.current.refresh();
        })
        .catch((error) => {
          handleClose();
          let message = "";
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          handleOpenError(message);
        });
    },
    language,
  });

  const onDeleteClicked = (el, item) => {
    el.preventDefault();
    handleOpen(
      item,
      item.name &&
        language.delete_message_with_name_key.replace("{0}", item.name)
    );
  };

  return (
    <div>
      <DeleteDialog />
      <AdminLayout
        showPermissionWarning
        browserTabTitle={
          language.UserPermission_MENU_ADMIN_CELLULAR_ACCOUNT_description_key
        }
        headerTitle={
          language.UserPermission_MENU_ADMIN_CELLULAR_ACCOUNT_description_key
        }
        reload={reload}
        pageName={pageName}
        sharedKey={SHARED_KEY}
        defaultOption={DEFAULT_OPTION}
        isAdvanceFilter={true}
        isShowBackHistory={false}
        isHideTheSearchUI={isEmbeddedComponent}
        totalLabel={language.cellular_accounts_lower_key}
        newButton={{
          isShow: permissionsMap["CELLULAR_ACCOUNT_MANAGE"],
          link: "/cellular_account_manage?type=create",
          label: language.add_cellular_account_key,
        }}
        body={({ isDisabled, dataOptions, onChangeChildren }) =>
          dataOptions && (
            <SntAdvancedSearchTableViewCard
              shared={(child) => (table.current = child)}
              allColumns={allColumns}
              extraCols={extraCols}
              preventCols={[]}
              visibleCols={defaultColumnInit}
              disabled={isDisabled}
              onChange={(data) => {
                onDataRequestChange(data, onChangeChildren);
              }}
              dataOptions={dataOptions}
              addFilter={addFilter}
              isDisabledCopyData={false}
              isDisabbledAddFilter={false}
              onFilterClicked={(action, key, filterKeys, query) => {
                setForceFilterAction({ action, key, filterKeys, query });
              }}
              filterMap={filterMap}
            />
          )
        }
        forceFilterAction={forceFilterAction}
        setForceFilterAction={setForceFilterAction}
        onLoadFilter={(data) => setFilterMap(data)}
      ></AdminLayout>
    </div>
  );
};

export default CellularAccount;
