import { useEffect, useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import { useSelector } from "react-redux";
import AwesomeIcon from "../Icons/AwesomeIcon";

export const SntCollapse = ({ children }) => {
  const { isMobile } = useSelector((state) => state.all);
  const [open, setOpen] = useState(!isMobile);

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  return (
    <>
      {isMobile && (
        <Button
          variant="sensolus-greylight"
          onClick={() => setOpen(!open)}
          aria-expanded={open}
          className="mb-2"
        >
          <AwesomeIcon icon="filter"></AwesomeIcon>
        </Button>
      )}
      <Collapse in={open}>{children}</Collapse>
    </>
  );
};
