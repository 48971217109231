import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SntTooltip from "../../components/Tooltip/SntTooltip";

const SntActionButton = (props) => {
  const {
    children,
    to,
    onClick,
    tooltipText,
    className = "",
    classIcon,
    ...rest
  } = props;
  return to ? (
    <Link
      {...rest}
      to={to}
      className={`btn btn-sm btn-sensolus-transparent ${className}`}
    >
      {children ? children : <i className={classIcon}></i>}
    </Link>
  ) : (
    <Button
      {...rest}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick(e);
        }
      }}
      variant="sensolus-transparent"
      size="sm"
      className={`${className}`}
    >
      {tooltipText ? (
        <SntTooltip
          text={tooltipText}
          icon={children ? children : <i className={classIcon}></i>}
        />
      ) : children ? (
        children
      ) : (
        <i className={classIcon}></i>
      )}
    </Button>
  );
};
export default SntActionButton;
