import { colorDefault } from "./ColorDefault";

const configurationUtils = {
  getIcon: function (state) {
    switch (state) {
      case "stop":
        return {
          bgColor: colorDefault.Sensolus_Blue_Darkest,
          bgHoverColor: colorDefault.Sensolus_BG_LightBlue,
          bgSelectedColor: colorDefault.Sensolus_Orange,
          iconColor: colorDefault.White,
          iconHoverColor: colorDefault.Sensolus_Blue_Darkest,
        };
      case "offline":
        return {
          bgColor: colorDefault.Sensolus_Grey_Light,
          bgHoverColor: colorDefault.Sensolus_BG_LightBlue,
          bgSelectedColor: colorDefault.Sensolus_Orange,
          iconColor: colorDefault.White,
          iconHoverColor: colorDefault.Sensolus_Blue_Darkest,
        };
      case "moving":
        return {
          bgColor: colorDefault.Green,
          bgHoverColor: colorDefault.Sensolus_BG_LightBlue,
          bgSelectedColor: colorDefault.Sensolus_Orange,
          iconColor: colorDefault.White,
          iconHoverColor: colorDefault.Sensolus_Blue_Darkest,
        };
      case "periodic":
        return {
          bgColor: colorDefault.Sensolus_Blue_Darkest,
          bgHoverColor: colorDefault.Sensolus_BG_LightBlue,
          bgSelectedColor: colorDefault.Sensolus_Orange,
          iconColor: colorDefault.White,
          iconHoverColor: colorDefault.Sensolus_Blue_Darkest,
        };
      case "cluster":
        return {
          bgColor: colorDefault.Sensolus_BG_Blue,
          bgHoverColor: colorDefault.Sensolus_BG_LightBlue,
          bgSelectedColor: colorDefault.Sensolus_Orange,
          iconColor: colorDefault.White,
          iconHoverColor: colorDefault.Sensolus_BG_Blue,
        };
      case "cluster_infras":
        return {
          bgColor: colorDefault.Sensolus_Pink_Dark,
          bgHoverColor: colorDefault.Sensolus_BG_LightBlue,
          bgSelectedColor: colorDefault.Sensolus_Orange,
          iconColor: colorDefault.White,
          iconHoverColor: colorDefault.Sensolus_Pink_Dark,
        };
      case "cluster_geozone":
        return {
          bgColor: "#E86124",
          bgHoverColor: colorDefault.Sensolus_BG_LightBlue,
          bgSelectedColor: colorDefault.Sensolus_Orange,
          iconColor: colorDefault.White,
          iconHoverColor: colorDefault.Sensolus_BG_Blue,
        };
      default:
        break;
    }
  },
  getJourneyIcon: function (state) {
    switch (state) {
      case "marker":
        return {
          bgColor: colorDefault.Sensolus_Blue_Darkest,
          bgHoverColor: colorDefault.Sensolus_Map_HighLight,
          bgSelectedColor: colorDefault.Sensolus_Orange,
        };
      case "marker-satellite":
        return {
          bgColor: colorDefault.Sensolus_Marker_Blue,
          bgHoverColor: colorDefault.Sensolus_Grey,
          bgSelectedColor: colorDefault.Sensolus_Orange,
        };
      case "marker-pink":
        return {
          bgColor: colorDefault.Sensolus_Pink_Light,
          bgHoverColor: colorDefault.Sensolus_Map_HighLight,
          bgSelectedColor: colorDefault.Sensolus_Orange,
        };
      case "marker-green":
        return {
          bgColor: colorDefault.Sensolus_Green_Light,
          bgHoverColor: colorDefault.Sensolus_Map_HighLight,
          bgSelectedColor: colorDefault.Sensolus_Orange,
        };
      case "marker-blue":
        return {
          bgColor: colorDefault.Sensolus_Blue,
          bgHoverColor: colorDefault.Sensolus_Map_HighLight,
          bgSelectedColor: colorDefault.Sensolus_Orange,
        };
      case "marker-brown":
        return {
          bgColor: colorDefault.Sensolus_Brown_Light,
          bgHoverColor: colorDefault.Sensolus_Map_HighLight,
          bgSelectedColor: colorDefault.Sensolus_Orange,
        };
      case "marker-purple":
        return {
          bgColor: colorDefault.Sensolus_Purple_Light,
          bgHoverColor: colorDefault.Sensolus_Map_HighLight,
          bgSelectedColor: colorDefault.Sensolus_Orange,
        };
      default:
        break;
    }
  },
};
export default configurationUtils;
