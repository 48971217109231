import styled from "styled-components";

const Style = styled.div`
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    right: 36px;
    width: 100%;
    height: 13px;
  }

  .lds-ellipsis div {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #212851;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

const ImportingSpinner = ({ loading = false, message = "Importing..." }) => {
  return (
    <Style style={{ display: loading ? "block" : "none" }}>
      <div className="panel-refresh-overlay" style={{ zIndex: 100 }}></div>
      <div className="progress-loader" style={{ zIndex: 100 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignTtems: "center",
          }}
        >
          <div style={{ whiteSpace: "nowrap" }}>
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignTtems: "center",
          }}
        >
          <div style={{ whiteSpace: "nowrap" }}>{message}</div>
        </div>
      </div>
    </Style>
  );
};
export default ImportingSpinner;
