import { useHistory } from "react-router";
import { useLocation } from "react-use";
import IcomoonIcon from "../../components/Icons/IcomoonIcon";

const ChangeModeHeaderIcon = () => {
  const history = useHistory();
  const location = useLocation();

  let searchParams = new URLSearchParams(location.search);
  let currentType = searchParams.get("type") || "view";

  const changeViewMode = () => {
    let pathname = location.pathname;
    if (currentType && currentType === "view") {
      searchParams.set("type", "edit");
    } else {
      searchParams.set("type", "view");
    }
    history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <span
      className="page-header-icon"
      onClick={changeViewMode}
      style={{
        color: "#5094BE",
        opacity: 1,
        paddingLeft: "10px",
        cursor: "pointer",
      }}
    >
      {currentType && currentType === "view" ? (
        <IcomoonIcon icon="pencil" size="16"></IcomoonIcon>
      ) : (
        <IcomoonIcon icon="eye" size="16"></IcomoonIcon>
      )}
    </span>
  );
};

export default ChangeModeHeaderIcon;
