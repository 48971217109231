export const supportedIntensityProximityConditions = {
  LIGHT: false,
  HEAVY: false,
  RECENT: false,
  IN_VICINITY: false,
};

export const supportedDescriptorConditions = {
  SHOWERS: false,
  SHALLOW: false,
  PATCHES: false,
  PARTIAL: false,
  DRIFTING: false,
  THUNDERSTORM: false,
  BLOWING: false,
  FREEZING: false,
};

export const supportedPrecipitationConditions = {
  RAIN: false,
  DRIZZLE: false,
  SNOW: false,
  SNOW_GRAINS: false,
  ICE_PELLETS: false,
  ICE_CRYSTALS: false,
  HAIL: false,
  SMALL_HAIL: false,
  UNKNOW_PRECIPITATION: false,
  FOG: false,
  VOLCANIC_ASH: false,
  MIST: false,
  HAZE: false,
  WIDESPREAD_DUST: false,
  SMOKE: false,
  SAND: false,
  SPRAY: false,
  SQUALL: false,
  SAND_WHIRLS: false,
  DUSTSTORM: false,
  SANDSTORM: false,
  FUNNEL_CLOUD: false,
};
