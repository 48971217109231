import { useEffect, useRef, useState } from "react";
import Select from "react-select";

const styles = {
  control: (base) => ({
    ...base,
    minHeight: 34,
    border: "1px solid #b8bfca",
    boxShadow: "none",
    "&:hover, &:focus": {
      border: "1px solid #0071a1",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const SntOrganizationSelector = ({
  value /* orgId */,
  onChange,
  items,
  placeholder,
}) => {
  const [currentItems, setCurrentItems] = useState(items);
  const index = useRef(0);
  const searchText = useRef("");
  const selectInput = useRef();

  useEffect(() => {
    index.current = 0;
    if (items.length > 0) {
      setCurrentItems(
        items.slice(index.current * 50, index.current * 50 + 50 - 1)
      );
    }
  }, [items]);

  const getSearchTextItems = () => {
    return items.filter(
      (s) =>
        s.label.toLowerCase().indexOf(searchText.current.toLowerCase()) !== -1
    );
  };

  const onMenuScrollToBottom = () => {
    index.current = index.current + 1;
    setCurrentItems([
      ...currentItems,
      ...getSearchTextItems().slice(
        index.current * 50,
        index.current * 50 + 50 - 1
      ),
    ]);
  };

  const onInputChange = (text) => {
    index.current = 0;
    searchText.current = text;
    setCurrentItems(
      getSearchTextItems().slice(
        index.current * 50,
        index.current * 50 + 50 - 1
      )
    );

    if (selectInput.current.select && selectInput.current.select.menuListRef) {
      selectInput.current.select.menuListRef.scrollTo(0, 0);
    }
  };

  return (
    <Select
      ref={(input) => (selectInput.current = input)}
      menuPosition={"fixed"}
      onChange={onChange}
      options={currentItems}
      onInputChange={onInputChange}
      onMenuScrollToBottom={onMenuScrollToBottom}
      styles={styles}
      placeholder={placeholder}
      value={items.filter((option) => option.value === value)}
      menuPlacement="auto"
    />
  );
};

export default SntOrganizationSelector;
