import { useEffect, useRef } from "react";
import L from "leaflet";
import { useSelector } from "react-redux";

const CircleMarker = ({ map, circleGeozone }) => {
  const language = useSelector((state) => state.language);
  let previousGeozoneRef = useRef();

  useEffect(() => {
    let bounds = L.latLngBounds();
    //fixed zoom in to much
    if (map && map.hasLayer(previousGeozoneRef.current)) {
      map.removeLayer(previousGeozoneRef.current);
    }

    if (
      circleGeozone.point.x &&
      circleGeozone.point.y &&
      circleGeozone.radius
    ) {
      previousGeozoneRef.current = L.circle(
        [circleGeozone.point.x, circleGeozone.point.y],
        {
          color: circleGeozone.borderColor,
          weight: 1,
          fillColor: circleGeozone.contentColor,
          radius: circleGeozone.radius,
        }
      ).bindTooltip(circleGeozone.name || language.unknown_key, {
        sticky: true,
        offset: [0, -4],
      });
      // networkCircle.clearLayers();
      previousGeozoneRef.current.addTo(map);
      bounds.extend(previousGeozoneRef.current.getBounds());
    }

    bounds.isValid() && map.fitBounds(bounds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, circleGeozone]);

  return <></>;
};

export default CircleMarker;
