import styled from "styled-components";

const HideLongTextStyle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  white-space: nowrap;
`;

const SntHideLongText = (props) => {
  const { text, children, className, ...rest } = { ...props };
  return (
    <HideLongTextStyle className={className} title={text || ""} {...rest}>
      {children || text}
    </HideLongTextStyle>
  );
};
export default SntHideLongText;
