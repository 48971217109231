import { useState } from "react";
import { useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import SntCloseSmallIcon from "../Icons/SntCloseSmallIcon";
import SntSearchIcon from "../Icons/SntSearchIcon";

const SntSearch = ({
  value = "",
  placeholder = "",
  disabled = false,
  onChange,
  title = "",
  className = "",
}) => {
  const [keyUpDelay, setKeyUpDelay] = useState(null);
  const [searchText, setSearchText] = useState(value);

  useEffect(() => {
    setSearchText(value);
  }, [value]);

  const onChangeInput = (text) => {
    setSearchText(text);
    // Key up delay 500ms
    if (keyUpDelay) clearTimeout(keyUpDelay);

    setKeyUpDelay(
      setTimeout(() => {
        onChange && onChange(text);
      }, 500)
    );
  };

  return (
    <InputGroup style={{ width: 300 }} className={className}>
      <Form.Control
        size="md"
        style={searchText ? { borderRight: "white" } : { borderRight: "" }}
        value={searchText}
        placeholder={placeholder}
        title={title}
        disabled={disabled}
        onChange={(e) => onChangeInput(e.target.value)}
      />
      {searchText && !disabled && (
        <InputGroup.Text
          style={{ background: "none" }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSearchText("");
            onChange && onChange("");
          }}
        >
          <SntCloseSmallIcon />
        </InputGroup.Text>
      )}
      <InputGroup.Text
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setSearchText(searchText);
          onChange && onChange(searchText);
        }}
      >
        <SntSearchIcon />
      </InputGroup.Text>
    </InputGroup>
  );
};

export default SntSearch;
