import { apiClient } from "./apiClient";

export default {
  getById(id) {
    return apiClient.get("/rest/rma_declaration/" + id);
  },

  create(rmaDeclaration) {
    return apiClient.post("/rest/rma_declaration", rmaDeclaration);
  },

  update(id, rmaDeclaration) {
    return apiClient.put(`/rest/rma_declaration/${id}`, rmaDeclaration);
  },

  delete(id) {
    return apiClient.delete(`/rest/rma_declaration/${id}`);
  },

  search(params) {
    return apiClient.post(`/rest/rma_declaration/search`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  getPdfFile(id) {
    return apiClient.defaults.baseURL + `/rest/rma_declaration/getPdf/${id}`;
  },
  getDownloadLink(id) {
    return (
      apiClient.defaults.baseURL +
      `/rest/rma_declaration/getPdf/${id}?isDownload=true`
    );
  },
};
