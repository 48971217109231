import { useSelector } from "react-redux";
import SntDialog from "../../SntDialog/SntDialog";
import SntSortZA from "../../Icons/SntSortZA";
import SntSortAZ from "../../Icons/SntSortAZ";
import styled from "styled-components";
import SntUnGroup from "../../Icons/SntUnGroup";
import SntGroupBy from "../../Icons/SntGroupBy";

const Styles = styled.div`
  .sortIcon {
    color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #eff3f4;
  }

  .sortIconHover {
    display: none;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #eff3f4;
  }

  .group-header-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #eff3f4;
  }
`;

const MobileSortingDiaglog = ({
  headers,
  show,
  setShowMobileSorting,
  toggleSortBy,
  features,
  isDisabbledGroup,
  dataFilter,
}) => {
  const language = useSelector((state) => state.language);

  return (
    <SntDialog
      isShow={show}
      isShowSaveButton={false}
      onClose={() => setShowMobileSorting(false)}
      title={language.sorting_settings}
    >
      {() => (
        <ul className="ps-0">
          {headers.map((column, index) => {
            return (
              <li className="list-group mb-1" key={index}>
                <Styles>
                  <label className="fw-bold list-group-item">
                    <div className="d-flex align-items-center">
                      <div
                        {...column.getSortByToggleProps({
                          title: column.description,
                        })}
                        onClick={() => {
                          toggleSortBy(
                            column.columnKey,
                            column.isSorted
                              ? !column.isSortedDesc
                              : column.isSortedDesc
                          );
                        }}
                      >
                        {column.isGrouped && (
                          <span style={{ fontWeight: "normal" }}>
                            {language.group_by_key}:{" "}
                          </span>
                        )}
                        {column.render("Header")}
                      </div>

                      {!column.disableSortBy && (
                        <div
                          className="ms-2"
                          {...column.getSortByToggleProps({
                            title: column.description,
                          })}
                          onClick={() => {
                            toggleSortBy(
                              column.columnKey,
                              column.isSorted
                                ? !column.isSortedDesc
                                : column.isSortedDesc
                            );
                          }}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <span className="sortIcon">
                                <SntSortZA />
                              </span>
                            ) : (
                              <span className="sortIcon">
                                <SntSortAZ />
                              </span>
                            )
                          ) : (
                            <span className="sortIconHover">
                              <SntSortAZ />
                            </span>
                          )}
                        </div>
                      )}

                      {features["search_aggregate"] &&
                        !isDisabbledGroup &&
                        !column.disabledGroupColumn && (
                          <>
                            {column.isGrouped ? (
                              <div
                                className="ms-2"
                                style={{ cursor: "pointer" }}
                              >
                                <span
                                  className="group-header-icon"
                                  title={language.ungroup_column_key}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    dataFilter.toggleGroupColumnKey(column.key);
                                  }}
                                >
                                  <SntUnGroup />
                                </span>
                              </div>
                            ) : (
                              column.isGrouped !== false && (
                                <div
                                  className="ms-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  <span
                                    className="group-header-icon"
                                    title={language.group_column_key}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      dataFilter.toggleGroupColumnKey(
                                        column.key
                                      );
                                    }}
                                  >
                                    <SntGroupBy />
                                  </span>
                                </div>
                              )
                            )}
                          </>
                        )}
                    </div>
                  </label>
                </Styles>
              </li>
            );
          })}
        </ul>
      )}
    </SntDialog>
  );
};

export default MobileSortingDiaglog;
