// 3rd parties
import { useSelector } from "react-redux";

import L from "leaflet";
import "leaflet-imageoverlay-rotated";

// owner
import { useOrg } from "@/contexts/OrgContext";
import geolayer from "@apis/geolayer";

import { extendedTileLayer } from "./Layers/ExtendedTileLayer";
import { GeozoneLayer } from "./Layers/LayerGeozone";
import { layerNetwork } from "./Layers/LayerNetwork";
import { HeatMapLayer } from "./Layers/LayerHeatMap";
import { InfraLayer } from "./Layers/LayerInfra";
import { AssetLayer } from "./Layers/LayerAsset";
import { userSelector } from "@/redux/selectors";

export default function useCustomMap() {
  const language = useSelector((state) => state.language);
  const { orgId } = useOrg();
  const loginInfo = useSelector(userSelector);
  const { features } = loginInfo;

  return L.Map.extend({
    layerControl: null,

    overlayLayers: [],

    baseLayers: [],

    addCustomLayersSucceed: function () {},

    addCustomLayers: function (id, options) {
      var self = this;
      self.clearCustomOverlayLayers();
      if (!features.loc_geolayer) return;

      self.getGeolayerData(id, options.isUseOwnerGeolayerData, (data) => {
        data?.forEach((layer) => {
          if (options && +options.ignoreGeolayerId === layer.id) return;

          try {
            switch (layer.type.name) {
              case "GEOJSON":
                self.addGeoJsonLayer(layer);
                break;
              case "IMAGE":
                self.addImageLayer(layer);
                break;
              case "TILE":
                self.addTileLayer(layer);
                break;
              case "KML":
                self.addKmlLayer(layer);
                break;
              case "ESRI":
                self.addEsriLayer(layer);
                break;
              default:
                break;
            }
            // eslint-disable-next-line no-empty
          } catch (ex) {}
        });
        self.addCustomLayersSucceed && self.addCustomLayersSucceed();
      });
    },

    getGeolayerData: (
      orgContextOrLoginOrgId,
      isUseOwnerGeolayerData,
      callback
    ) => {
      if (isUseOwnerGeolayerData) {
        geolayer
          .getByOrg({
            search: "",
            orgId: orgContextOrLoginOrgId ? orgContextOrLoginOrgId : orgId,
          })
          .then(
            ({ data }) => {
              callback && callback(data.data);
            },
            (err) => {}
          );
      } else {
        geolayer
          .getByOrgCheckVisibility({
            orgId: orgContextOrLoginOrgId ? orgContextOrLoginOrgId : orgId,
          })
          .then(({ data }) => {
            callback && callback(data.data);
          });
      }
    },

    addInfraLayer: function () {
      var infraLayer = InfraLayer();
      infraLayer.name = "Infra";
      infraLayer.type = "INFRA";
      infraLayer.options = { opacity: 1 };
      this.addOverlayLayer(infraLayer);
    },

    addAssetLayer: function () {
      var infraLayer = AssetLayer();
      infraLayer.name = "asset";
      infraLayer.type = "ASSET";
      infraLayer.options = { opacity: 1 };
      this.addOverlayLayer(infraLayer);
    },

    addGeozoneLayer: function () {
      var geozoneLayer = GeozoneLayer();
      geozoneLayer.name = language.geozones_key;
      geozoneLayer.type = "GEOZONE";
      geozoneLayer.options = { opacity: 1 };
      this.addOverlayLayer(geozoneLayer);
    },

    addNetworkLayer: function () {
      var networkLayer = layerNetwork();
      networkLayer.name = language.approximate_locations_key;
      networkLayer.type = "NETWORK";
      this.addOverlayLayer(networkLayer);
    },

    addHeatMapLayer: function () {
      var heatmapLayer = HeatMapLayer({
        // radius should be small ONLY if scaleRadius is true (or small radius is intended)
        // if scaleRadius is false it will be the constant radius used in pixels
        radius: 20,
        maxOpacity: 0.8,
        // scales the radius based on map zoom
        scaleRadius: false,
        // if set to false the heatmap uses the global maximum for colorization
        // if activated: uses the data maximum within the current map boundaries
        //   (there will always be a red spot with useLocalExtremas true)
        useLocalExtrema: true,
        // which field name in your data represents the latitude - default "lat"
        latField: "lat",
        // which field name in your data represents the longitude - default "lng"
        lngField: "lng",
        // which field name in your data represents the data value - default "value"
        valueField: "count",
      });
      heatmapLayer.name = "Heatmap";
      heatmapLayer.type = "HEATMAP";
      this.addOverlayLayer(heatmapLayer);
    },

    addGeoJsonLayer: function (layer) {
      var geojson = L.geoJSON(layer.data);
      geojson.name = layer.name;
      this.addOverlayLayer(geojson);
    },

    addImageLayer: function (layer) {
      var image = layer.data;
      var topLeft = L.latLng(image.topLeft.lat, image.topLeft.lng);
      var topRight = L.latLng(image.topRight.lat, image.topRight.lng);
      var bottomLeft = L.latLng(image.bottomLeft.lat, image.bottomLeft.lng);
      var options = image.options || {};
      options.pane = "customPane";
      options.opacity = layer.opacity || 1;
      options.bounds = layer.bounds || 1;
      options.zIndex = 1;
      var imageLayer = L.imageOverlay.rotated(
        image.imageSrc,
        topLeft,
        topRight,
        bottomLeft,
        options
      );
      imageLayer.name = layer.name;
      imageLayer.type = "IMAGE";
      imageLayer.category = layer.category;
      imageLayer.id = layer.id;
      imageLayer.description = layer.description;

      this.addOverlayLayer(imageLayer);
    },

    addTileLayer: function (layer) {
      var config = layer.data;
      let maxNativeZoom = 20;
      //maxNativeZoom for sigfox tile
      if (
        layer.data &&
        layer.data.url &&
        layer.data.url.indexOf("sigfox") !== -1
      ) {
        maxNativeZoom = 11;
      }
      var tileLayer = extendedTileLayer(config.url, {
        pane: "customPane",
        minZoom: 2,
        maxNativeZoom: maxNativeZoom,
        maxZoom: 22,
        zoomOffset: config.zoomOffset || 0,
        xOffset: config.xOffset || 0,
        yOffset: config.yOffset || 0,
        opacity: 0.7,
        zIndex: 2,
      });
      tileLayer.type = "TILE";
      tileLayer.name = layer.name;
      tileLayer.category = layer.category;
      tileLayer.description = layer.description;
      tileLayer.id = layer.id;
      this.addOverlayLayer(tileLayer);
    },

    addKmlLayer: function (layer) {
      var kmltext = layer.data.kml;
      var parser = new DOMParser();
      var kml = parser.parseFromString(kmltext, "text/xml");
      var kmlLayer = new L.KML(kml);
      kmlLayer.type = "KML";
      kmlLayer.name = layer.name;
      this.addOverlayLayer(kmlLayer);
    },

    addEsriLayer: function (layer) {
      var esriLayer = L.esri.dynamicMapLayer({
        url: layer.data.url,
        opacity: layer.data.opacity,
      });
      esriLayer.type = "ESRI";
      esriLayer.name = layer.name;
      this.addOverlayLayer(esriLayer);
    },

    getMapOverlayLayerByName: function (layerName) {
      var self = this;
      for (var i = 0; i < self.overlayLayers.length; i++) {
        if (self.overlayLayers[i].name === layerName) {
          return self.overlayLayers[i];
        }
      }
    },

    getMapBaseLayerByName: function (layerName) {
      var self = this;
      for (var i = 0; i < self.baseLayers.length; i++) {
        if (self.baseLayers[i].name === layerName) {
          return self.baseLayers[i];
        }
      }
    },

    clearCustomOverlayLayers: function () {
      var self = this;
      self.overlayLayers.forEach((layer) => {
        if (self.isCustomLayer(layer)) {
          self.removeLayer(layer);
          self.layerControl.removeLayer(layer);
        }
      });
      self.overlayLayers = self.overlayLayers.filter((layer) => {
        return !self.isCustomLayer(layer);
      });
    },

    isCustomLayer: function (layer) {
      return (
        layer.type === "GEOJSON" ||
        layer.type === "IMAGE" ||
        layer.type === "TILE" ||
        layer.type === "KML" ||
        layer.type === "ESRI"
      );
    },
    addOverlayLayer: function (overlayLayer) {
      if (
        !this.overlayLayers.find((layer) => layer.name === overlayLayer.name)
      ) {
        this.overlayLayers.push(overlayLayer);
        this.layerControl.addOverlay(overlayLayer, overlayLayer.name);
      }
    },
  });
}
