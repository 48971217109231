import SntIcon from "./SntIcon";

const SntAddFilterColumnIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path d="M-0.000244141 0H10.9998V1.24342H10.9509L6.72198 5.54567V13L4.27753 10.5132V5.53945L0.0547559 1.24342H-0.000244141V0Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9998 8H10.9998V11H7.99976V13H10.9998V16H12.9998V13H15.9998V11H12.9998V8Z"
      />
    </SntIcon>
  );
};

export default SntAddFilterColumnIcon;
