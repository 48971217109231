import { useLocation } from "react-router-dom";
import { usePrevious } from "./UsePrevious";
import queryString from "query-string";

export const useLocationChange = (paramNames) => {
  const location = useLocation();
  const prevLocation = usePrevious(location);

  const isLocationChanged = () => {
    if (prevLocation) {
      let prevParams = queryString.parse(prevLocation.search);
      let curParams = queryString.parse(location.search);
      return paramNames.some((name) => prevParams[name] !== curParams[name]);
    } else {
      return true;
    }
  };

  return { location, isLocationChanged };
};
