import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import AwesomeIcon from "../../components/Icons/AwesomeIcon";
import * as actions from "../../redux/actions";
import "./backhistory.css";

class BackHistory extends Component {
  render() {
    let { language, global } = this.props;

    return (
      <span className="leftBreakcrumb">
        {global.historyCount > 0 ? (
          <a
            href="# "
            onClick={(ev) => this.backClicked(ev)}
            title={language.back_overview_key}
            className="me-2"
          >
            <AwesomeIcon
              className="back-history"
              icon="arrow-left"
              size="xl"
            ></AwesomeIcon>
          </a>
        ) : null}
      </span>
    );
  }

  backClicked(ev, backDefault) {
    ev && ev.preventDefault();

    let { history, backHistory, global } = this.props;

    if (global.historyCount > 0) {
      backHistory();
      history.goBack();
    } else {
      window.location = backDefault;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    global: state.all,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    backHistory: () => {
      dispatch(actions.backHistory());
    },
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BackHistory);
