import { apiClient } from "./apiClient";
import apiUltils from "./apiUltils";

export default {
  getBeaconById(serial) {
    return apiClient.get(`/rest/infrastructure/serial/${serial}`);
  },
  getById(id) {
    return apiClient.get(`/rest/infrastructure/${id}`);
  },
  findAll(params) {
    return apiClient.get("/rest/infrastructure/", { params: params });
  },
  search(params) {
    return apiClient.post(`/rest/infrastructure/search`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  performOperation(orgId, data) {
    return apiClient.put(
      apiUltils.buildParamsListURI("/rest/infrastructure/bulkRegister", {
        orgId: orgId,
      }),
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },
  update(id, data) {
    return apiClient.put(`/rest/infrastructure/${id}`, data, {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    });
  },

  getRandomUniqueSecret() {
    return apiClient.get("/rest/infrastructure/generateSecret", {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    });
  },

  getRandomUniqueSerial() {
    return apiClient.get("/rest/infrastructure/generateSerial");
  },
};
