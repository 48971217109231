import SntIcon from "./SntIcon";

const SntCloseIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2225 2.90882L13.0912 1.77745L8 6.86862L2.90883 1.77745L1.77746 2.90882L6.86863 7.99999L1.77746 13.0912L2.90883 14.2225L8 9.13136L13.0912 14.2225L14.2225 13.0912L9.13137 7.99999L14.2225 2.90882Z"
      />
    </SntIcon>
  );
};

export default SntCloseIcon;
