import { useSelector } from "react-redux";
import { Formik, useField } from "formik";
import InputCheck from "../../components/ReactBootstrap/FormValidation/InputCheck.js";
import { useMemo } from "react";
import SntInfoField from "@/components/SntInfo/SntInfoField.js";
import { Col, Form, Row } from "react-bootstrap";
import FormGroupLayout from "../../components/ReactBootstrap/FormValidation/Layout/FormGroupLayout.js";

const MetarWeatherCondition = ({
  name,
  onConditionsChange,
  conditionType,
  conditionTypeLabel,
  pageMode,
}) => {
  const language = useSelector((state) => state.language);
  const [field, meta] = useField(name);

  let formGroupClass = "";
  if (meta && meta.touched) {
    if (meta.error) {
      formGroupClass = "is-invalid";
    } else {
      formGroupClass = "is-valid";
    }
  }
  const handleCheckboxChange = (condition, checked) => {
    onConditionsChange &&
      checked !== undefined &&
      onConditionsChange({ ...field.value, [condition]: checked });
  };

  const renderCondition = useMemo(() => {
    return Object.keys(field.value).map((condition) => {
      return (
        <Row className={"pt-2"} key={condition}>
          <Col xs="4" lg="4">
            <InputCheck
              name={condition}
              label={language[conditionType + "_" + condition + "_shortcut"]}
              handleChange={(value) => handleCheckboxChange(condition, value)}
              disabled={pageMode === "view"}
            />
          </Col>
          <Col xs="8" lg="8">
            <SntInfoField>
              {language[conditionType + "_" + condition + "_description"]}
            </SntInfoField>
          </Col>
        </Row>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, language]);

  return (
    <>
      <Formik initialValues={field.value} enableReinitialize={true}>
        {({ handleSubmit }) => {
          return (
            <FormGroupLayout
              xl="2"
              lg="2"
              label={conditionTypeLabel}
              rightRender={() => (
                <Row>
                  <Col className="align-self-center" xs="12" lg="12">
                    <>{renderCondition}</>
                  </Col>
                  <Form.Control.Feedback
                    type="invalid"
                    className={`${
                      formGroupClass === "is-invalid" ? "d-block" : "d-none"
                    }`}
                  >
                    {meta.error}
                  </Form.Control.Feedback>
                </Row>
              )}
            />
          );
        }}
      </Formik>
    </>
  );
};

export default MetarWeatherCondition;
