import ErrorLabel from "../../components/ReactBootstrap/FormValidation/ErrorLabel";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import PageHeader from "../../containers/PageHeader/PageHeader";
import SntPanel from "../../components/Panel/SntPanel";
import { Button, Col, Row } from "react-bootstrap";
import InputText from "../../components/ReactBootstrap/FormValidation/InputText";
import { FormFullScreenLayout } from "../../components/ReactBootstrap/FormValidation";
import InputNumber from "@/components/ReactBootstrap/FormValidation/InputNumber.js";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import metarWeatherLocationClient from "@apis/metarWeatherLocationClient.js";
import { useOrg } from "@/contexts/OrgContext.js";
import MetarWeatherCondition from "@pages/MetarWeatherLocation/MetarWeatherCondition.js";
import CommonMapReact from "@containers/CommonMapReact/CommonMapReact.js";
import SntText from "@/components/ReactBootstrap/SntText.js";
import CircleMarker from "@containers/CommonMapReact/CircleMarker.js";
import * as Yup from "yup";
import {
  supportedDescriptorConditions,
  supportedIntensityProximityConditions,
  supportedPrecipitationConditions,
} from "@pages/MetarWeatherLocation/MetarWeatherLocationUtils.js";
import { SensolusDatetimeReact } from "@/constants/Config.js";

function MetarWeatherLocationManage() {
  const language = useSelector((state) => state.language);
  const history = useHistory();
  const location = useLocation();
  let { type, id } = queryString.parse(location.search);
  const { orgId, updateOrg } = useOrg();
  const map = useRef();

  const [metarWeatherLocation, setMetarWeatherLocation] = useState({
    name: "",
    address: "",
    latitude: "",
    longitude: "",
    radius: "",
    precipitationConditions: Object.assign(
      {},
      supportedPrecipitationConditions
    ),
    intensityProximityConditions: Object.assign(
      {},
      supportedIntensityProximityConditions
    ),
    descriptorConditions: Object.assign({}, supportedDescriptorConditions),
  });

  const [requestError, setRequestError] = useState(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(language.valid_required_key),
    address: Yup.string().required(language.valid_required_key),
    latitude: Yup.number().required(language.valid_required_key),
    longitude: Yup.number().required(language.valid_required_key),
    radius: Yup.number()
      .min(
        1000,
        language.yup_validation_message_must_be_greater_than_or_equal_to
          .replace("{f}", language.radius_new_key)
          .replace("{v}", 1000)
      )
      .max(
        402336,
        language.yup_validation_message_must_be_less_than_or_equal_to
          .replace("{f}", language.radius_new_key)
          .replace("{v}", 402336)
      )
      .required(language.valid_required_key),
    precipitationConditions: Yup.object().test(
      "is-valid",
      language.valid_required_key,
      (precipitationConditions) =>
        Object.values(precipitationConditions).filter(Boolean).length > 0
    ),
  });

  useEffect(() => {
    type !== "create" &&
      metarWeatherLocationClient.get(id).then(({ data }) => {
        let intensityProximityConditions = Object.assign(
          {},
          supportedIntensityProximityConditions
        );
        let descriptorConditions = Object.assign(
          {},
          supportedDescriptorConditions
        );
        let precipitationConditions = Object.assign(
          {},
          supportedPrecipitationConditions
        );

        if (data.weatherCondition && data.weatherCondition.intensityProximity) {
          const currentIntensityProximity =
            data.weatherCondition.intensityProximity;
          for (const key in currentIntensityProximity) {
            intensityProximityConditions[currentIntensityProximity[key]] = true;
          }
        }

        if (data.weatherCondition && data.weatherCondition.descriptors) {
          const currentDescriptors = data.weatherCondition.descriptors;
          for (const key in currentDescriptors) {
            descriptorConditions[currentDescriptors[key]] = true;
          }
        }

        if (data.weatherCondition && data.weatherCondition.precipitations) {
          const currentPrecipitations = data.weatherCondition.precipitations;
          for (const key in currentPrecipitations) {
            precipitationConditions[currentPrecipitations[key]] = true;
          }
        }

        setMetarWeatherLocation({
          name: data.name,
          address: data.address,
          latitude: data.latitude,
          longitude: data.longitude,
          radius: data.radius,
          lastWeatherSync: data.lastWeatherSync,
          lastWeatherData: data.lastWeatherData,
          precipitationConditions: precipitationConditions,
          intensityProximityConditions: intensityProximityConditions,
          descriptorConditions: descriptorConditions,
        });

        updateOrg(data.ownedByOrg.id);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSubmit(values) {
    let chosenIntensityProximity = [];
    Object.keys(supportedIntensityProximityConditions).map((condition) => {
      if (values.intensityProximityConditions[condition]) {
        chosenIntensityProximity.push(condition);
      }
    });

    let chosenDescriptors = [];
    Object.keys(supportedDescriptorConditions).map((condition) => {
      if (values.descriptorConditions[condition]) {
        chosenDescriptors.push(condition);
      }
    });

    let chosenPrecipitations = [];
    Object.keys(supportedPrecipitationConditions).map((condition) => {
      if (values.precipitationConditions[condition]) {
        chosenPrecipitations.push(condition);
      }
    });

    let newValues = {
      name: values.name,
      address: values.address,
      latitude: values.latitude,
      longitude: values.longitude,
      radius: values.radius,
      orgId: orgId,
      weatherCondition: {
        intensityProximity: chosenIntensityProximity,
        descriptors: chosenDescriptors,
        precipitations: chosenPrecipitations,
      },
    };

    if (type === "edit") {
      metarWeatherLocationClient
        .update(id, newValues)
        .then(({ data }) => {
          history.push(`metar_weather_location?org=${orgId}`);
        })
        .catch((error) => {
          console.error(error);
          setRequestError(error.response.data.message);
        });
    } else {
      metarWeatherLocationClient
        .create(newValues)
        .then(({ data }) => {
          history.push(`metar_weather_location?org=${orgId}`);
        })
        .catch((error) => {
          console.error(error);
          setRequestError(error.response.data.message);
        });
    }
  }

  return (
    <>
      <PageHeader title={language.create_metar_weather_service_location} />
      <Row>
        <Col xs="12">
          <Formik
            initialValues={metarWeatherLocation}
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              return (
                <Form>
                  <SntPanel title={language.create_metar_weather_area}>
                    <FormFullScreenLayout
                      label={language.name_key}
                      isRequired={true}
                      rightRender={() => {
                        return (
                          <InputText name="name" disabled={type === "view"} />
                        );
                      }}
                    />
                    <FormFullScreenLayout
                      label={language.v41_address_key}
                      isRequired={true}
                      rightRender={() => {
                        return (
                          <InputText
                            name="address"
                            disabled={type === "view"}
                          />
                        );
                      }}
                    />
                    <FormFullScreenLayout
                      label={language.latitude_key}
                      isRequired={true}
                      rightRender={() => {
                        return (
                          <InputText
                            name="latitude"
                            disabled={type === "view"}
                          />
                        );
                      }}
                    />
                    <FormFullScreenLayout
                      label={language.longitude_key}
                      isRequired={true}
                      rightRender={() => {
                        return (
                          <InputText
                            name="longitude"
                            disabled={type === "view"}
                          />
                        );
                      }}
                    />
                    <FormFullScreenLayout
                      label={language.radius_new_key}
                      isRequired={true}
                      rightRender={() => {
                        return (
                          <Row>
                            <Col xs="8" lg="8">
                              <InputNumber
                                name="radius"
                                min={1000}
                                max={402336}
                                disabled={type === "view"}
                              />
                            </Col>
                            <Col xs="4" lg="4" className="mt-2">
                              <SntText value={language.meters_key} />
                            </Col>
                          </Row>
                        );
                      }}
                    />
                    <FormFullScreenLayout
                      label={language.preview}
                      rightRender={() => {
                        return (
                          <CommonMapReact
                            mapRef={map}
                            width="none"
                            height="30vh"
                            options={{
                              center:
                                values.latitude && values.longitude
                                  ? [values.latitude, values.longitude]
                                  : null,
                              zoom: 100,
                              hideCurrentLocation: true,
                              hideOverview: true,
                              searchBox: false,
                              hideFullScreen: true,
                            }}
                          >
                            <CircleMarker
                              circleGeozone={{
                                name: values.name,
                                point: {
                                  x: values.latitude,
                                  y: values.longitude,
                                },
                                radius: values.radius,
                                borderColor: "#2785db",
                                contentColor: "#fe0b0b",
                              }}
                              map={map.current}
                            />
                          </CommonMapReact>
                        );
                      }}
                    />

                    <FormFullScreenLayout rightRender={() => <ErrorLabel />} />
                  </SntPanel>

                  {type !== "create" && (
                    <SntPanel title={language.last_weather_sync}>
                      <FormFullScreenLayout
                        label={language.last_weather_data}
                        rightRender={() =>
                          metarWeatherLocation.lastWeatherData
                            ? metarWeatherLocation.lastWeatherData
                            : language.na_key
                        }
                      />
                      <FormFullScreenLayout
                        label={language.last_weather_sync_timestamp}
                        rightRender={() =>
                          metarWeatherLocation.lastWeatherSync
                            ? SensolusDatetimeReact.parse(
                                metarWeatherLocation.lastWeatherSync,
                                "server"
                              ).format("lll")
                            : language.na_key
                        }
                      />
                    </SntPanel>
                  )}

                  <SntPanel
                    title={language.set_metar_weather_bad_weather_conditions}
                  >
                    <MetarWeatherCondition
                      name="precipitationConditions"
                      onConditionsChange={(precipitationConditions) => {
                        setFieldValue(
                          "precipitationConditions",
                          precipitationConditions
                        );
                      }}
                      conditionType="precipitation"
                      conditionTypeLabel={language.precipitation_key + " *"}
                      pageMode={type}
                    />
                    <MetarWeatherCondition
                      name="intensityProximityConditions"
                      onConditionsChange={(intensityProximityConditions) => {
                        setFieldValue(
                          "intensityProximityConditions",
                          intensityProximityConditions
                        );
                      }}
                      conditionType="intensity"
                      conditionTypeLabel={language.intensity_of_proximity_key}
                      pageMode={type}
                    />
                    <MetarWeatherCondition
                      name="descriptorConditions"
                      onConditionsChange={(descriptorConditions) => {
                        setFieldValue(
                          "descriptorConditions",
                          descriptorConditions
                        );
                      }}
                      conditionType="descriptor"
                      conditionTypeLabel={language.descriptor_key}
                      pageMode={type}
                    />
                    <FormFullScreenLayout rightRender={() => <ErrorLabel />} />
                  </SntPanel>

                  {requestError !== null && (
                    <Col xs="12" className="mb-3">
                      <ErrorLabel error={requestError} />
                    </Col>
                  )}

                  <Col xs="12" className="mb-3">
                    <Button
                      variant="sensolus"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      {language.save_key}
                    </Button>
                    <Button
                      variant="outline-sensolus"
                      className="ms-2"
                      onClick={() =>
                        history.push("/metar_weather_location?org=" + orgId)
                      }
                    >
                      {language.cancel_key}
                    </Button>
                  </Col>
                </Form>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </>
  );
}

MetarWeatherLocationManage.propTypes = {};

export default MetarWeatherLocationManage;
