import * as types from "../redux/ActionTypes";

var initialState = {};
var myReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INTERFACE:
      if (action.data) {
        sessionStorage.setItem("customInterface", JSON.stringify(action.data));
      }
      return { ...action.data };
    default:
      return state;
  }
};

export default myReducer;
