import { useEffect, useRef, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-use";

// Manage tab routes with tabs
export const useRoutes = (data) => {
  const location = useLocation();
  const preLocation = useRef(location);
  const routeData = queryString.parse(location.search);

  const [routeParams, setRouteParams] = useState(
    data
      ? {
          tabActive: data.tabActive,
          ...routeData,
        }
      : { ...routeData }
  );

  useEffect(() => {
    if (preLocation.current.href !== location.href) {
      preLocation.current = location;
      setRouteParams((pre) => ({
        ...pre,
        ...routeData,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const updateRoute = (params) => {
    let url =
      data.route +
      new URLSearchParams({ ...routeParams, ...params }).toString();
    window.history.replaceState({ isInternal: true }, null, url);
  };

  return {
    routeParams,
    setRouteParams,
    updateRoute,
  };
};
