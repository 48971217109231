import SntIcon from "./SntIcon";

const SntSortZA = (props) => {
  return (
    <SntIcon {...props}>
      <g clipPath="url(#clip0_4364_689)">
        <rect width="16" height="16" fill="transparent" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 11L11 16L8 11L10 11L10 -3.49691e-07L12 -1.74846e-07L12 11L14 11Z"
        />
        <path d="M7 8H2V7.03762L5.22151 2.22572H2.08732V1H6.91268V1.95759L3.69577 6.77428H7V8Z" />
        <path d="M5.81812 15L5.44303 13.8147H3.55697L3.18188 15H2L3.8259 10H5.16702L7 15H5.81812ZM5.18117 12.9292C4.83439 11.8551 4.63859 11.2477 4.59377 11.1069C4.55131 10.9662 4.52064 10.8549 4.50177 10.7732C4.42392 11.0638 4.20099 11.7825 3.83298 12.9292H5.18117Z" />
      </g>
      <defs>
        <clipPath id="clip0_4364_689">
          <rect width="16" height="16" fill="transparent" />
        </clipPath>
      </defs>
    </SntIcon>
  );
};

export default SntSortZA;
