import { Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import TechnicalErorIcon from "@images/error/Technical_error_illustration.svg";
import errorBoundaryClient from "@apis/errorBoundaryClient";
import { useSelector } from "react-redux";
import { languageSelector } from "@/redux/selectors";
import { ErrorBoundary } from "react-error-boundary";
import { useRef } from "react";

const CustomErrorBoundary = ({ children }) => {
  const location = useLocation();

  const timeoutRef = useRef();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, errorInfo) => {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          onErrorHandler(error, errorInfo, location.pathname + location.search);
        }, 1000);
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

const ErrorFallback = () => {
  const history = useHistory();
  const language = useSelector(languageSelector);

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div style={{ fontSize: "2rem" }}>Oooops</div>
      <div className="mb-3">{language.technical_error_info_key}</div>
      <Button
        variant="sensolus-greylight"
        onClick={() => {
          history.push("/");
          window.location.reload(true);
        }}
      >
        {language.technical_error_back_home_key}
      </Button>

      <img src={TechnicalErorIcon} />
    </div>
  );
};

const onErrorHandler = (error, errorInfo, pathname) => {
  // Create a structured error log
  const errorDetails = {
    message: error.message + ". Url path: " + pathname,
    stack: error.stack,
    componentStack: errorInfo.componentStack,
    // You could include more context here, such as the current URL, user's id, etc.
  };

  // Send the error details to the server
  errorBoundaryClient
    .logError(errorDetails)
    .then(() => {})
    .catch(() => {});
};

export default CustomErrorBoundary;
