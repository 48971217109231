import SntIcon from "./SntIcon";

const SntMapIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8 0.800049L14.672 0.824049L10.4 2.48005L5.60005 0.800049L1.08805 2.32005C0.920049 2.37605 0.800049 2.52005 0.800049 2.70405V14.8C0.800049 15.024 0.976049 15.2 1.20005 15.2L1.32805 15.176L5.60005 13.52L10.4 15.2L14.912 13.68C15.08 13.624 15.2 13.48 15.2 13.296V1.20005C15.2 0.976049 15.024 0.800049 14.8 0.800049ZM10.4 13.6L5.60004 11.912V2.40004L10.4 4.08804V13.6Z"
      />
    </SntIcon>
  );
};

export default SntMapIcon;
