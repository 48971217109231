import SntIcon from "./SntIcon";

const SntGroupBy = (props) => {
  return (
    <SntIcon {...props}>
      <circle cx="8" cy="4" r="3" />
      <circle cx="3" cy="12" r="3" />
      <circle cx="13" cy="12" r="3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14C10.7614 14 13 11.7614 13 9C13 6.23858 10.7614 4 8 4C5.23858 4 3 6.23858 3 9C3 11.7614 5.23858 14 8 14ZM8 15C11.3137 15 14 12.3137 14 9C14 5.68629 11.3137 3 8 3C4.68629 3 2 5.68629 2 9C2 12.3137 4.68629 15 8 15Z"
      />
    </SntIcon>
  );
};

export default SntGroupBy;
