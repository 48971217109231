import { Col, Row } from "react-bootstrap";

const VerticalLayout = ({ className, children }) => {
  return (
    <Row className={className ? className : " pt-2"}>
      {children.map(
        (item, idx) =>
          item && (
            <Col xs="12" className="mb-3" key={idx}>
              {item}
            </Col>
          )
      )}
    </Row>
  );
};

export default VerticalLayout;
