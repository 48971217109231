import { useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import IcomoonIcon from "../Icons/IcomoonIcon";

const SntTooltip = ({ size = 16, className = "", text, icon, style = {} }) => {
  const ref = useRef();
  const [show, setShow] = useState(false);

  return (
    <span
      ref={ref}
      className={`${className}`}
      style={style}
      onMouseEnter={() => setShow(!show)}
      onMouseLeave={() => setShow(false)}
    >
      {icon ? icon : <IcomoonIcon icon="question" size="16"></IcomoonIcon>}
      <Overlay target={ref.current} show={show}>
        {/* https://github.com/react-bootstrap/react-overlays/issues/312 */}
        {({
          placement,
          scheduleUpdate,
          arrowProps,
          outOfBoundaries,
          show,
          ...props
        }) => (
          <div className="snt-tooltip" {...props}>
            {text}
          </div>
        )}
      </Overlay>
    </span>
  );
};
export default SntTooltip;
