import {apiClient} from "./apiClient";

export default {
  getAllValidCellularAccountsForDeviceSetting() {
    return apiClient.get(`/rest/cellularAccount`);
  },

  get(id) {
    return apiClient.get(`/rest/cellularAccount/${id}`);
  },

  create(params) {
    return apiClient.post(`/rest/cellularAccount`, params, {
      headers: { "content-type": "application/json" },
    });
  },

  update(id, params) {
    return apiClient.put(`/rest/cellularAccount/${id}`, params, {
      headers: { "content-type": "application/json" },
    });
  },

  delete(id) {
    return apiClient.delete(`/rest/cellularAccount/${id}`);
  },
  search(params) {
    return apiClient.post(`/rest/cellularAccount/search`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};
