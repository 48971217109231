import L from "leaflet";
import $ from "jquery";

L.Control.CurrentLocation = L.Control.extend({
  // @section
  // @aka Control.Zoom options
  options: {
    position: "topright",
    title: "Current Location",
  },

  onAdd: function (map) {
    var a = $(
      '<div class="leaflet-control-custom-location leaflet-bar" title="' +
        this.options.title +
        '"><a class="" javascript:void(0)></a></div>'
    );
    a.on("click", function () {
      navigator.geolocation &&
        navigator.geolocation.getCurrentPosition(function (position) {
          map.setView(
            [position.coords.latitude, position.coords.longitude],
            map.getMaxZoom() - 3
          );
        });
    });
    a.on("dblclick", function (e) {
      e.originalEvent.stopPropagation();
    });

    return a[0];
  },

  onRemove: function (map) {},
});
export const CurrentLocation = function (opts) {
  return new L.Control.CurrentLocation(opts);
};
