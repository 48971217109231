import { useMemo } from "react";
import PropTypes from "prop-types";

import RadioOffIcon from "@images/checkbox/radio-off.png";
import RadioOnIcon from "@images/checkbox/radio-on.png";
import RadioOffDisabledIcon from "@images/checkbox/radio-off-disabled.png";
import RadioOnfDisabledIcon from "@images/checkbox/radio-on-disabled.png";

const _nomarl = {
  display: "inline-block",
  verticalAlign: "middle",
  margin: 0,
  padding: 0,
  width: "16px",
  height: "16px",
  background: `url(${RadioOffIcon}) no-repeat`,
  border: "none",
  cursor: "pointer",
};

const _checked = {
  background: `url(${RadioOnIcon}) no-repeat`,
};

const _disabled = {
  background: `url(${RadioOffDisabledIcon}) no-repeat`,
  cursor: "default",
};

const disabled_checked = {
  background: `url(${RadioOnfDisabledIcon}) no-repeat`,
  cursor: "default",
};

SntRadio.propTypes = {
  value: PropTypes.string,
  selectedValue: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

function SntRadio({
  value,
  label,
  selectedValue,
  disabled = false,
  onChange,
  className,
}) {
  const _style = useMemo(() => {
    let rs = Object.assign({}, _nomarl);
    let _clss = "";
    if (value === selectedValue) {
      rs = Object.assign(rs, _checked);
      _clss += "snt_checked";
    }
    if (disabled) {
      _clss += " snt_checkbox_disable";
      if (value === selectedValue) {
        rs = Object.assign(rs, disabled_checked);
      } else {
        rs = Object.assign(rs, _disabled);
      }
    }

    return {
      style: rs,
      className: _clss,
    };
  }, [value, selectedValue, disabled]);

  function onClick() {
    if (!disabled) {
      onChange && onChange(value);
    }
  }

  return (
    <div className={`d-inline-block ${className ? className : ""}`}>
      <div
        className={`${_style.className} me-3`}
        style={_style.style}
        onClick={onClick}
      ></div>
      <span className="align-middle">{label || ""}</span>
    </div>
  );
}

export default SntRadio;
