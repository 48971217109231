import { apiClient } from "./apiClient";

export default {
  getAll(params) {
    return apiClient.get(`/rest/storedsearchquery/${params}`);
  },

  remove(id) {
    return apiClient.delete(`/rest/storedsearchquery/${id}`);
  },

  create(params) {
    return apiClient.post(`/rest/storedsearchquery`, params, {
      headers: { "content-type": "application/json" },
    });
  },
};
