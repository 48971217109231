import SntIcon from "./SntIcon";

const SntExportIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 15L6.55671e-07 10L2 10L2 15L0 15ZM2 13L14 13L14 10L16 10L16 15L14 15L2 15L2 13Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99994 6.625L4.414 5.29931L6.99995 7.72364L6.99995 1.00001L8.99995 1.00001L8.99994 7.72363L11.5859 5.29932L12.9999 6.62499L9.414 9.98681L9.414 9.98682L8.99994 10.375L7.99994 11.3125L7.99994 11.3125L6.99994 10.375L6.58588 9.98682L2.99994 6.625Z"
      />
    </SntIcon>
  );
};

export default SntExportIcon;
