import SntIcon from "./SntIcon";

const SntCheckNarrowIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 4.27162L6.18349 13L1 7.86832L2.28446 6.5967L6.18349 10.4568L13.7155 3L15 4.27162Z"
      />
    </SntIcon>
  );
};

export default SntCheckNarrowIcon;
