import L from "leaflet";

L.ExtendedTileLayer = L.TileLayer.extend({
  options: {
    xOffset: 0,
    yOffset: 0,
    minZoom: 2,
    maxNativeZoom: 20,
    maxZoom: 22,
  },

  getTileUrl: function (tilePoint) {
    return L.TileLayer.prototype.getTileUrl.call(this, {
      s: tilePoint.s,
      z: tilePoint.z,
      x: tilePoint.x + this.options.xOffset,
      y: tilePoint.y + this.options.yOffset,
    });
  },
});

export const extendedTileLayer = function (url, options) {
  return new L.ExtendedTileLayer(url, options);
};

export const extendedTileLayerxtends = L.extendedTileLayer;
