import SntIcon from "./SntIcon";

const SntDeleteIcon = (props) => {
  return (
    <SntIcon {...props} color={props.color || "rgb(224, 0, 0)"}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0V1H16V3H14V14.1429C14 15.1671 13.2311 16 12.2857 16H3.71429C2.76886 16 2 15.1671 2 14.1429V3H0V1H5V0H11ZM4 3V14H12L11.9992 3H4ZM6 4H7V13H6V4ZM10 4H9V13H10V4Z"
      ></path>
    </SntIcon>
  );
};

export default SntDeleteIcon;
