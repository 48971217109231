import {apiClient} from "./apiClient";

export default {

  get(id) {
    return apiClient.get(`/rest/cellularSim/${id}`);
  },

  search(params) {
    return apiClient.post(`/rest/cellularSim/search`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};
