import Select from "react-select";

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 30,
    border: "1px solid #b8bfca",
    boxShadow: "none",
    "&:hover, &:focus": {
      border: "1px solid #0071a1",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

const SntSelect = (prop) => {
  const { styles = {} } = prop;

  return (
    <Select
      {...prop}
      styles={{ ...customStyles, ...styles }}
      menuPortalTarget={document.body}
      menuPosition="fixed"
    />
  );
};

export default SntSelect;
