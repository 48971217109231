export const supportedGroupTypeConstant = {
  VALUE_BASED: "VALUE_BASED",
  DATE_BUCKET: "DATE_BUCKET",
  NUMERIC_BUCKET: "NUMERIC_BUCKET",
  TAG_BASED: "TAG_BASED",
};

export const bucketTypeConstant = {
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  YEAR: "YEAR",
  WIDTH: "WIDTH",
  AUTO_WIDTH: "AUTO_WIDTH",
  TAGS_FULL: "TAGS_FULL",
  TAGS_FILTERED: "TAGS_FILTERED",
};

export const dateBucketTypes = ["DAY", "WEEK", "MONTH", "YEAR"];

export const supportedOperatorConstant = {
  IN: "IN",
  NOT_IN: "NOT_IN",
  ALL: "ALL",
  NOT_NULL: "NOT_NULL",
  NULL: "NULL",
};
