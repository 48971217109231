import { apiClient } from "./apiClient";
import apiUltils from "./apiUltils";

export default {
  getByOrg(params) {
    return apiClient.get(
      `/rest/partner_tag/${apiUltils.buildParamsListURI("", params)}`
    );
  },
  getByLoginUser() {
    return apiClient.get(`/rest/partner_tag/getByLoginUser`);
  },

  search(data, orgId) {
    return apiClient.post(`/rest/partner_tag/search/${orgId}`, data, {
      headers: { "content-type": "application/json" },
    });
  },

  checkTag(id) {
    return apiClient.get(`/rest/partner_tag/checkTag/${id}`);
  },

  delete(id) {
    return apiClient.delete(`/rest/partner_tag/${id}`);
  },

  getById(id) {
    return apiClient.get(`/rest/partner_tag/${id}`);
  },

  create(orgId, body) {
    return apiClient.post(`/rest/partner_tag/${orgId}`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  update(id, body) {
    return apiClient.put(`/rest/partner_tag/${id}`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};
