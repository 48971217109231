// TODO: should useLoader hook instead of this component
import styled from "styled-components";
import IcomoonIcon from "../../Icons/IcomoonIcon";

const RefreshOverlayStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #555;
  opacity: 0.2;
  filter: alpha(opacity=20);
  border-radius: 4px;
  z-index: 1001;
`;

export const LoaderStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  margin-top: -16px;
  margin-left: -16px;
  z-index: 1010;
`;

const LoaderSpinner = ({ loading = false, top, minHeight }) => {
  return (
    <div style={{ display: loading ? "block" : "none", minHeight: minHeight }}>
      <RefreshOverlayStyle />
      <LoaderStyle style={{ top: top }}>
        <IcomoonIcon
          icon="spinner9"
          iconClassName="icon-spin"
          color="#75b9e6"
          size={24}
        ></IcomoonIcon>
      </LoaderStyle>
    </div>
  );
};
export default LoaderSpinner;
