import { useSelector } from "react-redux";

import NoDataIcon from "./no-data-available.svg";

const NoDataContainer = ({ noDataText, className = "", loading = false }) => {
  const language = useSelector((state) => state.language);

  if (loading) return <></>;

  return (
    <div
      className={`d-flex flex-column align-items-center justify-content-center ${className}`}
    >
      <div>
        <img src={NoDataIcon} />
      </div>
      <div>{noDataText || language.empty_table_key}</div>
    </div>
  );
};

export default NoDataContainer;
