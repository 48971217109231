import SntIcon from "./SntIcon";

const SntArrowLeftIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path d="M10.4733 4.94L7.41333 8L10.4733 11.06L9.52666 12L5.52666 8L9.52666 4L10.4733 4.94Z" />
    </SntIcon>
  );
};

export default SntArrowLeftIcon;
