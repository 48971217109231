import { useEffect } from "react";
import { useField } from "formik";
import { Form } from "react-bootstrap";

const InputTextArea = ({
  name,
  placeholder = "",
  disabled = false,
  rows,
  handleChange,
  required,
}) => {
  const [field, meta] = useField(name);

  useEffect(() => {
    handleChange && handleChange(field.value);
    // eslint-disable-next-line
  }, [field.value]);

  let formClass = "";
  if (meta && meta.touched) {
    if (meta.error) {
      formClass = "is-invalid";
    } else {
      formClass = "is-valid";
    }
  }

  return (
    <>
      <Form.Control
        className={`${formClass}`}
        as="textarea"
        placeholder={placeholder}
        disabled={disabled}
        rows={rows}
        {...field}
        value={field.value || ""}
        required={required}
      />
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </>
  );
};

export default InputTextArea;
