import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { OrgProvider } from "./contexts/OrgContext";

import App from "./containers/App/App";
import { CookiesProvider } from "react-cookie";
// //store
import { Provider } from "react-redux";
import configStore from "./redux/configStore";

import "mapbox.js/dist/mapbox.css";

import "floating-scroll";

import "./sass/css/bootstrap-multiselect.css";
import "sprflat/assets/plugins/misc/highlight/styles/default.css";

import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "vis-timeline/dist/vis-timeline-graph2d.min.css";
import "floating-scroll/dist/jquery.floatingscroll.css";
import "popper.js";

// import "select2";

import "./sass/bootstrap.scss";

import CustomErrorBoundary from "@containers/errors/ErrorFallback";

if (import.meta.hot) {
  import.meta.hot.accept();
}

const store = configStore();
ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <Router>
        <OrgProvider>
          <CustomErrorBoundary>
            <App />
          </CustomErrorBoundary>
        </OrgProvider>
      </Router>
    </Provider>
  </CookiesProvider>,
  document.getElementById("root")
);
