import { combineReducers } from "redux";
import user from "./user";
import all from "./all";
import language from "./language";
import customInterface from "./interface";
import message from "./message";
import layers from "./layers";

const myReducer = combineReducers({
  user,
  language,
  customInterface,
  message,
  all,
  layers,
});

export default myReducer;
