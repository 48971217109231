/* eslint-disable react/display-name */
import { forwardRef } from "react";

import DateRangePicker from "react-bootstrap-daterangepicker";
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";
import "@sass/css/daterangepicker/daterangepicker-bs3.css";

import { Form } from "react-bootstrap";

const DateRangeWrapper = forwardRef(
  (
    { start, end, onChange, maxDate, minDate, disabled, locale, ranges },
    ref
  ) => {
    return (
      <DateRangePicker
        ref={ref}
        initialSettings={{
          startDate: start,
          endDate: end,
          maxDate: maxDate || undefined,
          minDate: minDate || undefined,
          ranges: ranges,
          locale: {
            format: "ll",
            ...locale,
          },
        }}
        onCallback={(start, end, label) => {
          var date = { start: start, end: end };
          onChange && onChange(date);
        }}
      >
        <Form.Control disabled={disabled} type="text" />
      </DateRangePicker>
    );
  }
);

export default DateRangeWrapper;
