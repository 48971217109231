import { useEffect, useState } from "react";
import SntSearch from "../ReactBootstrap/SntSearch";

const SimpleFilterList = ({
  onChange = (data) => {},
  data,
  multiMode,
  searchPlaceholder = "Search here…",
}) => {
  const [filterValue, setFilterValue] = useState({
    search: data && data.filter ? data.filter.search : "",
  });

  useEffect(() => {
    const timeOutId = setTimeout(() => onChange && onChange(filterValue), 500);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  return (
    <>
      {data && data.filter && data.filter.search !== undefined && (
        <SntSearch
          className="mb-2"
          placeholder={searchPlaceholder}
          value={filterValue.search}
          onChange={(value) =>
            setFilterValue({ ...filterValue, search: value })
          }
        />
      )}
    </>
  );
};

export default SimpleFilterList;
