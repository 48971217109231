import { apiClient } from "./apiClient";

export default {
  getLastest() {
    return apiClient.get(`/rest/tou/current`);
  },

  signToU() {
    return apiClient.post(`/rest/tou/signed`);
  },

  getExternalIdPConfiguration(orgId) {
    return apiClient.get(`/rest/org/externalIdPConfiguration/${orgId}`);
  },

  getGTMContainerId() {
    return apiClient.get(`/rest/appsettings/getGTMContainerId`);
  },

  getCsrfName() {
    return apiClient.get(`/rest/appsettings/getCsrfName`);
  },

  getMessageOnLoginScreen() {
    return apiClient.get(`/rest/appsettings/getMessageOnLoginScreen`);
  },

  getMessageOnUserPage() {
    return apiClient.get(`/rest/appsettings/getMessageOnUserPage`);
  },

  validOrgName(orgName) {
    return apiClient.get(`/rest/register/validOrgName/${orgName}`);
  },

  validUsername(userName) {
    return apiClient.get(`/rest/register/validUsername?username=${userName}`);
  },

  getPublicKey() {
    return apiClient.get(`/rest/register/pubkey`);
  },

  register(params) {
    return apiClient.post(`/rest/register`, params, {
      headers: { "content-type": "application/json" },
    });
  },

  cropImage(name, params) {
    return apiClient.post(`/rest/file/crop/${name}`, params, {
      headers: { "content-type": "multipart/form-data" },
    });
  },

  cropImage2(entityType, id, resourceType, name, params) {
    return apiClient.put(
      `/rest/file/${entityType}/${id}/${resourceType}/${name}`,
      params,
      {
        headers: { "content-type": "multipart/form-data" },
      }
    );
  },

  getBuildInfor(userName) {
    return apiClient.get(`/rest/buildInfo`);
  },

  confirmRegistration(code) {
    return apiClient.get(`/rest/register/confirm?code=${code}`);
  },
};
