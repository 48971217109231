import { useEffect, useState } from "react";
import _ from "lodash";
import uiSetting from "../../../apis/uiSetting";

export const useUiSetting = (pageName, fixedSetting) => {
  const defaultMapSettings = {
    selectedLayers: [],
    mapLayer: "",
    geozoneLayer: false,
    networkLayer: false,
    assetLayer: false,
    infraLayer: false,
    layers: [],
  };
  const [setting, setSetting] = useState({});

  useEffect(() => {
    if (!pageName) {
      setSetting({
        useColumnFilter: true,
        groups: fixedSetting ? fixedSetting.groups : [],
        orderData: fixedSetting ? fixedSetting.orderData : null,
        mapSettings: defaultMapSettings,
      });
      return;
    }

    uiSetting
      .findOne(pageName)
      .then(({ data }) => {
        if (data.mapSettings) {
          data.mapSettings = JSON.parse(data.mapSettings);
        }
        setSetting(data);
      })
      .catch((err) => {
        // in useColumnFilter using setting is not empty
        setSetting({
          useColumnFilter: true,
          mapSettings: pageName === "DASHBOARD_MAP" ? {} : defaultMapSettings,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSetting = (filter) => {
    if (!pageName) return;
    let nextSetting = {
      columnVisibleInOrder: filter.columns
        .filter((c) => !c.immovable)
        .map((c) => c.columnKey),
      orderData: [
        filter.sortBy.column.sortKey,
        filter.sortBy.desc ? "desc" : "asc",
      ],
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
      pageName: pageName,
      groups: filter.columns
        .filter((c) => c.aggregationType)
        .map((c) => ({
          aggregationType: c.aggregationType,
          aggregateColumn: c.groupKey,
          bucketType: c.bucketType,
          bucketConfig: c.bucketConfig,
        })),
    };
    if (
      !_.isEqual(
        nextSetting.columnVisibleInOrder,
        setting.columnVisibleInOrder
      ) ||
      !_.isEqual(nextSetting.orderData, setting.orderData) ||
      !_.isEqual(nextSetting.pageSize, setting.pageSize) ||
      !_.isEqual(nextSetting.pageIndex, setting.pageIndex) ||
      !_.isEqual(nextSetting.groups, setting.groups)
    ) {
      setSetting(nextSetting);
      uiSetting.updateSetting(nextSetting).then(
        () => {
          console.log("updated ui settings", nextSetting);
        },
        (err) => {
          console.log("update ui settings failed : ", err);
        }
      );
    }
  };

  const updateDashboardMapSetting = (data) => {
    if (!pageName || !data) return;

    let nextSetting = {
      pageName: pageName,
      mapSettings: { ...data },
    };
    setSetting(nextSetting);
    if (!_.isEqual(data, setting.mapSettings)) {
      uiSetting
        .updateSetting({
          ...nextSetting,
          mapSettings: JSON.stringify(nextSetting.mapSettings),
        })
        .then(
          () => {},
          (err) => {}
        );
    }
  };

  const updateMapSetting = (data) => {
    if (!pageName || !data) return;

    let nextSetting = {
      pageName: pageName,
      mapSettings: { ...setting.mapSettings, ...data },
    };
    setSetting(nextSetting);

    if (
      !_.isEqual(data.selectedLayers, setting.mapSettings.selectedLayers) ||
      !_.isEqual(data.mapLayer, setting.mapSettings.mapLayer) ||
      !_.isEqual(data.geozoneLayer, setting.mapSettings.geozoneLayer) ||
      !_.isEqual(data.networkLayer, setting.mapSettings.networkLayer) ||
      !_.isEqual(data.assetLayer, setting.mapSettings.assetLayer) ||
      !_.isEqual(data.infraLayer, setting.mapSettings.infraLayer) ||
      !_.isEqual(data.layers, setting.mapSettings.layers)
    ) {
      uiSetting
        .updateSetting({
          ...nextSetting,
          mapSettings: JSON.stringify(nextSetting.mapSettings),
        })
        .then(
          () => {},
          (err) => {}
        );
    }
  };

  return {
    setting,
    updateSetting,
    updateMapSetting,
    updateDashboardMapSetting,
  };
};
