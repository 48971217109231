import { useField } from "formik";
import { useEffect } from "react";
import SntCheckBox from "../../SntCheckBox/SntCheckBox";

const InputCheck = ({ name, label, handleChange, disabled, className, title }) => {
  // eslint-disable-next-line
  const [field, meta, helper] = useField(name);

  useEffect(() => {
    handleChange && handleChange(field.value);
    // eslint-disable-next-line
  }, [field.value]);

  function onChange(value) {
    helper.setValue(value);
  }

  return (
    <SntCheckBox
      checked={field.value}
      onChange={onChange}
      label={label}
      className={className}
      disabled={disabled}
      title={title}
    ></SntCheckBox>
  );
};

export default InputCheck;
