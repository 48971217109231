import * as types from "../redux/ActionTypes";
import Highcharts from "highcharts/highstock";

var initialState = {};
var myReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LANGUAGE:
      window.LANG = action.data;
      Highcharts.setOptions({
        lang: {
          downloadCSV: action.data.hc_downloadCSV_key,
          downloadJPEG: action.data.hc_downloadJPEG_key,
          downloadMIDI: action.data.hc_downloadMIDI_key,
          downloadPDF: action.data.hc_downloadPDF_key,
          downloadPNG: action.data.hc_downloadPNG_key,
          downloadSVG: action.data.hc_downloadSVG_key,
          downloadXLS: action.data.hc_downloadXLS_key,
          exitFullscreen: action.data.hc_exitFullscreen_key,
          printChart: action.data.hc_printChart_key,
          resetZoom: action.data.hc_resetZoom_key,
          resetZoomTitle: action.data.hc_resetZoomTitle_key,
          viewFullscreen: action.data.hc_viewFullscreen_key,
        },
      });
      return { ...action.data };
    default:
      return state;
  }
};

export default myReducer;
