import { Col, Row } from "react-bootstrap";
import FormGroupLayout from "./FormGroupLayout";

const FormFullScreenLayout = ({
  label,
  leftRender,
  rightRender,
  helpText,
  isRequired,
}) => {
  return (
    <FormGroupLayout
      xl="2"
      lg="2"
      label={label}
      isRequired={isRequired}
      leftRender={leftRender}
      rightRender={() => (
        <Row>
          <Col
            className={`${!helpText ? "align-self-center" : ""}`}
            xs="12"
            lg="6"
          >
            {rightRender()}
          </Col>
          {helpText && (
            <Col xs="12" lg="6" className="d-flex">
              {helpText}
            </Col>
          )}
        </Row>
      )}
    />
  );
};
export default FormFullScreenLayout;
