import SntIcon from "./SntIcon";

const SntSmallMenu = (props) => {
  return (
    <SntIcon {...props}>
      <circle cx="8" cy="4" r="1" transform="rotate(90 8 4)" />
      <circle cx="8" cy="8" r="1" transform="rotate(90 8 8)" />
      <circle cx="8" cy="12" r="1" transform="rotate(90 8 12)" />
    </SntIcon>
  );
};

export default SntSmallMenu;
