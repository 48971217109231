import { useRef } from "react";

const usePositionFilter = (callback) => {
  const popupRef = useRef();
  const buttonRef = useRef();

  let getPosition = () => {
    if (popupRef.current) {
      // Clone the element
      const element = popupRef.current;
      const clone = element.cloneNode(true);
      clone.style.cssText = `
      position: absolute;
      visibility: hidden;
      display: block;
      left: -9999px;
    `;
      document.body.appendChild(clone);

      // Get the element's dimensions
      const { clientWidth: popupWidth } = clone;

      // Remove the clone
      document.body.removeChild(clone);

      const windowWidth = window.innerWidth;
      const { left: buttonX } = buttonRef.current.getBoundingClientRect();
      if (buttonX + popupWidth <= windowWidth) {
        return {
          left: 0,
        };
      } else if (windowWidth <= 360) {
        return {
          left: 0,
          right: 0,
        };
      } else {
        // show popup to the left of the button
        return {
          left: "unset",
          right: 0,
        };
      }
    }
    return {};
  };

  return { getPosition, popupRef, buttonRef }; // return ref; client can omit `useRef`
};

export default usePositionFilter;
