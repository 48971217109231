import SntIcon from "./SntIcon";

const SntSortAZ = (props) => {
  return (
    <SntIcon {...props}>
      <g clipPath="url(#clip0_4364_688)">
        <rect width="16" height="16" fill="transparent" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 5L11 0L14 5H12L12 16H10L10 5L8 5Z"
        />
        <path d="M6.58174 8L6.13164 6.3406H3.86837L3.41826 8H2L4.19108 1H5.80042L8 8H6.58174ZM5.81741 5.10082C5.40127 3.59718 5.16631 2.74682 5.11253 2.54973C5.06157 2.35263 5.02477 2.19687 5.00212 2.08243C4.9087 2.48933 4.64119 3.49546 4.19958 5.10082H5.81741Z" />
        <path d="M6 15H2V14.3126L4.57721 10.8755H2.06985V10H5.93015V10.684L3.35662 14.1245H6V15Z" />
      </g>
      <defs>
        <clipPath id="clip0_4364_688">
          <rect width="16" height="16" fill="transparent" />
        </clipPath>
      </defs>
    </SntIcon>
  );
};

export default SntSortAZ;
