import iconSet from "../../sass/selection.json";
import IcomoonReact from "icomoon-react";

/** https://www.npmjs.com/package/icomoon-react */
const IcomoonIcon = (props) => {
  const {
    icon,
    size = 16,
    color = "currentcolor",
    title,
    className = "",
    iconClassName,
    onClick,
    style,
  } = {
    ...props,
  };
  return (
    <span onClick={onClick} title={title} className={className} style={style}>
      <IcomoonReact
        iconSet={iconSet}
        color={color}
        size={size}
        icon={icon}
        className={iconClassName}
        style={{ marginTop: -1, position: "unset" }}
      />
    </span>
  );
};

export default IcomoonIcon;
