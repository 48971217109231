// LanguageContext.js
import { createContext, useState, useContext } from "react";

const AppLanguageContext = createContext(null);

export const useAppLanguage = () => useContext(AppLanguageContext);

export const AppLanguageProvider = ({ children }) => {
  const [appLanguage, setAppLanguage] = useState("en");

  return (
    <AppLanguageContext.Provider value={{ appLanguage, setAppLanguage }}>
      {children}
    </AppLanguageContext.Provider>
  );
};
