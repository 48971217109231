import IcomoonIcon from "../../Icons/IcomoonIcon";
import { LoaderStyle } from "./LoaderSpinner";

function Loading() {
  return (
    <LoaderStyle>
      <IcomoonIcon
        icon="spinner9"
        iconClassName="icon-spin"
        color="#75b9e6"
        size={24}
      ></IcomoonIcon>
    </LoaderStyle>
  );
}

export default Loading;
