import { useCallback } from "react";
import SntHideLongText from "../ReactBootstrap/SntHideLongText";

import {
  AssetTag,
  DangerTag,
  DefaultTag,
  GreyTag,
  PrimaryTag,
  SecondaryTag,
  SuccessTag,
  WarningTag,
} from "../ReactBootstrap/TagMore";
import SntCheckBox from "../SntCheckBox/SntCheckBox";
import { FilterOptionHint } from "./FilterStyle";

const CheckboxItem = ({ optionHint, label, onChange, checked, cssClass }) => {
  return (
    <div className="d-flex justify-content-between mb-2">
      <SntCheckBox
        checked={checked}
        onChange={(value) => onChange(value)}
        label={
          <CheckboxFilterTag value={cssClass} title={label}></CheckboxFilterTag>
        }
      />

      <FilterOptionHint>{optionHint}</FilterOptionHint>
    </div>
  );
};
export default CheckboxItem;

const CheckboxFilterTag = ({ value, other, title }) => {
  const render = useCallback(
    (value) => {
      switch (value) {
        case "label label-success":
        case "label label-stop":
          return <SuccessTag tagName={title} />;
        case "label label-orange":
        case "label label-warning":
          return <WarningTag tagName={title} />;
        case "label label-danger":
          return <DangerTag tagName={title} />;
        case "label label-default":
          return <GreyTag tagName={title} />;
        case "label label-primary":
          return <SecondaryTag tagName={title} />;
        case "label label-blue":
        case "label label-dark":
          return <PrimaryTag tagName={title} />;
        case "asset-tag-render":
          return <AssetTag tagName={title} />;
        case undefined:
          return (
            <SntHideLongText
              text={title}
              style={{ maxWidth: "180px" }}
              className="d-inline-block align-middle"
            ></SntHideLongText>
          );
        default:
          return <DefaultTag tagName={title || other} />;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, title]
  );

  return <>{render(value)}</>;
};
