import { useEffect, useState } from "react";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { useLocationChange } from "../../hooks/useLocationChange";
import { Form, Formik } from "formik";
import ChangeModeHeaderIcon from "../../containers/PageHeader/ChangeModeHeaderIcon";
import PageHeader from "../../containers/PageHeader/PageHeader";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { userSelector } from "../../redux/selectors";
import { useOrg } from "../../contexts/OrgContext";
import SntDialog from "../../components/SntDialog/SntDialog";
import SntPanel from "../../components/Panel/SntPanel";
import {
  FormFullScreenLayout,
  InputText,
} from "../../components/ReactBootstrap/FormValidation";
import { Button, Col } from "react-bootstrap";
import systemTagClient from "@/apis/systemTagClient";

const SystemTagManage = () => {
  const history = useHistory();
  const loginInfo = useSelector(userSelector);
  let { permissionsMap, organisation } = loginInfo;
  const { orgId } = useOrg();
  const language = useSelector((state) => state.language);
  const [pageHeaderTitle, setPageHeaderTitle] = useState(
    language.update_tag_key
  );
  const { location, isLocationChanged } = useLocationChange([
    "type",
    "id",
    "org",
  ]);
  const [dataFilter, setDataFilter] = useState({
    name: "",
  });
  let routeData = queryString.parse(location.search);
  let type = routeData.type;
  const [errorDialogMessage, setErrorDialogMessage] = useState(
    language.error_key
  );
  const [isShowErrorDialog, setShowErrorDialog] = useState(false);

  useEffect(() => {
    if (type === "add") {
      setPageHeaderTitle(language.add_tag_key);
    }
    if (type === "edit" || type === "view") {
      if (isLocationChanged()) {
        setPageHeaderTitle(language.update_tag_key);
        systemTagClient
          .getById(routeData.id)
          .then((data) => {
            setDataFilter(data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onSubmit = (values) => {
    let updateTag = { id: values.id, name: values.name };

    if (type === "add") {
      systemTagClient
        .create(updateTag)
        .then((data) => {
          history.push(`/system_tag?org=${orgId}`);
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            setErrorDialogMessage(err.response.data.message);
            setShowErrorDialog(true);
          }
        });
    }
    if (type === "edit") {
      systemTagClient
        .update(routeData.id, updateTag)
        .then((data) => {
          history.push(`/system_tag?org=${orgId}`);
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            setErrorDialogMessage(err.response.data.message);
            setShowErrorDialog(true);
          }
        });
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, language.too_short_key)
      .max(50, language.too_long_key)
      .required(language.valid_required_key),
  });

  return (
    <>
      <SntDialog
        isShow={isShowErrorDialog}
        onSave={() => {
          setShowErrorDialog(false);
        }}
        btnSaveClassName="danger"
        saveTxt={language.ok_key}
        isShowCloseButton={false}
        title={language.error_key}
      >
        {() => <div>{errorDialogMessage}</div>}
      </SntDialog>

      {type !== "add" &&
      organisation.organisationType === "SYSTEM" &&
      permissionsMap["DEVICE_UPDATE"] ? (
        <PageHeader
          title={pageHeaderTitle}
          renderHeaderIcon={ChangeModeHeaderIcon}
        />
      ) : (
        <PageHeader title={pageHeaderTitle} />
      )}

      <Formik
        initialValues={dataFilter}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {() => {
          return (
            <Form
              className="form-horizontal"
              id="form-data"
              noValidate="novalidate"
            >
              <SntPanel title={language.general_info_key}>
                <FormFullScreenLayout
                  label={language.name_key}
                  isRequired={true}
                  rightRender={() => {
                    return (
                      <InputText
                        name="name"
                        disabled={type === "view"}
                      ></InputText>
                    );
                  }}
                ></FormFullScreenLayout>
              </SntPanel>
              <Col xs="12" className="mb-3">
                {type !== "view" &&
                  organisation.organisationType === "SYSTEM" &&
                  permissionsMap["DEVICE_UPDATE"] && (
                    <Button
                      className="tmp me-2"
                      variant="sensolus"
                      title={language.save_key}
                      type="submit"
                    >
                      {language.save_key}
                    </Button>
                  )}
                <Button
                  variant="outline-sensolus"
                  title={language.cancel_key}
                  onClick={() => history.push(`/system_tag?org=${orgId}`)}
                >
                  {language.cancel_key}
                </Button>
              </Col>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SystemTagManage;
