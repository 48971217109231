import * as types from "@redux/ActionTypes";

var initialState = {
  selectedLayers: [],
  mapLayer: "",
  geozoneLayer: false,
  networkLayer: false,
  assetLayer: false,
  infraLayer: false,
  layers: [],
};

var myReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STORE_LAYERS:
      localStorage.setItem(
        "snt_control_layer",
        JSON.stringify({ ...state, ...action.data })
      );
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export default myReducer;
