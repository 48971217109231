import SntIcon from "./SntIcon";

const SntPencilIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.74 2.33968C16.0867 2.66852 16.0867 3.24622 15.74 3.59283L14.1047 5.21928L10.7718 1.88641L12.4072 0.259964C12.7538 -0.0866546 13.3315 -0.0866546 13.6603 0.259964L15.74 2.33968ZM0 16V12.6671L9.82975 2.8285L13.1626 6.16137L3.33287 16H0Z"
      ></path>
    </SntIcon>
  );
};

export default SntPencilIcon;
