import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const SntBrowserTabTitle = ({ title }) => {
  const customInterface = useSelector((state) => state.customInterface);

  const renderEls = () => {
    let el = null;

    if (customInterface) {
      el = (
        <Helmet preserved={true}>
          <title>{title || "Home"}</title>
          {customInterface.loginTitle && (
            <meta
              name="application-name"
              content={customInterface.loginTitle}
            />
          )}
          {customInterface.loginTitle && (
            <meta name="description" content={customInterface.loginTitle} />
          )}
          {customInterface.loginTitle && (
            <meta name="keywords" content={customInterface.loginTitle} />
          )}
        </Helmet>
      );
    } else {
      el = (
        <Helmet preserved={true}>
          <title>{title || "Home"}</title>
          <meta
            name="description"
            content="Track your non-powered assets on the IoT platform. Simple. Reliable, Cost-effective. Over 100k assets connected. Containers, boxes, trailers, ULD."
          />
          <meta name="keywords" content="indoor location tracking, sigfox" />
          <meta name="application-name" content="IoT Platform" />
        </Helmet>
      );
    }

    return el;
  };

  return renderEls();
};

export default SntBrowserTabTitle;
