import { apiClient } from "./apiClient";
import apiUltils from "./apiUltils";

export default {
  getByOrg(params) {
    return apiClient.get(
      `/rest/device_tag/${apiUltils.buildParamsListURI("", params)}`
    );
  },

  getByLoginUser() {
    return apiClient.get(`/rest/device_tag/getByLoginUser`);
  },

  getBasicInfo(params) {
    return apiClient.get(
      `/rest/device_tag/getBasicInfo?search=${params.search}&offset=${params.offset}&limit=${params.limit}&orgId=${params.orgId}`
    );
  },

  search(data, orgId) {
    return apiClient.post(`/rest/device_tag/search/${orgId}`, data, {
      headers: { "content-type": "application/json" },
    });
  },

  checkTag(id) {
    return apiClient.get(`/rest/device_tag/checkTag/${id}`);
  },

  delete(id) {
    return apiClient.delete(`/rest/device_tag/${id}`);
  },

  getById(id) {
    return apiClient.get(`/rest/device_tag/${id}`);
  },

  create(orgId, body) {
    return apiClient.post(`/rest/device_tag/${orgId}`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  update(id, body) {
    return apiClient.put(`/rest/device_tag/${id}`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};
