import {
  GreyTag,
  PrimaryTag,
  SuccessTag,
  WarningTag,
} from "@/components/ReactBootstrap/TagMore";
import { useCallback } from "react";
import { useSelector } from "react-redux";

const RMAStatusLabel = ({ value, other }) => {
  const language = useSelector((state) => state.language);

  const render = useCallback(
    (value) => {
      switch (value) {
        case "NEW":
          return <PrimaryTag tagName={language.new_key}></PrimaryTag>;
        case "ARRIVED":
          return <WarningTag tagName={language.arrived} />;
        case "UNDER_INVESTIGATION":
          return <SuccessTag tagName={language.under_investigation} />;
        case "CLOSED":
          return <GreyTag tagName={language.closed_key} />;
        default:
          return other ? other : "";
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, other]
  );

  return <span>{render(value)}</span>;
};

export default RMAStatusLabel;
