import L from "leaflet";
import $ from "jquery";

L.Control.Overview = L.Control.extend({
  // @section
  // @aka Control.Zoom options
  options: {
    position: "topright",
    title: "Overview",
  },

  onAdd: function (map) {
    var a = $(
      '<div class="leaflet-control-custom-overview leaflet-bar" title="' +
        this.options.title +
        '"><a class="" javascript:void(0)></a></div>'
    );
    a.on("click", function (e) {
      map.fire("custom_overview", {});
    });
    a.on("dblclick", function (e) {
      e.originalEvent.stopPropagation();
    });

    return a[0];
  },

  onRemove: function (map) {},
});
export const ControlOverview = function (opts) {
  return new L.Control.Overview(opts);
};
