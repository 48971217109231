import SntIcon from "./SntIcon";

const SntBatteryFullIcon = (props) => {
  return (
    <SntIcon {...props}>
      <rect x="11" y="5" width="2" height="6"></rect>
      <rect x="2" y="5" width="2" height="6"></rect>
      <rect x="5" y="5" width="2" height="6"></rect>
      <rect x="8" y="5" width="2" height="6"></rect>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4H14V12H1L1 4ZM0 4C0 3.44772 0.447715 3 1 3H14C14.5523 3 15 3.44772 15 4V5H16V11H15V12C15 12.5523 14.5523 13 14 13H1C0.447715 13 0 12.5523 0 12V4Z"
      ></path>
    </SntIcon>
  );
};

export default SntBatteryFullIcon;
