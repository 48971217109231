export const colorDefault = {
  Sensolus_Blue_Darkest: "#212851",
  Sensolus_BG_Blue: "#304269",
  Sensolus_BG_LightBlue: "#EFF3F4",
  Sensolus_Orange: "#FFA858",
  Sensolus_Grey_Light: "#B8BFCA",
  Sensolus_Pink_Light: "#F57DFF",
  Sensolus_Pink_Dark: "#A900D5",
  Sensolus_Blue: "#5776EB",
  Sensolus_Green_Light: "#009D9D",
  Sensolus_Brown_Light: "#950000",
  Sensolus_Purple_Light: "#680282",
  Sensolus_Map_HighLight: "#73D5FF",
  Sensolus_Marker_Blue: "#3FD0FB",
  Sensolus_Grey: "#A0C3D8",
  White: "#FFFFFF",
  Green: "#1E8E45",
};
