import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircle,
  faRemove,
  faCog,
  faFilter,
  faSortAlphaDown,
  faSort,
  faSortDown,
  faSortUp,
  faBell,
  faCheck,
  faArrowUp,
  faArrowDown,
  faRefresh,
  faUserGroup,
  faCalendarDays,
  faPlus,
  faGear,
  faBars,
  faMagnifyingGlass,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCalendarDays as faCalendarDays2,
  faCopy,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faCircle,
  faRemove,
  faCog,
  faFilter,
  faSortAlphaDown,
  faSort,
  faSortDown,
  faSortUp,
  faCalendarDays,
  faBell,
  faCheck,
  faArrowUp,
  faArrowDown,
  faRefresh,
  faUserGroup,
  faCopy,
  faCalendarDays2,
  faPlus,
  faGear,
  faBars,
  faMagnifyingGlass,
  faArrowLeft
);

/** https://fontawesome.com/v5/docs/web/use-with/react */
const AwesomeIcon = (props) => {
  const {
    icon,
    size,
    color,
    title,
    className = "",
    style,
    onClick,
    children,
  } = { ...props };
  return (
    <span
      onClick={onClick}
      title={title}
      className={`fa-icon ${className}`}
      style={style}
      role={onClick && "button"}
    >
      <FontAwesomeIcon
        size={size}
        icon={icon}
        color={color}
        style={{ position: "unset" }}
      >
        {children}
      </FontAwesomeIcon>
    </span>
  );
};

export default AwesomeIcon;
