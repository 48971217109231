import { Card } from "react-bootstrap";

const SntPanel = ({
  title,
  rightTitle,
  className = "",
  bodyClassName = "",
  children,
  onClick,
  style,
}) => {
  return (
    <Card
      className={`w-100 mb-3 ${className}`}
      onClick={onClick}
      style={{ ...style }}
    >
      {(title || rightTitle) && (
        <Card.Header className="bg-sensolus-lightblue">
          <div className="justify-content-between d-flex">
            <div className="align-self-center fw-bold text-sensolus">
              {title}
            </div>
            {rightTitle && (
              <div className="align-self-center">
                <div className="float-end">{rightTitle}</div>
              </div>
            )}
          </div>
        </Card.Header>
      )}
      <Card.Body className={bodyClassName}>{children}</Card.Body>
    </Card>
  );
};

export default SntPanel;
