import L from "leaflet";

// owner
import sigfoxDeviceClient from "@apis/sigfoxDeviceClient";
import {
  PruneCluster,
  PruneClusterForLeaflet,
} from "@/components/Leaflet/Map/PruneCluster/PruneCluster";
import { ClusterIcon } from "@/components/Leaflet/Map/Marker/ClusterIcon";
import { svgIcon, ICON_TYPE } from "@/components/Leaflet/Map/Marker/SvgIcon";
import GlobalUtils from "@/utils/GlobalUtils";
import { forceMapFilters } from "@/components/AdvancedFilters/FilterConstants";

import Cluster_Icon from "@images/map/cluster-icon.svg";
import DeviceIcon from "@images/device/Device-icon.svg";

L.AssetLayer = L.FeatureGroup.extend({
  options: {},
  bounds: L.latLngBounds(),
  pruneCluster: null,

  disableGroup: function (isDisabled) {
    var self = this;
    if (self.pruneCluster) {
      if (isDisabled) {
        self.pruneCluster.Cluster.Size = 0.0000000001;
      } else {
        self.pruneCluster.Cluster.Size = 87;
      }
      self.pruneCluster.ProcessView();
    }
  },
  updateData: function (orgId) {
    var self = this;
    let pruneCluster = new PruneClusterForLeaflet();

    self.pruneCluster = pruneCluster;
    PruneCluster.Cluster.ENABLE_MARKERS_LIST = true;
    pruneCluster.BuildLeafletClusterIcon = function (cluster) {
      let _length = cluster.population;
      return ClusterIcon({
        count: _length,
        iconUrl: Cluster_Icon,
        iconClassName: "cluster-svg",
      });
    };
    pruneCluster.PrepareLeafletMarker = function (leafletMarker, data) {
      data.icon.setMarker(leafletMarker);
      leafletMarker.setIcon(data.icon);
      if (data.tooltip) {
        leafletMarker.unbindTooltip();
        leafletMarker.bindTooltip(data.tooltip, {
          permanent: true,
          direction: "top",
          className: "transparent-tooltip",
          offset: [0, -8],
        });
      }
    };

    let basic = {
      searchQueryType: "BASIC",
      queryObjectType: "SIGFOX_DEVICE",
      query: [],
      requestedTableColumns: [
        {
          columnKey: "lastNetworkLocationRadius",
          order: null,
        },
        {
          columnKey: "currentLat",
          order: null,
        },
        {
          columnKey: "currentLng",
          order: null,
        },
        {
          columnKey: "name",
          order: null,
        },
        {
          columnKey: "decoratorColor",
          order: null,
        },
        {
          columnKey: "location",
          order: null,
        },
        {
          columnKey: "lastBeaconName",
          order: null,
        },
        {
          columnKey: "lastActivity",
          order: null,
        },
      ],
    };

    if (orgId)
      basic.query.push({
        categoryId: "userInfo",
        filterKey: "organizations",
        filterType: "MULTI_SELECT_AUTO_COMPLETE",
        searchType: "STATIC",
        notFilter: false,
        filterValue: {
          includeEmpty: false,
          includeNoEmpty: false,
          selectedValues: [orgId],
        },
      });
    forceMapFilters(basic);

    self.bound = L.latLngBounds();
    self.clearLayers();

    self.addLayer(self.pruneCluster);

    sigfoxDeviceClient.getFilterSearch(basic).then(({ data }) => {
      self.processData(data.data);
    });
  },
  transformData: function (beacon) {
    beacon.coordinate = {
      lat: beacon.currentLat,
      lon: beacon.currentLng,
      lastSeen: beacon.lastSeenAlive,
    };
  },
  isValidCoordinate: function (coordinate) {
    return !!(coordinate && (coordinate.lat || coordinate.lon));
  },
  processData: function (data) {
    let self = this;
    let lastMarkers = [];
    data.forEach((obj) => {
      self.transformData(obj);
      let coordinate = obj.coordinate,
        isValid = self.isValidCoordinate(coordinate);
      if (isValid) {
        lastMarkers.push(obj);
      }
    });
    self.processMarkers(lastMarkers);
  },
  processMarkers: function (lastMarkers) {
    let self = this;
    var markersById = new Map(lastMarkers.map((m) => [m.id, m]));

    // register the remaining markers
    markersById.forEach((device) => {
      // new marker, add to cluster
      let _coordinate = device.coordinate;
      let markerInfo = self.getMarkerInfo(device);

      let icon = svgIcon({
        iconUrl: device.trackerIcon
          ? GlobalUtils.getFile(
              "device",
              device.id,
              "icon",
              device.trackerIcon,
              "small"
            )
          : DeviceIcon,
        id: device.id,
        trackerName: device.name,
        state: markerInfo,
        network: device?.lastLocationSource === "network",
        decoratorColor: device?.decoratorColor,
        type: ICON_TYPE.assetType,
      });

      let marker = new PruneCluster.Marker(
        _coordinate.lat || 0,
        _coordinate.lon || 0,
        {
          icon: icon,
          tooltip: device.name,
        }
      );
      marker.data.deviceInfo = device;
      marker.getLatLng = function () {
        return new L.latLng(this.position.lat, this.position.lon);
      };

      // calculatedBounds.current.extend(L.latLng(_coordinate.lat, _coordinate.lon));
      self.pruneCluster.RegisterMarker(marker);
    });

    self.pruneCluster.ProcessView();
  },
  getMarkerInfo: function (device) {
    let markerInfo = "offline";
    if (device.trackerStatus === "ONLINE") {
      let lastActivity = device.lastActivityState;
      if (lastActivity && lastActivity === "STOP") {
        markerInfo = "stop";
      } else if (
        lastActivity &&
        (lastActivity === "START" || lastActivity === "ON_THE_MOVE")
      ) {
        markerInfo = "moving";
      } else {
        markerInfo = "periodic";
      }
    }
    return markerInfo;
  },
  getLayer: function () {
    return this;
  },
  getBounds: function () {
    return this.bounds;
  },
});

L.assetLayer = function (opts) {
  return new L.AssetLayer(opts);
};
export const AssetLayer = L.assetLayer;
