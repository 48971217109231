import { useMemo } from "react";
import { Form } from "react-bootstrap";

const FormGroupLabel = ({
  children,
  isRequired,
  xs = 12,
  sm = 12,
  md = 3,
  xl = 3,
  lg = 3,
}) => {
  const textAlignClass = useMemo(() => {
    const maxColumn = 12;
    let rs = "";
    rs += maxColumn - xs > 0 ? "text-end " : "text-start ";
    rs += maxColumn - sm > 0 ? "text-sm-end " : "text-sm-start ";
    rs += maxColumn - md > 0 ? "text-md-end " : "text-md-start ";
    rs += maxColumn - xl > 0 ? "text-xl-end " : "text-xl-start ";
    rs += maxColumn - lg > 0 ? "text-lg-end " : "text-lg-start ";
    return rs;
  }, [xs, sm, md, xl, lg]);

  return (
    <>
      <Form.Label
        column
        xs={xs}
        sm={sm}
        md={md}
        xl={xl}
        lg={lg}
        className={`fw-bold ${textAlignClass} ${!children && "m-0 p-0"}`}
      >
        {children}
        {isRequired && <span className="s12 ms-1">*</span>}
      </Form.Label>
    </>
  );
};

export default FormGroupLabel;
