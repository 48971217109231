/* eslint-disable array-callback-return */
import { useMemo } from "react";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import SntDialog from "../../components/SntDialog/SntDialog";

const CanNotDeleteTag = ({ linkedList, isShow, onClose }) => {
  const language = useSelector((state) => state.language);

  const renderInfo = useMemo(() => {
    let stringHtml = "<div>" + language.warning_can_not_delete_tag;
    if (linkedList) {
      Object.values(linkedList).map((item, idx) => {
        stringHtml +=
          "<b> " +
          item.length +
          "  " +
          language[Object.keys(linkedList)[idx] + "_linked_to_tag"] +
          "</b>";
        stringHtml += "<ul>";
        // eslint-disable-next-line array-callback-return
        item.map(({ title }) => {
          stringHtml += "<li>" + (title || language.untitled_key) + "</li>";
        });
        stringHtml += "</ul>";
      });
    }

    stringHtml += "<div/>";
    return ReactHtmlParser(stringHtml);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedList]);
  return (
    <SntDialog
      isShow={isShow}
      onClose={onClose}
      closeTxt={language.ok_key}
      title={language.delete_tag}
      isShowSaveButton={false}
    >
      {() => <div>{renderInfo}</div>}
    </SntDialog>
  );
};
export default CanNotDeleteTag;
