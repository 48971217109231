import { apiClient } from "./apiClient";

const feature = "geolayer";

export default {
  getByOrg(params) {
    return apiClient.get(`/rest/${feature}/`, { params: params });
  },

  getByOrgCheckVisibility(params) {
    return apiClient.get(`/rest/${feature}/visibility/`, { params: params });
  },

  getGeolayerById(id) {
    return apiClient.get(`/rest/${feature}/${id}`);
  },

  createGeolayer(params) {
    return apiClient.post(`/rest/${feature}/`, params, {
      headers: { "content-type": "application/json" },
    });
  },

  updateGeolayer(id, params) {
    return apiClient.put(`/rest/${feature}/${id}`, params, {
      headers: { "content-type": "application/json" },
    });
  },

  delete(id) {
    return apiClient.delete(`/rest/${feature}/${id}`);
  },
};
