import { useEffect, useState } from "react";
import SntSearch from "../../ReactBootstrap/SntSearch";
import { AdvanceFilter } from "../FilterStyle";

const MultiStringFilter = ({
  descriptor = {
    key: "nameSerialFilter",
    label: "Search on name or serial",
    description: "Search on name or serial",
    filterType: "MULTI_STRING",
    filterTypeSettings: {
      searchFieldPlaceholder: "Search name, serial",
    },
  },
  data = {
    filterValue: {
      searchText: "",
    },
  },
  onChange = (data) => {
    console.log(data);
  },
  disabled = false,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchFieldPlaceholder] = useState(
    (descriptor.filterTypeSettings &&
      descriptor.filterTypeSettings.searchFieldPlaceholder) ||
      ""
  );
  const [description] = useState((descriptor && descriptor.description) || "");

  useEffect(() => {
    setSearchText(
      (data && data.filterValue && data.filterValue.searchText) || ""
    );
  }, [data, data.filterValue]);

  const getData = (text = searchText) => {
    if (text === "" || text === undefined) {
      return {
        idx: descriptor._idx,
        data: null,
      };
    }
    let newData = JSON.parse(JSON.stringify(data));
    newData.filterValue = {
      searchText: text || null,
    };

    return newData;
  };

  const onChangeInput = (text) => {
    setSearchText(text);
    onChange && onChange(getData(text));
  };

  return (
    <AdvanceFilter>
      <SntSearch
        value={searchText}
        placeholder={searchFieldPlaceholder}
        title={description}
        disabled={disabled}
        onChange={onChangeInput}
      />
    </AdvanceFilter>
  );
};

export default MultiStringFilter;
