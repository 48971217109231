import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import SntTabRouteContainer from "../../components/SntTab/SntTabRouteContainer";
import { useRoutes } from "../../components/SntTab/useRoutes";
import PageHeader from "../../containers/PageHeader/PageHeader";
import LocationMismatch from "./LocationMismatch";
import PrivateCellularTower from "./PrivateCellularTower";

const WifiCellularMismatch = ({ reload }) => {
  const language = useSelector((state) => state.language);
  const tabRef = useRef(null);

  const route = {
    route: "wifi_cellular_mismatch?",
    tabActive: "locationMismatch",
  };
  const { routeParams } = useRoutes(route);

  const allTabs = useMemo(() => {
    return [
      {
        id: "locationMismatch",
        label: language.UserPermission_MENU_ADMIN_CELL_TOWER_TROUBLESHOOTING_description_key,
        render: true,
        component: () => <LocationMismatch />,
      },
      {
        id: "privateCellular",
        label: language.private_cellular_towers,
        render: true,
        component: () => <PrivateCellularTower />,
      },
    ];
    // eslint-disable-next-line
  }, [reload]);

  return (
    <>
      {
        <div ref={tabRef}>
          <PageHeader
            title={language.cell_tower_troubleshooting}
            tabTitle={language.cell_tower_troubleshooting}
          />
          <SntTabRouteContainer
            allTabs={allTabs}
            tabGroupInfo={{
              route: route.route,
              tabActive: routeParams.tabActive,
            }}
            browserTabTitle={language.cell_tower_troubleshooting}
          />
        </div>
      }
    </>
  );
};

export default WifiCellularMismatch;
