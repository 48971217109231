import SntIcon from "./SntIcon";

const SntArrowDownIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path d="M4.94 5.23669L8 8.29669L11.06 5.23669L12 6.18336L8 10.1834L4 6.18336L4.94 5.23669Z" />
    </SntIcon>
  );
};

export default SntArrowDownIcon;
