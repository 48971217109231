const ActionToolEnum = {
  reset_filter: "reset_filter",
  save_filter: "save_filter",
  load_filter: "load_filter",
  bulk_operation: "bulk_operation",
  export_report: "export_report",
  import_export_geozones: "import_export_geozones",
  legal_document_send: "legal_document_send",
  import_tracker_settings: "import_tracker_settings",
};

export default ActionToolEnum;
