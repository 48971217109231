import L from "leaflet";
import * as d3 from "d3";
import $ from "jquery";
import { svgIconExtends } from "./SvgIcon";
import configurationUtils from "../../../../utils/IconConfiguration";

L.ClusterIcon = svgIconExtends.extend({
  options: {
    className: "cluster-svg-icon",
    id: "",
    width: 30,
    count: 123,
  },

  createIcon: function () {
    var div = document.createElement("div"),
      options = this.options;

    div.id = "svg-icon-" + options.id;
    div.name = options.state;

    if (options.svgString) {
      this._createSvg(options.svgString, div);
    } else if (options.iconUrl) {
      var img = this._createImg(options.iconUrl);
      div.append(img);
    }

    if (options.bgPos) {
      var bgPos = L.point(options.bgPos);
      div.style.backgroundPosition = -bgPos.x + "px " + -bgPos.y + "px";
    }
    this._setIconStyles(div, "icon");
    return div;
  },

  setStyle: function (el, style) {
    var options = this.options;
    var iconInfo =
      options.className === "cluster-svg-infras"
        ? configurationUtils.getIcon("cluster_infras")
        : options.className === "cluster-svg-geozone"
        ? configurationUtils.getIcon("cluster_geozone")
        : configurationUtils.getIcon("cluster");
    // eslint-disable-next-line default-case
    switch (style) {
      case "normal":
        $(el).find('[id^="cluster-bg"]').attr("fill", iconInfo.bgColor);
        $(el).find('[id^="cluster-fg"]').attr("stroke", iconInfo.iconColor);
        $(el).find('[id^="cluster-text"]').attr("fill", iconInfo.iconColor);
        break;
      case "hover":
        $(el).find('[id^="cluster-bg"]').attr("fill", iconInfo.bgHoverColor);
        $(el)
          .find('[id^="cluster-fg"]')
          .attr("stroke", iconInfo.iconHoverColor);
        $(el)
          .find('[id^="cluster-text"]')
          .attr("fill", iconInfo.iconHoverColor);
        break;
    }
  },
  onEnter: function () {},

  onLeave: function () {},

  onClick: function () {},

  beforeInject: function (img, svg) {
    var self = this;
    var svg3 = d3.select(svg);

    svg3.attr("width", self.options.width);
    svg3.attr("height", self.options.width);
    svg3.attr(
      "viewBox",
      "0 0 " + self.options.width + " " + self.options.width
    );

    svg3.select('[id^="cluster-bg"]').attr("cx", self.options.width / 2);
    svg3.select('[id^="cluster-bg"]').attr("cy", self.options.width / 2);
    svg3.select('[id^="cluster-bg"]').attr("r", self.options.width / 2 - 1);

    svg3.select('[id^="cluster-fg"]').attr("cx", self.options.width / 2);
    svg3.select('[id^="cluster-fg"]').attr("cy", self.options.width / 2);
    svg3.select('[id^="cluster-fg"]').attr("r", self.options.width / 2 - 3);

    svg3.select('[id^="cluster-text"]').text(self.options.count + "");
  },
});
L.clusterIcon = function (opts) {
  var width = 24;
  if (opts.count >= 100 && opts.count < 1000) {
    width = 36;
  } else if (opts.count >= 1000) {
    width = 44;
  }
  return new L.ClusterIcon(
    Object.assign(opts, {
      width: width,
      id: opts.id,
      iconAnchor: [width / 2, width / 2],
    })
  );
};
export const ClusterIcon = L.clusterIcon;
