import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useOuterClick from "../useOutsideElement";
import GeneralFooterFilter from "../GeneralFooterFilter";
import ExcludeLabel from "../ExcludeLabel";
import SntOrganizationSelectorCustom from "../../SntOrganizationSelector/SntOrganizationSelectorCustom";
import { useSelector } from "react-redux";
import searchUIClient from "../../../apis/searchUIClient";
import { AdvanceFilter, FilterBody, FilterHeaderStyle } from "../FilterStyle";
import { Button, Container } from "react-bootstrap";
import CheckboxItem from "../CheckboxItem";
import SntCloseSmallIcon from "../../Icons/SntCloseSmallIcon";
import SntArrowDownIcon from "../../Icons/SntArrowDownIcon";
import { useOrg } from "../../../contexts/OrgContext";
import usePositionFilter from "../usePositionFilter";

const MultiSelectAutocompleteFilter = ({
  descriptor = {
    key: "organizations",
    label: "Organizations",
    description: "The organization that owns the tracker",
    categoryId: "assetInfo",
    order: 30,
    filterType: "MULTI_SELECT_AUTO_COMPLETE",
    filterTypeSettings: {
      optionsURL: "/search/gui/filter/suggestion/organizations",
      notDefinedOption: {
        optionLabel: "Not linked to any organization",
        headerLabel: "No organizations",
      },
    },
  },
  data = {
    filterValue: {
      includeEmpty: false,
      includeNoEmpty: true,
      notFilter: false,
      selectedValues: [1, 2],
    },
    notFilter: false,
  },
  onChange = (data) => {
    console.log("emit on onchange: ", data);
  },
  disabled = false,
  deviceCategorySuggestion,
}) => {
  const [description] = useState(descriptor.description || "");
  const [label] = useState(descriptor.label || descriptor.description || "");
  const [title, setTitle] = useState("");
  const [notDefinedOption] = useState(
    (descriptor.filterTypeSettings &&
      descriptor.filterTypeSettings.notDefinedOption) ||
      ""
  );
  const language = useSelector((state) => state.language);
  const { orgId } = useOrg();

  // input data
  const [dataState, setDataState] = useState({
    selectedValues: [],
    includeEmpty: false,
    includeNoEmpty: false,
    notFilter: false,
  });

  // orginal data get from server
  const [mapData, setmapData] = useState({});
  const [keysMap, setKeysMap] = useState({});

  // avaiable seleted items
  const [selectedList, setSelectedList] = useState([]);

  // avaiable not seleted items
  const [unselectedList, setUnselectedList] = useState([]);

  const [isShow, setShow] = useState(false);

  const [availableUnselectedList, setAvailableUnselectedList] = useState([]);
  const [categoryIndex, setCategoryIndex] = useState();

  let popupStyleRef = useRef({});
  const { popupRef, buttonRef, getPosition } = usePositionFilter();

  const getUnselectedList = (data) => {
    // get from data
    if (data && data instanceof Object) {
      return Object.keys(mapData)
        .filter((id) => data.selectedValues.indexOf(mapData[id].key) === -1)
        .map((id) => {
          return {
            id: mapData[id].key,
            key: id,
            checked: false,
            label: mapData[id].label,
          };
        });
    }
    // get from original arr
    return Object.keys(mapData)
      .filter((id) => dataState.selectedValues.indexOf(mapData[id].key) === -1)
      .map((id) => {
        return {
          id: mapData[id].key,
          key: id,
          checked: false,
          label: mapData[id].label,
        };
      });
  };

  const getSelectedList = (data) => {
    // get from data
    if (data && data instanceof Object) {
      return data.selectedValues.map((id) => {
        return {
          id: id,
          key: keysMap[id],
          checked: true,
          label: mapData[keysMap[id]].label,
        };
      });
    }
    // get from original arr
    return dataState.selectedValues.map((id) => {
      return {
        id: id,
        key: keysMap[id],
        checked: true,
        label: mapData[keysMap[id]],
      };
    });
  };

  useEffect(() => {
    if (unselectedList.length > 0) {
      setCategoryIndex(0);
      setAvailableUnselectedList([...unselectedList]);
    }
  }, [unselectedList]);

  useEffect(() => {
    if (categoryIndex !== 0)
      setAvailableUnselectedList([
        ...availableUnselectedList,
        ...unselectedList,
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryIndex]);

  const onAppliedFilter = () => {
    setShow(false);
    if (isChanged()) onChange && onChange(getData());
  };

  const onClearFilter = () => {
    setShow(false);
    onChange &&
      onChange({
        idx: descriptor._idx,
        data: null,
      });
    clearSelectedItems();
  };

  const clearSelectedItems = () => {};

  const onClickButton = (e) => {
    if (isShow) {
      setShow(false);
      if (isChanged()) onChange && onChange(getData());
    } else {
      popupStyleRef.current = getPosition();
      setShow(true);
    }
  };

  const onSelectOrganization = (e) => {
    if (Object.keys(keysMap).length > 0 && Object.keys(mapData).length > 0) {
      setSelectedList([
        ...selectedList,
        {
          checked: true,
          key: keysMap[e.value],
          id: e.value,
          label: mapData[keysMap[e.value]].label,
        },
      ]);
      setUnselectedList(
        unselectedList.filter((o) => o.id !== mapData[keysMap[e.value]].key)
      );
    }
  };

  const getData = useCallback(() => {
    let newData = JSON.parse(JSON.stringify(data));
    newData.notFilter = dataState.notFilter;
    newData.filterValue = {
      includeEmpty: dataState.includeEmpty,
      includeNoEmpty: dataState.includeNoEmpty,
      selectedValues: [
        ...selectedList.filter((s) => s.checked),
        ...unselectedList.filter((s) => s.checked),
      ].map((s) => (mapData[s.key] ? mapData[s.key].key : null)),
    };

    return newData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataState, selectedList, unselectedList]);

  const isChanged = useCallback(() => {
    let oldDataFilter = data.filterValue || {};
    let newData = getData();
    let newDataFilter = newData.filterValue || {};

    if (
      (oldDataFilter.includeEmpty === newDataFilter.includeEmpty ||
        (oldDataFilter.includeEmpty === undefined &&
          newDataFilter.includeEmpty === false) ||
        (oldDataFilter.includeEmpty === false &&
          newDataFilter.includeEmpty === undefined)) &&
      (oldDataFilter.includeNoEmpty === newDataFilter.includeNoEmpty ||
        (oldDataFilter.includeNoEmpty === undefined &&
          newDataFilter.includeNoEmpty === false) ||
        (oldDataFilter.includeNoEmpty === false &&
          newDataFilter.includeNoEmpty === undefined)) &&
      JSON.stringify([...(oldDataFilter.selectedValues || [])].sort()) ===
        JSON.stringify([...(newDataFilter.selectedValues || [])].sort()) &&
      (data.notFilter === newData.notFilter ||
        (data.notFilter === undefined && newData.notFilter === false) ||
        (data.notFilter === false && newData.notFilter === undefined))
    )
      return false;
    return true;
  }, [data.filterValue, data.notFilter, getData]);

  // on click outside the panel
  let refWrapper = useOuterClick((e) => {
    if (isShow && e.target.id.indexOf("react-select") === -1) {
      setShow(false);
      if (isChanged()) onChange && onChange(getData());
    }
  });

  // load data from server
  useEffect(() => {
    if (isShow === false && descriptor.filterTypeSettings.optionsURL) {
      let param =
        descriptor.searchType === "DYNAMIC" ? "/" + descriptor.key : "";
      let orgParam = orgId && !disabled ? `?org=${orgId}` : "";

      searchUIClient
        .getSuggestion({
          url: descriptor.filterTypeSettings.optionsURL + param + orgParam,
        })
        .then(({ data }) => {
          let _options = data.options,
            _mapData = {},
            _keysMap = {};

          for (var j = 0, _size = _options.length; j < _size; j++) {
            let data = _options[j],
              _key = data.key,
              _idx = "suggestion_" + j;

            _mapData[_idx] = data;
            _keysMap[_key] = _idx;
          }
          setmapData(_mapData);
          setKeysMap(_keysMap);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, descriptor.filterTypeSettings.optionsURL]);

  useEffect(() => {
    // there no data
    if (Object.keys(keysMap).length === 0 || Object.keys(mapData).length === 0)
      return;

    // set for the button
    let { filterValue, notFilter } = data;
    let _title = language.all_key,
      _dataState = {
        selectedValues: [],
        includeEmpty: false,
        includeNoEmpty: false,
        notFilter: notFilter,
      };

    if (filterValue) {
      _dataState = { ...filterValue, notFilter: notFilter };
      // Filter valid id (selected id can be removed)
      let selectedValues = filterValue.selectedValues.filter((id) => {
        return mapData[keysMap[id]];
      });
      filterValue = { ...filterValue, selectedValues: selectedValues };
      _dataState = { ..._dataState, selectedValues: selectedValues };
      if (
        filterValue.selectedValues &&
        Object.keys(keysMap).length !== 0 &&
        Object.keys(mapData).length !== 0
      ) {
        if (filterValue.selectedValues.length > 1) {
          _title = filterValue.selectedValues.length;
        } else if (filterValue.selectedValues.length === 1) {
          _title = mapData[keysMap[filterValue.selectedValues[0]]]?.label;
        }
      }

      if (filterValue.includeEmpty) {
        _title = descriptor.filterTypeSettings.notDefinedOption
          ? descriptor.filterTypeSettings.notDefinedOption.headerLabel
          : "";
      }
    }
    setDataState(_dataState);
    setTitle(_title);

    // set for lists in the panel
    if (_dataState && _dataState.selectedValues) {
      let _unselectedList = getUnselectedList(_dataState);
      let _selectedList = getSelectedList(_dataState);

      setUnselectedList(_unselectedList);
      setSelectedList(_selectedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data.filterValue, keysMap, mapData, notDefinedOption.headerLabel]);

  const categorySelected = useMemo(() => {
    let _defaultClass = "label label-stop";

    let _selectedList = Array.from(selectedList);
    let selectedOptions = _selectedList.map(({ key, checked }, index) => {
      return (
        <CheckboxItem
          key={key}
          checked={checked}
          onChange={(value) => {
            _selectedList[index].checked = value;
            setSelectedList(_selectedList);
          }}
          cssClass={mapData[key].cssClass || _defaultClass}
          label={mapData[key].label}
        />
      );
    });
    return <div className="form-group">{selectedOptions}</div>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList]);

  const excludeLabel = useMemo(() => {
    return data.notFilter && <ExcludeLabel />;
  }, [data.notFilter]);

  return (
    <AdvanceFilter ref={refWrapper}>
      <Button
        ref={buttonRef}
        variant="sensolus-greylight"
        title={description}
        disabled={disabled}
        onClick={onClickButton}
      >
        {(dataState.selectedValues.length > 0 ||
          dataState.includeEmpty ||
          dataState.includeNoEmpty) && (
          <SntCloseSmallIcon
            className="me-1"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClearFilter();
            }}
          />
        )}
        <span>{label}</span>: <span>{excludeLabel}</span>
        <FilterHeaderStyle>{title}</FilterHeaderStyle>
        <SntArrowDownIcon />
      </Button>

      <FilterBody
        ref={popupRef}
        style={{ display: isShow ? "block" : "none", ...popupStyleRef.current }}
      >
        <div style={{ overflowY: "auto", height: "300px" }}>
          <Container className="mt-3 mb-3">
            <SntOrganizationSelectorCustom
              onChange={(e) => {
                onSelectOrganization(e);
              }}
              items={availableUnselectedList.map((o) => {
                return {
                  label: o.label,
                  value: o.id,
                };
              })}
              placeholder={language.selecting_key}
            />
          </Container>
          <div style={{ overflowY: "auto", height: "300px" }}>
            <Container className="mb-3">{categorySelected}</Container>
            <div className="mb-3 border-top border-sensolus-grey"></div>
            {notDefinedOption && (
              <Container>
                <CheckboxItem
                  checked={dataState.includeEmpty}
                  onChange={(value) =>
                    setDataState({ ...dataState, includeEmpty: value })
                  }
                  label={notDefinedOption.optionLabel}
                />
              </Container>
            )}
          </div>
        </div>
        <GeneralFooterFilter
          onClearFilter={(e) => onClearFilter(e)}
          onAppliedFilter={(e) => onAppliedFilter(e)}
          isNotFilter={dataState.notFilter}
          onCheckNotFilter={(e) => {
            setDataState({
              ...dataState,
              notFilter: e,
            });
          }}
          disableCheckBox={
            (selectedList.length === 0 ||
              selectedList.filter((item) => item.checked === true).length ===
                0) &&
            !dataState.includeEmpty &&
            !dataState.includeNoEmpty
          }
        />
      </FilterBody>
    </AdvanceFilter>
  );
};

export default MultiSelectAutocompleteFilter;
