import { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import SntCheckBox from "../SntCheckBox/SntCheckBox";

const GeneralFooterFilter = ({
  onClearFilter,
  onAppliedFilter,
  isNotFilter = false,
  onCheckNotFilter,
  disableCheckBox = true,
  isShowExclude = true,
}) => {
  const language = useSelector((state) => state.language);

  useEffect(() => {
    if (disableCheckBox) {
      onCheckNotFilter && onCheckNotFilter(false);
    }
    // eslint-disable-next-line
  }, [disableCheckBox]);
  return (
    <>
      <div className="mb-3 border-top border-sensolus-grey"></div>
      <Container>
        <div className="d-flex flex-row flex-wrap">
          <div className="mb-3 flex-grow-1 align-self-center">
            {isShowExclude && (
              <>
                <SntCheckBox
                  checked={disableCheckBox ? false : isNotFilter}
                  onChange={onCheckNotFilter}
                  disabled={disableCheckBox}
                  label={
                    <label className="fw-bold">{language.exclude_key}</label>
                  }
                />
              </>
            )}
          </div>

          <div className="mb-3 flex-grow-1 align-self-center">
            <div className="float-end">
              <Button
                variant="outline-sensolus"
                onClick={onClearFilter}
                style={{ margin: "calc(0.5rem * 0.5)" }}
              >
                {language.clear_key}
              </Button>
              <Button variant="sensolus" onClick={onAppliedFilter}>
                {language.apply_key}
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default GeneralFooterFilter;
