const ErrorLabel = ({ error = "", className = "ps-3" }) => {
  return error ? (
    <div
      className={`${className} text-danger fw-bold`}
      style={{ fontSize: "13px" }}
    >
      {error}
    </div>
  ) : (
    <></>
  );
};

export default ErrorLabel;
