const SntIcon = (props) => {
  const {
    size = 16,
    color = "currentColor",
    title,
    className = "",
    onClick,
    style,
    children,
    viewBox = "0 0 16 16",
  } = {
    ...props,
  };
  return (
    <svg
      width={size}
      height={size}
      onClick={onClick}
      className={`snt-owner-icon ${className}`}
      style={style}
      role={onClick && "button"}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title>{title}</title>}
      {children}
    </svg>
  );
};

export default SntIcon;
