import SntBrowserTabTitle from "../../components/SntBrowserTabTitle/SntBrowserTabTitle";
import BackHistory from "../BackHistory/BackHistory";
import Cookies from "js-cookie";
import SntHideLongText from "@/components/ReactBootstrap/SntHideLongText";

const PageHeader = ({
  title,
  tabTitle,
  renderRightHeader,
  renderHeaderIcon,
  isShowBackHistory = true,
}) => {
  const sensolusFullScreen = Cookies.get("sensolus-full-screen");

  if (sensolusFullScreen) return <></>;
  return (
    <>
      <SntBrowserTabTitle title={tabTitle || title} />

      <div className="d-flex justify-content-between heading">
        <div className="d-flex align-items-center w-50">
          {isShowBackHistory && <BackHistory />}
          <h1 className="page-header">
            <SntHideLongText>{title}</SntHideLongText>
          </h1>
          {renderHeaderIcon && renderHeaderIcon()}
        </div>
        <div className="d-flex justify-content-end align-items-center w-50">
          {renderRightHeader && renderRightHeader()}
        </div>
      </div>
    </>
  );
};

export default PageHeader;
