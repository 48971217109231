import { useSelector } from "react-redux";
import { SntDataCard } from "./SntDataCard/SntDataCard";
import { SntDataTable } from "./SntDataTable/SntDataTable";

export const SntTableViewCard = (props) => {
  const { isMobile } = useSelector((state) => state.all);

  return isMobile && !props.isDynamicDashBoard ? (
    <SntDataCard {...props} />
  ) : (
    <SntDataTable {...props} />
  );
};
