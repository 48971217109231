import $ from "jquery";

export default {
  buildParamsListURI(uri, params) {
    var paramStr = uri;
    $.each(params, function (key, values) {
      if (Array.isArray(values)) {
        $.each(values, function (idx, value) {
          paramStr += paramStr.indexOf("?") < 0 ? "?" : "&";
          paramStr += key + "=" + value;
        });
      } else if (values != null) {
        paramStr += paramStr.indexOf("?") < 0 ? "?" : "&";
        paramStr += key + "=" + values;
      }
    });

    return paramStr;
  },

  buildURIWithArray(uri, array, paramName) {
    var paramStr = uri;
    $.each(array, function (index, values) {
      paramStr += paramStr.indexOf("?") < 0 ? "?" : "&";
      paramStr += paramName + "=" + values[index];
    });

    return paramStr;
  },
};
