import { useEffect, useMemo, useState } from "react";
import SntDialog from "../../../SntDialog/SntDialog";
import storedSearchQuery from "../../../../apis/storedSearchQuery";
import { useSelector } from "react-redux";
import moment from "./../../../../constants/SensolusMoment";
import { Form, InputGroup } from "react-bootstrap";
import SntLink from "../../../ReactBootstrap/SntLink";
import SntDeleteIcon from "../../../Icons/SntDeleteIcon";
import SntSearchIcon from "../../../Icons/SntSearchIcon";
import NoDataContainer from "@/cleanup/containers/NoData/NoDataContainer";

const LoadFilter = ({
  onLoadSearchQuery,
  onClose,
  isView = false,
  queryObjectType,
  isShow,
  margin = 0,
}) => {
  const language = useSelector((state) => state.language);
  const [itemSelected, setItemSelected] = useState(null);
  const [isShowDeleteDialog, setShowDeleteDialog] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [mapFilters, setMapFilters] = useState([]);

  useEffect(() => {
    if (isShow) {
      storedSearchQuery.getAll(queryObjectType).then(
        ({ data }) => {
          setMapFilters(data);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [isShow, queryObjectType]);

  const savedFilterTable = useMemo(() => {
    return mapFilters
      .filter(
        (filter) =>
          filter.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      )
      .map((filter, idx) => {
        const { name, createdAt, createdBy } = filter;
        return (
          <tr key={idx}>
            <td
              style={{ verticalAlign: "top", width: "40%" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onLoadSearchQuery(filter);
              }}
              role="button"
            >
              <SntLink>{name}</SntLink>
            </td>
            <td style={{ verticalAlign: "top", width: "30%" }}>
              {moment(createdAt).format("lll")}
            </td>
            <td style={{ verticalAlign: "top", width: "30%" }}>
              {createdBy.fullName}
            </td>
            {!isView && (
              <td>
                <SntDeleteIcon onClick={() => onDeleteFilter(filter)} />
              </td>
            )}
          </tr>
        );
      });
  }, [isView, mapFilters, onLoadSearchQuery, searchText]);

  const onDeleteFilter = (filter) => {
    setItemSelected(filter);
    setShowDeleteDialog(true);
  };

  const onDelete = () => {
    if (itemSelected) {
      storedSearchQuery
        .remove(itemSelected.id)
        .then(() => {
          setMapFilters(
            mapFilters.filter((elem) => elem.id !== itemSelected.id)
          );
          setShowDeleteDialog(false);
          setItemSelected(null);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onClosePopup = () => {
    setShowDeleteDialog(false);
  };

  const loadFilterTable = () => {
    return (
      <div>
        <InputGroup className="mw-100" style={{ width: "275px" }}>
          <Form.Control
            placeholder={language.loading_filter_search}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <InputGroup.Text>
            <SntSearchIcon />
          </InputGroup.Text>
        </InputGroup>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th style={{ verticalAlign: "top", width: "40%" }}>
                  {language.saving_filter_label}
                </th>
                <th style={{ verticalAlign: "top", width: "30%" }}>
                  {language.creation_date_key}
                </th>
                <th style={{ verticalAlign: "top", width: "30%" }}>
                  {language.created_by_key}
                </th>
                {!isView && <th style={{ verticalAlign: "top" }}></th>}
              </tr>
            </thead>
            <tbody style={{ overflow: "auto", maxHeight: "20vw" }}>
              {savedFilterTable}
            </tbody>
          </table>
          {savedFilterTable && savedFilterTable.length === 0 && (
            <NoDataContainer />
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <SntDialog
        isShow={isShowDeleteDialog}
        onSave={onDelete}
        onClose={onClosePopup}
        saveTxt={language.delete_key}
        btnSaveClassName="danger"
        title={language.delete_key}
      >
        {() => (
          <div>
            {itemSelected && itemSelected.name
              ? language.delete_message_with_name_key.replace(
                  "{0}",
                  itemSelected.name
                )
              : language.delete_message}
          </div>
        )}
      </SntDialog>
      <SntDialog
        isShow={isShow && !isShowDeleteDialog}
        onClose={onClose}
        title={language.loading_filter_header}
        isShowSaveButton={false}
        isShowCloseButton={false}
      >
        {loadFilterTable}
      </SntDialog>
    </div>
  );
};

export default LoadFilter;
