import { Button } from "react-bootstrap";

const SntButton = ({
  variant = "sensolus-greylight",
  onClick,
  icon,
  text = "",
  title = "",
  className = "",
  style = {},
}) => {
  return (
    <Button
      variant={variant}
      className={`${className}`}
      style={style}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick(e);
        }
      }}
    >
      <div className="d-flex align-items-center justify-content-center">
        {icon ? icon : ""}
        {text && (
          <span
            title={title || text}
            className={`${icon ? "ms-1 d-none" : ""} d-lg-inline-block`}
          >
            {text}
          </span>
        )}
      </div>
    </Button>
  );
};
export default SntButton;
