import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import ActionToolType from "./ActionToolType";
import ActionToolEnum from "./ActionToolEnum";
import SntListIcon from "../../../components/Icons/SntListIcon";
import SntMapIcon from "../../../components/Icons/SntMapIcon";
import Toolbar from "./Toolbar";
import AwesomeIcon from "../../../components/Icons/AwesomeIcon";
import { useOrg } from "../../../contexts/OrgContext";

const ActionTool = ({
  list,
  group, // {viewMode, onChange}
  heroAction,
  onClick,
}) => {
  const { orgId } = useOrg();
  const language = useSelector((state) => state.language);

  const loginInfo = useSelector((state) => state.user, shallowEqual);
  const { permissionsMap, features } = loginInfo;
  const [sections, setSections] = useState([]);

  useEffect(() => {
    let listData = list || [];
    let datas = listData.filter(
      (option) => option.type === ActionToolType.data
    );

    // check condition for exporting data action
    if (
      !features.report_generator ||
      !permissionsMap["MENU_HOME_REPORT_LIST"]
    ) {
      datas = datas.filter(
        (action) => action.id !== ActionToolEnum.export_report
      );
    }
    // check condition for import_tracker_settings action
    if (!permissionsMap["DEVICE_UPDATE"]) {
      datas = datas.filter(
        (action) => action.id !== ActionToolEnum.import_tracker_settings
      );
    }
    // check condition for bulk operation action
    if (
      !features["bulk_operations"] ||
      !(
        permissionsMap["DEVICE_ACTIVATE"] ||
        permissionsMap["DEVICE_UPDATE"] ||
        permissionsMap["SUBSCRIPTION_MANAGE"] ||
        permissionsMap["DEVICE_UTILIZATION_DATA_MANAGE"] ||
        permissionsMap["DEVICE_ASSIGN"] ||
        permissionsMap["DEVICE_REMOTE_SETTING_MANAGE"] ||
        permissionsMap["PROCESS_MANAGE"] ||
        permissionsMap["QUEUE_FIRMWARE_UPGRADE"]
      )
    ) {
      datas = datas.filter(
        (action) => action.id !== ActionToolEnum.bulk_operation
      );
    }

    let others = listData.filter(
      (action) => action.type !== ActionToolType.data
    );

    const data = [];
    // convert heroAction option to section option
    heroAction &&
      heroAction.isShow &&
      data.push([
        {
          id: "heroAction",
          title: heroAction.label,
          icon: heroAction.isManage ? (
            <AwesomeIcon icon="gear"></AwesomeIcon>
          ) : (
            <AwesomeIcon icon="plus"></AwesomeIcon>
          ),
          link: getLink(heroAction, orgId),
          fullLinkInNewTab: heroAction.fullLinkInNewTab,
          customActiveClass: heroAction.customActiveClass,
        },
      ]);

    datas.length > 0 && data.push(datas);
    others.length > 0 && data.push(others);

    group &&
      data.push([
        {
          id: "LIST",
          title: language.list_key,
          icon: <SntListIcon />,
        },
        {
          id: "MAP",
          title: language.map_key,
          icon: <SntMapIcon />,
        },
      ]);

    setSections(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heroAction, list]);

  const getLink = (heroAction, selectedOrgId) => {
    if (heroAction && heroAction.link) {
      if (heroAction.link.includes("&org=")) {
        return heroAction.link;
      } else if (
        selectedOrgId !== 0 &&
        selectedOrgId !== null &&
        heroAction.link.includes("?")
      ) {
        if (heroAction.link.includes("?")) {
          return `${heroAction.link}&org=${selectedOrgId}`;
        } else {
          return `${heroAction.link}?org=${selectedOrgId}`;
        }
      }

      return heroAction.link;
    }
  };

  const handleClick = (id) => {
    if (id === "heroAction") {
      heroAction.onClick && heroAction.onClick();
    } else if (id === "MAP" || id === "LIST") {
      group.onChange && group.onChange(id);
    } else {
      onClick && onClick(id);
    }
  };

  return (
    <>
      <Toolbar
        sections={sections}
        handleClick={handleClick}
        selectedId={group?.viewMode}
      ></Toolbar>
    </>
  );
};

export default ActionTool;
