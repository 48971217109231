import L from "leaflet";

L.Class.extend({
  options: {
    serviceUrl: "https://eu1.locationiq.com/v1/autocomplete.php",
  },

  initialize: function (key, options) {
    this._key = decodeURIComponent(key);
    L.Util.setOptions(this, options);
  },

  geocode: function (query, cb, context) {
    if (query.split(",").length === 2) {
      var lat = query.split(",")[0].trim();
      var lng = query.split(",")[1].trim();
      if (
        isFinite(lat) &&
        Math.abs(lat) <= 90 &&
        isFinite(lng) &&
        Math.abs(lng) <= 180
      ) {
        var latLng = L.latLng(lat, lng);
        var latLngBounds = L.latLngBounds(
          L.latLng(lat, lng),
          L.latLng(lat, lng)
        );
        var result = {
          name: query,
          bbox: latLngBounds,
          center: latLng,
        };
        cb.call(context, [result]);
        return;
      }
    }

    this.getJSON(
      this.options.serviceUrl,
      { key: this._key, q: query },
      function (data) {
        var results = [],
          loc,
          latLng,
          latLngBounds;
        if (data && data.length) {
          for (var i = 0; i < data.length; i++) {
            loc = data[i];
            latLng = L.latLng(loc.lat, loc.lon);
            latLngBounds = L.latLngBounds(
              L.latLng(loc.boundingbox[0], loc.boundingbox[2]),
              L.latLng(loc.boundingbox[1], loc.boundingbox[3])
            );
            results[i] = {
              name: loc.display_name,
              bbox: latLngBounds,
              center: latLng,
            };
          }
        }

        cb.call(context, results);
      }
    );
  },

  suggest: function (query, cb, context) {
    return this.geocode(query, cb, context);
  },

  getJSON: function (url, params, callback) {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState !== 4) {
        return;
      }
      if (xmlHttp.status !== 200 && xmlHttp.status !== 304) {
        callback("");
        return;
      }
      callback(JSON.parse(xmlHttp.response));
    };
    xmlHttp.open("GET", url + L.Util.getParamString(params), true);
    xmlHttp.setRequestHeader("Accept", "application/json");
    xmlHttp.send(null);
  },
});
export const LocationIQ = L.Class;
