import SntIcon from "./SntIcon";

const SntFilterSettingIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9707 11.5C14.9707 11.6485 14.9568 11.7925 14.9383 11.9365L15.9145 12.6835C16.0024 12.751 16.0255 12.8725 15.97 12.9715L15.0447 14.5285C14.9892 14.6275 14.8643 14.6635 14.7625 14.6275L13.6106 14.173C13.37 14.353 13.1202 14.5015 12.8288 14.6185L12.6576 15.811C12.6391 15.919 12.5419 16 12.4263 16H10.5758C10.4601 16 10.363 15.919 10.3445 15.811L10.1733 14.6185C9.88184 14.506 9.63202 14.353 9.39146 14.173L8.23952 14.6275C8.13775 14.6635 8.01284 14.6275 7.95732 14.5285L7.03207 12.9715C6.97193 12.8725 6.99969 12.751 7.08759 12.6835L8.06373 11.9365C8.04522 11.7925 8.03134 11.6485 8.03134 11.5C8.03134 11.3515 8.04522 11.203 8.06373 11.05L7.08759 10.3165C6.99969 10.249 6.97193 10.1275 7.03207 10.0285L7.95732 8.4715C8.01284 8.3725 8.13775 8.332 8.23952 8.3725L9.39146 8.8225C9.63202 8.647 9.88184 8.494 10.1733 8.3815L10.3445 7.189C10.363 7.081 10.4601 7 10.5758 7H12.4263C12.5419 7 12.6391 7.081 12.6576 7.189L12.8288 8.3815C13.1202 8.494 13.37 8.647 13.6106 8.8225L14.7625 8.3725C14.8643 8.332 14.9892 8.3725 15.0447 8.4715L15.97 10.0285C16.0255 10.1275 16.0024 10.249 15.9145 10.3165L14.9383 11.05C14.9568 11.203 14.9707 11.3515 14.9707 11.5ZM9.88182 11.5C9.88182 12.3685 10.6081 13.075 11.501 13.075C12.3939 13.075 13.1202 12.3685 13.1202 11.5C13.1202 10.6315 12.3939 9.92501 11.501 9.92501C10.6081 9.92501 9.88182 10.6315 9.88182 11.5Z"
      ></path>
      <path d="M0 0H10V1L6 5V11L4 9V5L0 1V0Z"></path>
    </SntIcon>
  );
};

export default SntFilterSettingIcon;
