import { applyMiddleware, compose, createStore } from "redux";
import myReducer from "./../reducers/index";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./../saga/index";
import config from "../constants/Config";

const composeEnhancers =
  config.MODE !== "prod" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        shouldHotReload: false,
      })
    : compose;

const sagaMiddleware = createSagaMiddleware();

const configStore = () => {
  const middlewares = [sagaMiddleware];
  const enchancers = [applyMiddleware(...middlewares)];
  const store = createStore(myReducer, composeEnhancers(...enchancers));
  sagaMiddleware.run(rootSaga);
  return store;
};

export default configStore;
