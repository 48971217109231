import { useEffect, useState } from "react";
import organizationClient from "../../apis/organizationClient";
import { createFilter } from "react-select";
import { useSelector } from "react-redux";
import { useOrg } from "../../contexts/OrgContext";
import IcomoonIcon from "../Icons/IcomoonIcon";
import SntSelect from "@/cleanup/wrappers/ReactSelect/SntSelect";

const styles = {
  control: (base) => ({
    ...base,
    minHeight: 34,
    border: "1px solid #b8bfca",
    boxShadow: "none",
    "&:hover, &:focus": {
      border: "1px solid #0071a1",
    },
  }),
  option: (base, { isSelected }) => ({
    ...base,
    color: isSelected ? "white" : "black",
  }),

  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: (provider) => ({ ...provider, zIndex: 4 }),
};

const SntOrganizationSelector = ({
  value /* orgId */,
  onChange,
  allOrg,
  disabled = false,
  includePartner = true,
  isShowPartnerName = true,
}) => {
  const { orgId } = useOrg();

  // const org = new URLSearchParams(param).get("org");
  const language = useSelector((state) => state.language);
  const [items, setItems] = useState([]);
  const [selectedValue, setSelectedValue] = useState();

  const getOrgList = () => {
    organizationClient.getBasicInfo().then(({ data }) => {
      data.sort(function (a, b) {
        b.organisationType = !b.organisationType
          ? "NORMAL"
          : b.organisationType;
        a.organisationType = !a.organisationType
          ? "NORMAL"
          : a.organisationType;

        if (b.organisationType !== a.organisationType) {
          if (b.organisationType === "SYSTEM") return 1;
          if (
            b.organisationType === "PARTNER" &&
            a.organisationType !== "SYSTEM"
          )
            return 1;
          return -1;
        }

        return a.name.localeCompare(b.name);
      });

      var listOrg = [];
      allOrg &&
        listOrg.push({
          label: language.all_organizations_key,
          value: 0,
        });
      for (let i = 0; i < data.length; i++) {
        if (data[i].organisationType !== "PARTNER" || includePartner) {
          let label = data[i].name;
          if (isShowPartnerName) {
            if (data[i].organisationType !== "SYSTEM") {
              label = `${data[i].name} (${data[i].partnerName}) `;
            }
            label =
              data[i].status === "LOCKED" ? (
                <span style={{ color: "grey" }}>
                  {label}
                  <span className="ms-2">
                    <IcomoonIcon icon="lock"></IcomoonIcon>
                  </span>
                </span>
              ) : (
                <span>{label}</span>
              );
          }
          listOrg.push({
            label: label,
            value: data[i].id,
            type: data[i].organisationType,
            name: data[i].name,
            isLock: data[i].status === "LOCKED",
          });
        }
      }
      setItems(listOrg);
      return listOrg;
    });
  };

  useEffect(() => {
    let listOrg = getOrgList() || [];
    let orgIdString = orgId || 0;
    if (orgIdString != null) {
      for (let i = 0; i < listOrg.length; i++) {
        if (Number(orgIdString) === listOrg[i].value) {
          onChange && onChange(listOrg[i]);
          break;
        }
      }
    } else {
      if (allOrg) {
        !value && onChange && onChange(listOrg[1]);
      } else {
        !value && onChange && onChange(listOrg[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // check if value is not exist in items, need to reload data
    let list = items.filter((data) => data.value === value);
    if (list.length === 0) getOrgList();

    setSelectedValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const selectChanged = (item) => {
    if (item === null) {
      // clear click
      onChange({
        label: language.all_organizations_key,
        value: 0,
      });
      setSelectedValue(0);
    } else {
      onChange(item);
      setSelectedValue(item.value);
    }
  };

  return (
    <SntSelect
      filterOption={createFilter({
        matchFrom: "any",
        stringify: (option) => `${option.data?.name}`,
      })}
      onChange={selectChanged}
      options={items}
      styles={styles}
      value={items.filter((option) => option.value === selectedValue)}
      isClearable
      isDisabled={disabled}
      menuPlacement="auto"
    />
  );
};

export default SntOrganizationSelector;
