import { Alert } from "react-bootstrap";

const SntWarning = ({ children, textClassName, onClose, dismissible }) => {
  return (
    <Alert
      style={{
        backgroundColor: "#fff3cd",
        border: "1px solid #FFA858",
        color: "#304269",
        marginBottom: "10px",
      }}
      onClose={onClose}
      dismissible={dismissible}
    >
      <div className={textClassName}>
        <div>{children}</div>
      </div>
    </Alert>
  );
};

export default SntWarning;
