import L from "leaflet";
import SVGInject from "@iconfu/svg-inject";
import $ from "jquery";
import configurationUtils from "../../../../utils/IconConfiguration";
L.SvgIcon = L.Icon.extend({
  options: {
    className: "leaflet-svg-icon",
    id: "",
    trackerName: "",
    state: "",
    iconAnchor: [23, 35], // this is usually the center bottom, image should be 46 x 43
    network: false,
    decoratorColor: null,
    type: "",
  },

  setMarker: function (marker) {
    this.marker = marker;
  },

  createIcon: function (oldIcon) {
    if (oldIcon && oldIcon.tagName === "DIV") {
      return oldIcon;
    }

    var div = document.createElement("div"),
      options = this.options;

    div.id =
      "svg-icon-" + options.id + (options.type ? "-" + options.type : "");
    div.name = options.state;

    var img = this._createImg(options.iconUrl);
    div.append(img);

    if (options.bgPos) {
      var bgPos = L.point(options.bgPos);
      div.style.backgroundPosition = -bgPos.x + "px " + -bgPos.y + "px";
    }
    this._setIconStyles(div, "icon");
    return div;
  },

  setStyle: function (el, style) {
    var iconInfo = configurationUtils.getIcon($(el).prop("name"));
    if (!iconInfo) {
      return;
    }
    // eslint-disable-next-line default-case
    $(el)
      .find('[id^="asset-bg-normal"]')
      .attr("visibility", this.options.network ? "hidden" : "visible");
    $(el)
      .find('[id^="asset-bg-network"]')
      .attr("visibility", this.options.network ? "visible" : "hidden");
    let bgSelector = this.options.network
      ? '[id^="asset-bg-network"]'
      : '[id^="asset-bg-normal"]';
    if (this.options.decoratorColor) {
      if (this.options.network) {
        $(el)
          .find('[id^="asset-circle-decorator-color"]')
          .attr("fill", this.options.decoratorColor);
      } else {
        $(el)
          .find('[id^="asset-decorator-color"]')
          .attr("fill", this.options.decoratorColor);
      }
    }

    switch (style) {
      case "normal":
      default:
        $(el).find(bgSelector).attr("fill", iconInfo.bgColor);
        $(el).find('[id^="asset-icon"]').attr("fill", iconInfo.iconColor);
        $(el)
          .find('[id^="asset-icon"]')
          .children()
          .attr("fill", iconInfo.iconColor);
        break;
      case "hover":
        $(el).find(bgSelector).attr("fill", iconInfo.bgHoverColor);
        $(el).find('[id^="asset-icon"]').attr("fill", iconInfo.iconHoverColor);
        $(el)
          .find('[id^="asset-icon"]')
          .children()
          .attr("fill", iconInfo.iconHoverColor);
        break;
      case "selected":
        $(el).find(bgSelector).attr("fill", iconInfo.bgSelectedColor);
        $(el).find('[id^="asset-icon"]').attr("fill", iconInfo.iconHoverColor);
        $(el)
          .find('[id^="asset-icon"]')
          .children()
          .attr("fill", iconInfo.iconHoverColor);
        break;
    }
  },

  beforeInject: function (img, svg) {},

  onEnter: function () {
    var self = this;
    self.timeout && clearTimeout(self.timeout);
    self.timeout = setTimeout(function () {
      $("body").find("#map-canvas").trigger("enter-marker", {
        id: self.options.id,
        type: self.options.type,
        marker: self.marker,
      });
    }, 300);
  },

  onLeave: function () {
    var self = this;

    if (self.timeout != null) {
      clearTimeout(self.timeout);
      self.timeout = null;
    }
    $("body").find("#map-canvas").trigger("leave-marker", {
      id: self.options.id,
      type: self.options.type,
      marker: self.marker,
    });
  },

  onClick: function () {
    var self = this;

    $("body").find("#map-canvas").trigger("selected-marker", {
      id: self.options.id,
      type: self.options.type,
      marker: self.marker,
    });
  },

  _createSvg: function (svgString, container) {
    var self = this;
    container.innerHTML = svgString;

    var divMarker = $(container.firstChild).parent();
    self._handleEventImage(divMarker);
  },

  _createImg: function (src) {
    var self = this;
    var el = document.createElement("img");
    el.onload = function () {
      SVGInject(el, {
        beforeLoad: function (img) {
          // add a query to circumvent chrome cache (the return value will be used for fetching svg)
          // https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
          return img.src + "?q=xhr-2";
        },
        beforeInject: function (img, svg) {
          self.beforeInject(img, svg);
        },
        afterInject: function (img, svg) {
          var divMarker = $(svg).parent();
          self._handleEventImage(divMarker);
        },
      });
    };
    el.src = src;
    return el;
  },

  _handleEventImage: function (divMarker) {
    var self = this;
    self.setStyle(divMarker, "normal");

    // hovered marker
    divMarker.on("mouseenter", function () {
      if (!$(this).hasClass("selected")) {
        self.setStyle(this, "hover");
      }
      self.onEnter();
    });
    divMarker.on("mouseleave", function () {
      if (!$(this).hasClass("selected")) {
        self.setStyle(this, "normal");
      }
      self.onLeave();
    });

    // selected marker
    divMarker.on("click", function (event) {
      self.setStyle(this, "selected");

      // deselect previous selected
      var prev = $(this).siblings(".selected");
      if (prev.length) {
        prev.removeClass("selected");
        self.setStyle(prev, "normal");
      }

      if (!$(this).hasClass("selected")) {
        $(this).addClass("selected");
      }
      event.preventDefault();
      self.onClick();
    });
  },

  createShadow: function () {
    return null;
  },
});

export const svgIcon = function (opts) {
  return new L.SvgIcon(opts);
};
export const svgIconExtends = L.SvgIcon;

export const ICON_TYPE = {
  assetType: "asset-type",
  infraType: "infra-type",
};
