import { useMemo } from "react";
import SntTabGroup from "../../components/SntTab/SntTabGroup";
import LoaderSpinner from "../ReactBootstrap/LoaderSpinner/LoaderSpinner";
import SntBrowserTabTitle from "../SntBrowserTabTitle/SntBrowserTabTitle";
import { useSelector } from "react-redux";

const SntTabRouteContainer = ({
  allTabs,
  tabGroupInfo,
  loading,
  browserTabTitle,
}) => {
  const language = useSelector((state) => state.language);

  const tabRender = useMemo(() => {
    return allTabs
      .filter((tab) => tab.render)
      .map((tab) => (
        <div
          key={tab.id}
          id={tab.id}
          label={tab.label}
          alwaysHidden={tab.alwaysHidden}
        >
          <SntBrowserTabTitle
            title={
              tab.label && browserTabTitle
                ? `${tab.label} - ${browserTabTitle}`
                : tab.label || browserTabTitle || ""
            }
          />
          {tab.component && tab.component()}
        </div>
      ));
  }, [allTabs, browserTabTitle]);

  const sntTabGroupRender = useMemo(
    () => (
      <SntTabGroup
        key={Date.now()}
        tabGroupInfo={tabGroupInfo}
        language={language}
      >
        {tabRender}
      </SntTabGroup>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabRender]
  );

  return (
    <>
      {loading ? (
        <LoaderSpinner loading={loading} minHeight={800} />
      ) : (
        sntTabGroupRender
      )}
    </>
  );
};

export default SntTabRouteContainer;
