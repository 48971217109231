import { useEffect, useRef } from "react";

// const useOutsideElement = (ref, onClickOutside, id) => {

//   const handleClickOutside = (event) => {
//     if (ref.current && !ref.current.contains(event.target)) {
//       onClickOutside && onClickOutside();
//     }
//   }

//   useEffect(() => {
//     // Bind the event listener
//     document.addEventListener("click", handleClickOutside, true);
//     return () => {
//         // Unbind the event listener on clean up
//         document.removeEventListener("click", handleClickOutside, true);
//     };
//   }, [ref]);
// }

// export default useOutsideElement;

const useOuterClick = (callback, conditionToRun = true) => {
  const innerRef = useRef();
  const callbackRef = useRef();

  // set current callback in ref, before second useEffect uses it
  useEffect(() => {
    // useEffect wrapper to be safe for concurrent mode
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);

    // read most recent callback and innerRef dom node from refs
    function handleClick(e) {
      if (
        conditionToRun &&
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      ) {
        callbackRef.current(e);
      }
    }
  }, [conditionToRun]); // no need for callback + innerRef dep

  return innerRef; // return ref; client can omit `useRef`
};

export default useOuterClick;
