import SntIcon from "./SntIcon";

const SntUnGroup = (props) => {
  return (
    <SntIcon {...props}>
      <circle cx="8" cy="4" r="3" />
      <circle cx="3" cy="12" r="3" />
      <circle cx="13" cy="12" r="3" />
    </SntIcon>
  );
};

export default SntUnGroup;
