import { apiClient } from "./apiClient";

export default {
  getUserInfo() {
    return apiClient.get(`/rest/authentication/getUserInfo`);
  },

  getHelpdeskToken(params) {
    return apiClient.post(
      `/rest/authentication/helpdeskToken`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  login(username, password) {
    return apiClient.post(`/rest/authentication/login/${username}`, password, {
      headers: { "content-type": "text/plain" },
    });
  },

  logout() {
    return apiClient.get(`/rest/authentication/logout`, {
      headers: { "content-type": "text/plain" },
    });
  },

  loginExternalIdP(orgId, params) {
    return apiClient.get(`/rest/authentication/login/externalIdP/${orgId}`, {
      headers: { "content-type": "text/plain" },
      params: params,
    });
  },

  oauth2Authorize(params) {
    return apiClient.post(`/rest/oauth2/authorize`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};
