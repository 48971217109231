import { Col, Form, Row } from "react-bootstrap";
import FormGroupLabel from "./FormGroupLabel";

const FormGroupLayout = ({
  className = "",
  label,
  leftRender,
  rightRender,
  xs = 12,
  sm = 12,
  md = 12,
  xl = 3,
  lg = 3,
  isRequired,
}) => {
  const maxColumn = 12;
  return (
    <Form.Group as={Row} className={`${className} mb-3`}>
      <FormGroupLabel
        isRequired={isRequired}
        xs={xs}
        sm={sm}
        md={md}
        xl={xl}
        lg={lg}
      >
        {leftRender ? leftRender() : label}
      </FormGroupLabel>
      <Col
        xs={maxColumn - xs}
        sm={maxColumn - sm}
        md={maxColumn - md}
        xl={maxColumn - xl}
        lg={maxColumn - lg}
        className="align-self-center"
      >
        {rightRender && rightRender()}
      </Col>
    </Form.Group>
  );
};

export default FormGroupLayout;
