import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useOuterClick from "../useOutsideElement";
import { useSelector } from "react-redux";
import { AdvanceFilter, FilterBody } from "../FilterStyle";
import GeneralFooterFilter from "../GeneralFooterFilter";
import { Button, Container, Form } from "react-bootstrap";
import CheckboxItem from "../CheckboxItem";
import SntArrowDownIcon from "../../Icons/SntArrowDownIcon";
import usePositionFilter from "../usePositionFilter";

const MoreFilter = ({ descriptors, data = [], onChange, disabled = false }) => {
  const [isShow, setShow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [unSelectedItems, setUnSelectedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [keyUpDelay, setKeyUpDelay] = useState(null);
  const tempSelected = useRef([]);
  const tempUnSelected = useRef([]);

  const language = useSelector((state) => state.language);

  let popupStyleRef = useRef({});
  const { popupRef, buttonRef, getPosition } = usePositionFilter();

  const devideArray = (searchText = "") => {
    // get temp seleted items
    let _ctempSelected = [];
    unSelectedItems.forEach((items) => {
      items.group.forEach((item) => {
        if (item.checked && tempSelected.current.indexOf(item._idx) === -1)
          _ctempSelected.push(item._idx);
      });
    });

    let _tempSelected = [...tempSelected.current, ..._ctempSelected];
    let _tempUnSelected = [
      ...tempUnSelected.current,
      ...selectedItems
        .filter(
          (item) =>
            !item.checked && tempUnSelected.current.indexOf(item._idx) === -1
        )
        .map((i) => i._idx),
    ];
    let selected = [],
      unselected = [];

    for (let i = 0; i < descriptors.length; i++) {
      let category = descriptors[i],
        group = [];

      for (let i = 0; i < category.searchFields.length; i++) {
        if (data.indexOf(category.searchFields[i]["_idx"]) === -1) {
          category.searchFields[i].checked =
            _tempSelected.indexOf(category.searchFields[i]["_idx"]) !== -1;

          if (
            !searchText.trim() ||
            (searchText &&
              category.searchFields[i].label &&
              category.searchFields[i].label
                .toLowerCase()
                .indexOf(searchText.trim().toLowerCase()) !== -1)
          ) {
            group.push(category.searchFields[i]);
          }
        } else {
          category.searchFields[i].checked =
            _tempUnSelected.indexOf(category.searchFields[i]["_idx"]) === -1;
          selected.push(category.searchFields[i]);
        }
      }

      if (group.length > 0) {
        unselected.push({
          id: category.categoryID,
          name: category.categoryLabel,
          group: group,
        });
      }
    }

    setSelectedItems(selected);
    setUnSelectedItems(unselected);
  };

  useEffect(() => {
    // divide into 2 arrays
    devideArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptors, data]);

  const getData = useCallback(
    (tempSelected) => {
      let data = selectedItems
        .filter((s) => s.checked === true)
        .map((s) => s._idx);

      for (let i = 0; i < unSelectedItems.length; i++) {
        data = [
          ...data,
          ...unSelectedItems[i].group
            .filter((s) => s.checked === true)
            .map((s) => s._idx),
        ];
      }

      data = [...data, ...tempSelected];
      return Array.from(new Set(data));
    },
    [unSelectedItems, selectedItems]
  );

  const clearTemp = () => {
    tempSelected.current = [];
    tempUnSelected.current = [];
  };

  const submitChange = () => {
    let result = getData(tempSelected.current).sort();
    let sortedData = Array.from(data).sort();
    if (JSON.stringify(result) !== JSON.stringify(sortedData)) {
      onChange && onChange(result);
    }
    clearTemp();
  };

  let refWrapper = useOuterClick((e) => {
    if (isShow) {
      setShow(false);
      submitChange();
    }
  });

  const onClickButton = (e) => {
    if (isShow) {
      setShow(false);
      submitChange();
    } else {
      setSearchText("");
      popupStyleRef.current = getPosition();
      setShow(true);

      // Key up delay 100ms
      if (keyUpDelay) clearTimeout(keyUpDelay);
      setKeyUpDelay(
        setTimeout(() => {
          devideArray("");
        }, 100)
      );
    }
  };

  const onAppliedFilter = () => {
    setShow(false);
    submitChange();
  };

  const onClearFilter = () => {
    setShow(false);
    if (JSON.stringify(getData(tempSelected.current)) !== JSON.stringify([])) {
      onChange && onChange([]);
    }
  };

  const onChangeInput = (event) => {
    setSearchText(event.target.value);

    let text = event.target.value;

    // Key up delay 500ms
    if (keyUpDelay) clearTimeout(keyUpDelay);

    setKeyUpDelay(
      setTimeout(() => {
        devideArray(text);
      }, 500)
    );
  };

  const checkbox = (searchField, setData, key, resetTemp) => {
    return (
      <CheckboxItem
        key={key}
        checked={searchField.checked}
        onChange={(value) => {
          searchField.checked = value;
          resetTemp(searchField);
          setData();
        }}
        label={searchField.label}
      />
    );
  };

  const unSelectedCategoriesRender = useMemo(() => {
    return unSelectedItems.map((searchField, index) => {
      let group = searchField.group.map((searchField, i) => {
        return checkbox(
          searchField,
          () => {
            setUnSelectedItems([...unSelectedItems]);
          },
          i,
          (field) => {
            if (!field.checked) {
              tempSelected.current.splice(
                tempSelected.current.indexOf(field._idx),
                1
              );
            }
            if (
              field.checked &&
              tempSelected.current.indexOf(field._idx) === -1
            ) {
              tempSelected.current.push(field._idx);
            }
          }
        );
      });
      return (
        <div key={index}>
          <Container className="mb-3">
            <div className="fw-bold mb-2">{searchField.name}</div>
            {group}
          </Container>
          <div className="mb-3 border-top border-sensolus-grey"></div>
        </div>
      );
    });
  }, [unSelectedItems]);

  const selectedCategoriesRender = useMemo(() => {
    return selectedItems.map((searchField, index) => {
      return checkbox(
        searchField,
        () => {
          setSelectedItems([...selectedItems]);
        },
        index,
        (field) => {
          if (field.checked) {
            tempUnSelected.current.splice(
              tempUnSelected.current.indexOf(field._idx),
              1
            );
          }
          if (
            !field.checked &&
            tempUnSelected.current.indexOf(field._idx) === -1
          ) {
            tempUnSelected.current.push(field._idx);
          }
        }
      );
    });
  }, [selectedItems]);

  return (
    <AdvanceFilter ref={refWrapper}>
      <Button
        ref={buttonRef}
        variant="sensolus-greylight"
        title={language.all_filter_description_key}
        disabled={disabled}
        onClick={onClickButton}
      >
        {language.filters_key}
        <SntArrowDownIcon />
      </Button>

      <FilterBody
        ref={popupRef}
        style={{ display: isShow ? "block" : "none", ...popupStyleRef.current }}
      >
        <Container className="mb-3 mt-3">
          <Form.Control
            name="search"
            type="text"
            value={searchText}
            onChange={onChangeInput}
          />
        </Container>
        <div style={{ overflowY: "auto", height: "300px" }}>
          <Container>{selectedCategoriesRender}</Container>
          <div className="mb-3 mt-3 border-top border-sensolus-grey"></div>
          {unSelectedCategoriesRender}
        </div>
        <GeneralFooterFilter
          onClearFilter={(e) => onClearFilter()}
          onAppliedFilter={() => onAppliedFilter()}
          isShowExclude={false}
        />
      </FilterBody>
    </AdvanceFilter>
  );
};

export default MoreFilter;
