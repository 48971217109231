import SntIcon from "./SntIcon";

const SntReOrderIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12L2 10L14 10L14 12L2 12Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M2 9L2 7L14 7L14 9L2 9Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2 6L2 4L14 4L14 6L2 6Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8 0L11 3H5L8 0Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8 16L5 13L11 13L8 16Z" />
    </SntIcon>
  );
};

export default SntReOrderIcon;
