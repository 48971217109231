import * as types from "../redux/ActionTypes";

var initialState = {
  historyCount: 0,
  isMobile: false,
  reloadSidebar: false,
  notifications: [],
  newNotificationsCount: 0,
};

var myReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_HISTORY:
      return { ...state, historyCount: state.historyCount + 1 };
    case types.BACK_HISTORY:
      return { ...state, historyCount: state.historyCount - 1 };
    case types.SET_IS_MOBILE:
      return { ...state, isMobile: action.data.isMobile };

    case types.SHOW_CANVAS_MENU:
      return { ...state, showCanvasMenu: action.data };
    case types.LOGO_PATH:
      return { ...state, logoPath: action.data };
    case types.MENU_TYPE:
      return { ...state, menuType: action.data };
    case types.RELOAD_SIDEBAR:
      return { ...state, reloadSidebar: !state.reloadSidebar };
    case types.DASHBOARD_NOTIFICATION:
      return { ...state, dashboardNotification: action.data };
    case types.GLOBAL_NOTIFICATION:
      return {
        ...state,
        notifications: action.data,
      };
    case types.GLOBAL_NOTIFICATION_COUNT:
      return {
        ...state,
        newNotificationsCount: action.data,
      };
    case types.SHOW_NOTIFICATION:
      return { ...state, showNotification: action.data };
    default:
      return { ...state };
  }
};

export default myReducer;
