import { useEffect } from "react";

import { useField } from "formik";
import { Form } from "react-bootstrap";
import SntSelect from "@wrappers/ReactSelect/SntSelect";

const ItemSelector = ({
  name,
  multi = false,
  placeholder = "",
  disabled = false,
  options = [],
  handleChange,
  isClearable,
  filterOption = undefined,
}) => {
  const [field, meta, helper] = useField(name);

  useEffect(() => {
    handleChange && handleChange(field.value);
    // eslint-disable-next-line
  }, [field.value]);

  let formGroupClass = "";
  if (meta && meta.error) {
    formGroupClass = "is-invalid";
  } else {
    formGroupClass = "is-valid";
  }

  useEffect(() => {
    if (
      field.value &&
      !options.find((ops) => ops.value === field.value.value) &&
      options[0] &&
      !multi
    ) {
      helper.setValue(
        options.find((ops) => ops.value === meta.initialValue?.value) ||
          options[0]
      );
    }
    // eslint-disable-next-line
  }, [options]);

  const getValue = () => {
    if (options.length > 0) {
      if (field.value) {
        if (multi) {
          let value = options.filter(
            (ops) => field.value.filter((f) => f.value === ops.value).length > 0
          );
          return value;
        } else {
          return options.find((ops) => ops.value === field.value.value);
        }
      } else {
        return multi || [];
      }
    } else {
      if (field.value) {
        // show the current values, will be replaced when we get the options choice
        return field.value;
      } else {
        return multi || [];
      }
    }
  };

  const setValue = (option) => {
    if (option === null) {
      helper.setValue(multi ? [] : null);
    } else {
      helper.setValue(option);
    }
  };

  return (
    <>
      <SntSelect
        className={`${formGroupClass}`}
        name={name}
        placeholder={placeholder}
        isMulti={multi}
        options={options}
        isDisabled={disabled}
        value={getValue()}
        onChange={setValue}
        isClearable={isClearable}
        filterOption={filterOption}
      />
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </>
  );
};

export default ItemSelector;
