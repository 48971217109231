import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SntCheckBox from "../../SntCheckBox/SntCheckBox";
import useOuterClick from "../useOutsideElement";
import ExcludeLabel from "../ExcludeLabel";
import GeneralFooterFilter from "../GeneralFooterFilter";
import { useSelector } from "react-redux";
import searchUIClient from "../../../apis/searchUIClient";
import { Button, Container, Form } from "react-bootstrap";
import {
  AdvanceFilter,
  FilterBody,
  FilterHeaderStyle,
  FilterOptionHint,
} from "../FilterStyle";
import SntCloseSmallIcon from "../../Icons/SntCloseSmallIcon";
import SntArrowDownIcon from "../../Icons/SntArrowDownIcon";
import usePositionFilter from "../usePositionFilter";

const StringFilter = ({
  descriptor,
  data = {},
  onChange,
  disabled = false,
}) => {
  const language = useSelector((state) => state.language);
  const [isShow, setShow] = useState(false);
  const [disabledText, setDisabledText] = useState(false);
  const [title, setTitle] = useState("");
  const [countData, setCountData] = useState({
    notDefinedCount: 0,
    definedCount: 0,
  });

  const [dataState, setDataState] = useState({
    searchText: "",
    includeEmpty: false,
    includeNoEmpty: false,
    notFilter: false,
  });

  let popupStyleRef = useRef({});
  const { popupRef, buttonRef, getPosition } = usePositionFilter();

  useEffect(
    () => {
      if (isShow === false && descriptor.filterTypeSettings.optionsURL) {
        searchUIClient
          .getSuggestion({ url: descriptor.filterTypeSettings.optionsURL })
          .then(({ data }) => {
            setCountData({
              notDefinedCount:
                (data.notDefinedOption && data.notDefinedOption.optionHint) ||
                0,
              definedCount:
                (data.definedOption && data.definedOption.optionHint) || 0,
            });
          });
      }
    },
    [isShow, descriptor.filterTypeSettings.optionsURL],
    setCountData
  );

  useEffect(() => {
    let { filterValue } = data;
    let _title = language.all_key,
      _dataState = {
        searchText: "",
        includeEmpty: false,
        includeNoEmpty: false,
        notFilter: false,
      };

    if (filterValue) {
      _dataState = { ...filterValue };

      if (filterValue.searchText) {
        _title = '"' + filterValue.searchText + '"';
      }

      if (filterValue.includeEmpty) {
        _title =
          descriptor.filterTypeSettings?.notDefinedOption?.headerLabel || "";
      }

      if (filterValue.includeNoEmpty) {
        _title =
          descriptor.filterTypeSettings?.definedOption?.headerLabel || "";
      }
    }
    _dataState.notFilter = data.notFilter;

    setDataState(_dataState);
    setTitle(_title);
  }, [data, data.filterValue, descriptor, language.all_key]);

  const getData = useCallback(() => {
    let newData = JSON.parse(JSON.stringify(data));
    newData.notFilter = dataState.notFilter;

    newData.filterValue = {
      includeEmpty: dataState.includeEmpty,
      includeNoEmpty: dataState.includeNoEmpty,
      searchText:
        dataState.includeEmpty || dataState.includeNoEmpty
          ? null
          : dataState.searchText,
    };

    return newData;
  }, [data, dataState]);

  const isChanged = useCallback(() => {
    let oldDataFilter = data.filterValue || {};
    let newData = getData();
    let newDataFilter = newData.filterValue || {};

    let oldSearchText = oldDataFilter.searchText || "";
    let newSearchText = newDataFilter.searchText || "";

    if (
      (oldDataFilter.includeEmpty === newDataFilter.includeEmpty ||
        (oldDataFilter.includeEmpty === undefined &&
          newDataFilter.includeEmpty === false) ||
        (oldDataFilter.includeEmpty === false &&
          newDataFilter.includeEmpty === undefined)) &&
      (oldDataFilter.includeNoEmpty === newDataFilter.includeNoEmpty ||
        (oldDataFilter.includeNoEmpty === undefined &&
          newDataFilter.includeNoEmpty === false) ||
        (oldDataFilter.includeNoEmpty === false &&
          newDataFilter.includeNoEmpty === undefined)) &&
      oldSearchText.trim() === newSearchText.trim() &&
      (data.notFilter === newData.notFilter ||
        (data.notFilter === undefined && newData.notFilter === false) ||
        (data.notFilter === false && newData.notFilter === undefined))
    )
      return false;
    return true;
  }, [data, getData]);

  let refWrapper = useOuterClick((e) => {
    if (isShow) {
      setShow(false);
      if (isChanged()) onChange && onChange(getData());
    }
  });

  const onClickButton = (e) => {
    if (isShow) {
      setShow(false);
      if (isChanged()) onChange && onChange(getData());
    } else {
      popupStyleRef.current = getPosition();
      setShow(true);
    }
  };

  const onAppliedFilter = () => {
    setShow(false);
    if (isChanged()) onChange && onChange(getData());
  };

  const onClearFilter = () => {
    setShow(false);
    setDataState({
      searchText: "",
      includeEmpty: false,
      includeNoEmpty: false,
      notFilter: false,
    });
    onChange &&
      onChange({
        idx: descriptor._idx,
        data: null,
      });
  };

  const excludeLabel = useMemo(() => {
    return data.notFilter && <ExcludeLabel />;
  }, [data.notFilter]);

  useEffect(() => {
    if (dataState.includeEmpty || dataState.includeNoEmpty) {
      setDisabledText(true);
    } else {
      setDisabledText(false);
    }
  }, [dataState.includeEmpty, dataState.includeNoEmpty]);

  const filterView = useMemo(() => {
    let { filterTypeSettings } = descriptor;
    if (filterTypeSettings) {
      let { notDefinedOption, definedOption } = filterTypeSettings;
      return (
        <>
          {notDefinedOption && (
            <div className="d-flex justify-content-between mb-3">
              <SntCheckBox
                checked={dataState.includeEmpty}
                onChange={(value) =>
                  setDataState({
                    ...dataState,
                    includeEmpty: !dataState.includeEmpty && value,
                    includeNoEmpty: dataState.includeNoEmpty && !value,
                  })
                }
                label={<span>{notDefinedOption.optionLabel}</span>}
              />
              {filterTypeSettings.showOptionHints && (
                <FilterOptionHint>
                  {countData.notDefinedCount || 0}
                </FilterOptionHint>
              )}
            </div>
          )}
          {definedOption && (
            <div className="mb-3">
              <SntCheckBox
                checked={dataState.includeNoEmpty}
                onChange={(value) =>
                  setDataState({
                    ...dataState,
                    includeNoEmpty: !dataState.includeNoEmpty && value,
                    includeEmpty: dataState.includeEmpty && !value,
                  })
                }
                label={definedOption.optionLabel}
              />
              {filterTypeSettings.showOptionHints && (
                <FilterOptionHint>
                  {countData.definedCount || 0}
                </FilterOptionHint>
              )}
            </div>
          )}
        </>
      );
    }
    return null;
  }, [descriptor, countData, dataState]);

  return (
    <AdvanceFilter ref={refWrapper}>
      <Button
        ref={buttonRef}
        variant="sensolus-greylight"
        title={descriptor.description}
        disabled={disabled}
        onClick={onClickButton}
      >
        {(dataState.searchText !== "" ||
          dataState.includeEmpty ||
          dataState.includeNoEmpty) && (
          <SntCloseSmallIcon
            className="me-1"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClearFilter();
            }}
          />
        )}
        <span>{descriptor.label || descriptor.description}</span>:{" "}
        <span>{excludeLabel}</span>
        <FilterHeaderStyle>{title}</FilterHeaderStyle>
        <SntArrowDownIcon />
      </Button>
      <FilterBody
        ref={popupRef}
        style={{ display: isShow ? "block" : "none", ...popupStyleRef.current }}
      >
        <Container>
          <Form.Control
            as="textarea"
            name="searchText"
            rows={descriptor.filterTypeSettings.numInputFieldLines}
            placeholder={descriptor.filterTypeSettings.searchFieldPlaceholder}
            style={{ resize: "none" }}
            disabled={disabledText}
            value={dataState.searchText}
            onChange={(e) => {
              setDataState({ ...dataState, searchText: e.target.value });
            }}
            className="mb-3 mt-3"
          />
          {filterView}
        </Container>
        <GeneralFooterFilter
          onClearFilter={(e) => onClearFilter()}
          onAppliedFilter={() => onAppliedFilter()}
          isNotFilter={dataState.notFilter}
          onCheckNotFilter={(e) => {
            setDataState({
              ...dataState,
              notFilter: e,
            });
          }}
          disableCheckBox={
            dataState.searchText === "" ||
            dataState.includeEmpty ||
            dataState.includeNoEmpty
          }
        />
      </FilterBody>
    </AdvanceFilter>
  );
};

export default StringFilter;
