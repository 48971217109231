import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import rmaDeclarationClient from "@/apis/rmaDeclarationClient";
import useDeleteDialog from "@/cleanup/widgets/dialog/useDeleteDialog";
import SntDeleteIcon from "@/components/Icons/SntDeleteIcon";
import SntDateView from "@/components/ReactBootstrap/SntDateView";
import SntPencilIcon from "../../components/Icons/SntPencilIcon";
import SntActionButton from "../../components/ReactBootstrap/SntActionButton";
import SntAdvancedSearchTableViewCard from "../../components/SntTableViewCard/SntAdvancedSearchTableViewCard";
import AdminLayout from "../../containers/AdminLayout/AdminLayout";
import ActionToolEnum from "../../containers/AdminLayout/Toolbar/ActionToolEnum";
import ActionToolType from "../../containers/AdminLayout/Toolbar/ActionToolType";
import { useOrg } from "@/contexts/OrgContext";
import RMAStatusLabel from "./RMAStatusLabel";
import SntDownloadButton from "@/components/ReactBootstrap/SntDownloadButton";
import SntText from "@/components/ReactBootstrap/SntText";

const PAGE_NAME = "RMA_DECLARATION";
const DEFAULT_OPTION = {
  pageName: PAGE_NAME,
  queryObjectType: "RMA_DECLARATION",
  filtersVisibleAtInit: ["zendeskId", "status", "description"],
  optionsSettings: {},
};

const RMADeclaration = ({ reload }) => {
  //redux state
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  let { permissionsMap } = loginInfo;
  const table = useRef();
  const [forceFilterAction, setForceFilterAction] = useState(null);
  const [filterMap, setFilterMap] = useState(null);

  const { orgId } = useOrg();

  const filterList = [
    {
      id: ActionToolEnum.save_filter,
      title: language.save_filter,
      type: ActionToolType.filter,
    },
    {
      id: ActionToolEnum.load_filter,
      title: language.load_filter,
      type: ActionToolType.filter,
    },
  ];

  const allColumns = useMemo(
    () => [
      {
        key: "zendeskId",
        title: language.zendesk_support_id,
        Cell: ({ cell, value }) => {
          return (
            <a
              href={cell.row.original.linkToZendesk}
              target="_blank"
              rel="noreferrer"
            >
              {value}
            </a>
          );
        },
      },
      {
        key: "status",
        title: language.status_key,
        Cell: ({ cell, value }) => {
          return <RMAStatusLabel value={value} other="" />;
        },
      },
      {
        key: "deviceSerials",
        title: language.number_of_trackers_key,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          return value?.length || "";
        },
        cellCopyData: (data) => {
          return [data.deviceSerials ? data.deviceSerials.length : ""];
        },
        cellFilterValue: (data) => {
          return [data.deviceSerials ? data.deviceSerials.length : ""];
        },
      },
      {
        key: "ownedByOrg",
        title: language.organizations_key,
        Cell: ({ cell, value }) => {
          return <SntText value={cell.row.original.organisationName}></SntText>;
        },
        cellCopyData: (data) => {
          return [data.organisationName];
        },
        cellFilterValue: (data) => {
          return [data.organisationId];
        },
      },
      {
        key: "description",
        title: language.description_key,
        Cell: ({ cell, value }) => {
          return <SntText value={value}></SntText>;
        },
      },
      {
        key: "internalDescription",
        title: language.internal_description,
        Cell: ({ cell, value }) => {
          return <SntText value={value}></SntText>;
        },
      },
      {
        key: "handledByPartner",
        title: language.handled_by_partner,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "createdAt",
        title: language.created_date_key,
        Cell: ({ value }) => {
          return (
            <SntDateView
              value={value}
              other={language.na_key}
              format="lll"
            ></SntDateView>
          );
        },
      },
      {
        key: "closedAt",
        title: language.closed_key,
        Cell: ({ value }) => {
          return (
            <SntDateView
              value={value}
              other={language.na_key}
              format="lll"
            ></SntDateView>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const defaultColumnInit = useMemo(
    () => ["zendeskId", "status", "deviceSerials", "description", "createdAt"],
    []
  );

  const extraCols = useMemo(
    () => [
      {
        key: "actions",
        title: language.actions_key,
        immovable: true,
        disableSortBy: true,
        canResize: false,
        Cell: ({ cell }) => {
          var full = cell.row.original;
          return (
            <div>
              {permissionsMap["RMA_DECLARATION_MANAGE"] && (
                <SntActionButton
                  title={language.edit_key}
                  to={`/rma_declaration_manage?id=${full.id}&type=edit&org=${orgId}`}
                >
                  <SntPencilIcon />
                </SntActionButton>
              )}

              <SntDownloadButton
                title={language.download_key}
                linkDownload={rmaDeclarationClient.getDownloadLink(full.id)}
              />

              {permissionsMap["RMA_DECLARATION_MANAGE"] && (
                <SntActionButton
                  title={language.delete_key}
                  onClick={(e) => onDeleteClicked(e, full)}
                >
                  <SntDeleteIcon />
                </SntActionButton>
              )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onDataRequestChange = (data, callback) => {
    const { params, dataOptions } = data;
    if (table.current) {
      table.current.setLoading(true);
      rmaDeclarationClient.search(params).then(
        ({ data }) => {
          if (table.current) {
            table.current.updateDataTable({
              ...data,
              pageCount: data.countByFilter,
              totalItem: {
                countAll: data.countAll,
                countByFilter: data.countByFilter,
              },
            });
            table.current.setLoading(false);

            const dataCallback = table.current.getDataCallBack(
              params,
              dataOptions,
              data
            );
            callback(dataCallback[0], dataCallback[1]);
          }
        },
        (err) => {
          table.current.setLoading(false);
        }
      );
    }
  };

  const {
    handleOpen,
    handleClose,
    handleOpenError,
    DeleteDialog,
  } = useDeleteDialog({
    onDelete: (itemSelected) => {
      rmaDeclarationClient
        .delete(itemSelected.id)
        .then(() => {
          handleClose();
          table.current.resetDefault();
        })
        .catch((error) => {
          handleClose();
          let message = "";
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          handleOpenError(message);
        });
    },
    language,
  });

  const onDeleteClicked = (el, item) => {
    el.preventDefault();
    handleOpen(
      item,
      item.name &&
        language.delete_message_with_name_key.replace("{0}", item.name)
    );
  };

  return (
    <div>
      <DeleteDialog />

      <AdminLayout
        browserTabTitle={language.device_return}
        headerTitle={language.device_return}
        reload={reload}
        pageName={PAGE_NAME}
        sharedKey={PAGE_NAME}
        defaultOption={DEFAULT_OPTION}
        isAdvanceFilter={true}
        filterList={filterList}
        newButton={{
          isShow: permissionsMap["RMA_DECLARATION_MANAGE"],
          link: `/rma_declaration_manage?type=add&org=${orgId}`,
          label: language.add_key,
        }}
        totalLabel={language.items_key}
        body={({ isDisabled, dataOptions, onChangeChildren }) =>
          dataOptions && (
            <SntAdvancedSearchTableViewCard
              shared={(child) => (table.current = child)}
              allColumns={allColumns}
              extraCols={extraCols}
              visibleCols={defaultColumnInit}
              disabled={isDisabled}
              onChange={(data) => {
                onDataRequestChange(data, onChangeChildren);
              }}
              dataOptions={dataOptions}
              isDisabledCopyData={false}
              isDisabbledAddFilter={false}
              onFilterClicked={(action, key, filterKeys, query) => {
                setForceFilterAction({ action, key, filterKeys, query });
              }}
              filterMap={filterMap}
            />
          )
        }
        forceFilterAction={forceFilterAction}
        setForceFilterAction={setForceFilterAction}
        onLoadFilter={(data) => setFilterMap(data)}
      ></AdminLayout>
    </div>
  );
};

export default RMADeclaration;
