import { SensolusDatetimeReact } from "../../constants/Config";

const SntDateView = ({ value, other, format }) => {
  var rs = value
    ? format
      ? SensolusDatetimeReact.parse(value, "server").format(format)
      : SensolusDatetimeReact.fromNow(
          SensolusDatetimeReact.parse(value, "server")
        )
    : other
    ? other
    : "";

  return <span title={rs}>{rs}</span>;
};
export default SntDateView;
