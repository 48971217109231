import * as React from "react";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

const OrgContext = React.createContext(null);

export const OrgProvider = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  const getOrgIdFromLocation = useCallback((location) => {
    let param = new URLSearchParams(location.search);
    let org = param.get("org");
    return org === null ? null : isNaN(org) ? null : parseInt(org);
  }, []);

  const updateOrg = (newOrgId) => {
    let param = new URLSearchParams(location.search);
    let oldOrgId = getOrgIdFromLocation(location);
    if (oldOrgId !== newOrgId) {
      if (!newOrgId) {
        param.delete("org");
      } else {
        param.set("org", newOrgId);
      }
      history.replace({
        search: param.toString(),
      });
    }
  };

  return (
    <OrgContext.Provider
      value={{ orgId: getOrgIdFromLocation(location), updateOrg }}
    >
      {children}
    </OrgContext.Provider>
  );
};

export const useOrg = () => React.useContext(OrgContext);
