import SntIcon from "./SntIcon";

const SntViewerDisable = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4C7.05609 4 6.11215 4.15709 5.17834 4.47125L1.70709 1L1 1.70711L4.1676 4.87469C2.7327 5.53856 1.33093 6.58031 0 7.99994C3.35809 11.582 7.1673 12.7582 10.8217 11.5287L14.435 15.1421L15.1422 14.435L11.8324 11.1253C13.2673 10.4614 14.6691 9.41965 16 7.99998C13.5 5.33334 10.75 4 8 4ZM6.98401 6.27692L6.26172 5.55463C6.75214 5.2054 7.35208 5 8 5C9.65686 5 11 6.34314 11 8C11 8.64792 10.7946 9.24786 10.4454 9.73828L9.72308 9.01599C9.89905 8.71823 10 8.37091 10 8C10 6.89543 9.10458 6 8 6C7.62909 6 7.28177 6.10097 6.98401 6.27692ZM4.73563 5.68779C3.61154 6.1857 2.48718 6.94936 1.38589 7.99994C2.48718 9.05054 3.61148 9.81419 4.73553 10.3121C4.27231 9.65927 4 8.86143 4 8C4 7.13852 4.27237 6.34065 4.73563 5.68779ZM11.2644 10.3122C12.3885 9.81429 13.5128 9.0506 14.6141 8C13.5128 6.94943 12.3885 6.18576 11.2644 5.68785C11.7277 6.3407 12 7.13855 12 8C12 8.86147 11.7277 9.65933 11.2644 10.3122ZM8 11C8.64792 11 9.24786 10.7946 9.73828 10.4454L9.01599 9.72308C8.71823 9.89903 8.37091 10 8 10C6.89542 10 6 9.10457 6 8C6 7.62909 6.10095 7.28177 6.27692 6.98401L5.55463 6.26172C5.20538 6.75214 5 7.35208 5 8C5 9.65686 6.34314 11 8 11Z"
      />
    </SntIcon>
  );
};

export default SntViewerDisable;
